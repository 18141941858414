import { Button } from '@mui/material'
import { Flag } from '@mui/icons-material'

type Props = {
  onClick?: () => void
}

const ReportProblemButton = (props: Props) => {
  const { onClick = () => {} } = props;

  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<Flag />}
      sx={{ marginRight: '10px', marginBottom: '7px' }}
      onClick={onClick}
    >
      Report
    </Button>
  )
}

export default ReportProblemButton
