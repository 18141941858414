type WriteTagProps = {
  tagName: string
  fixedAttrName: string
  fixedAttrValue: string
  attrName: string
  attrValue: string | null
}

export const writeTag = (props: WriteTagProps): void => {
  const {
    tagName, fixedAttrName, fixedAttrValue, attrName, attrValue
  } = props
  let tag = document.querySelector(`${tagName}[${fixedAttrName}=${fixedAttrValue}]`)
  if (attrValue) {
    if (tag) {
      tag.setAttribute(attrName, attrValue);
    } else {
      tag = document.createElement(tagName)
      tag.setAttribute(fixedAttrName, fixedAttrValue)
      tag.setAttribute(attrName, attrValue)
      document.head.appendChild(tag)
    }
  } else if (tag) {
    tag.remove()
  }
}
