import React from 'react';
import { useParams } from "react-router-dom";
import Layout from 'layouts/Layout';
import CenteredCircularProgress from 'components/CenteredCircularProgress'
import Page from './Page';
import { withPageWrapper } from 'pages'
import { Event } from 'Tracker'
import { useMapProductLoader } from 'loaders/MapProductLoader';

const PatreonRewardPage = () => {
  const { battleMapId } = useParams()
  const product = useMapProductLoader(battleMapId)

  if (!product) {
    return (
      <Layout backgroundColor="light" padding="medium">
        <CenteredCircularProgress />
      </Layout>
    )
  }

  return (
    <Layout backgroundColor="light" padding="medium">
      <Page product={product} />
    </Layout>
  )
}

export default withPageWrapper(
    PatreonRewardPage, Event.PatreonRewardPageLoaded, { signInRequired: true }
)
