import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { CookieName } from 'storage'

const OwlbearRodeoDevelopmentRedirect = () => {
  const navigate = useNavigate()

  useEffect(() => {
    Cookies.set(CookieName.OwlbearRodeoDevelopment, '1')
    navigate("/")
  })

  return null;
}

export default OwlbearRodeoDevelopmentRedirect
