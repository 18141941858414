import React from 'react';
import {
  Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle,
} from '@mui/material';
import TextField from './TextField'

type Props = {
  open: boolean
  onClose: () => void
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  email: string
  emailError: null | string
  submit: () => void
  submitting: boolean
}

const DialogPresentation = (props: Props) => {
  const {
    open, onClose, onChange, email, emailError, submit, submitting
  } = props

  return (
    <MuiDialog open={open} onClose={onClose}>
      <DialogTitle>Free maps to inspire you every week</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Looking for new ideas for your next session? Subscribe to our Recommended Battle Maps emails and you&apos;ll receive new maps in your inbox every week.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          value={email}
          onChange={onChange}
          fullWidth
          error={emailError !== null}
          helperText={emailError}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={submitting} onClick={onClose}>No thanks</Button>
        <Button disabled={submitting} onClick={submit}>Subscribe me</Button>
      </DialogActions>
    </MuiDialog>
  )
}

export default DialogPresentation
