import React, { useContext } from 'react'
import AppContext from 'AppContext'
import { styled } from '@mui/material/styles';
import { colors } from 'theme'

const DesktopContainer = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '20px',
  background: colors.offWhiteBackground,
  display: 'flex',
  [theme.breakpoints.up('lg')]: { width: '90vw' }
}))

const PhoneContainer = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  borderRadius: '20px',
  background: colors.offWhiteBackground,
  width: '99vw',
}))

const TabletContainer = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '20px',
  background: colors.offWhiteBackground,
  display: 'flex',
  width: '99vw',
  flexDirection: 'column',
}))

type Props = {
  children: React.ReactNode
}

const Contents = ({ children }: Props) => {
  const { deviceType } = useContext(AppContext)
  const isDesktop = deviceType === 'desktop'
  const isPhone = deviceType === 'phone'
  const isTablet = deviceType === 'tablet'

  if (!(isDesktop || isPhone || isTablet)) {
    // Media query doesn't work yet, wait a little to render
    return null
  }

  const Container = (isDesktop ? DesktopContainer : (isPhone ? PhoneContainer : TabletContainer))

  return <Container>{children}</Container>
}

export default Contents
