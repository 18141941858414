import { useNavigate } from "react-router-dom";

const SubscribeFromDiscordRedirect = () => {
  const navigate = useNavigate()

  navigate("/subscribe?utm_medium=discord")

  return null;
}

export default SubscribeFromDiscordRedirect
