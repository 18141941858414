import React, { useEffect, useState } from 'react';
import { PageMetadata, SchemaOrgImageObject } from 'document';
import { writeCanonicalUrl } from './canonicalUrl'
import { DocumentContext } from './context'
import { writeMetaRobotsTag } from './metaRobots'
import { writeTag } from './document'

export { useUrlAsCanonicalUrl } from './canonicalUrl'
export * from './context'

const defaultPageMetadata = {
  title: `Lost Atlas - Search from ${process.env.REACT_APP_ROUGH_MAP_COUNT}+ free battle maps for D&D and other RPGs`,
  metaDescription: `The fastest way to find battle maps for your next game of D&D or other tabletop RPG. Search from ${process.env.REACT_APP_ROUGH_MAP_COUNT}+ free maps, and upgrade for premium maps from top creators.`
}

const writePageMetadata = (pageMetadata?: PageMetadata): void => {
  let pm: PageMetadata
  if (pageMetadata) {
    pm = pageMetadata
  } else {
    pm = defaultPageMetadata
  }
  document.title = pm.title
  document
    .querySelector('meta[name="description"]')
    ?.setAttribute("content", pm.metaDescription);
}

const writePageImageObjects = (imageObjects: SchemaOrgImageObject[]) => {
  const scriptType = 'application/ld+json'

  let scriptTag: HTMLScriptElement | null
  scriptTag = document.querySelector(`script[type="${scriptType}"]`)
  if (imageObjects.length === 0) {
    // Delete pre-existing script tag, if any
    scriptTag?.remove()
  } else {
    const scriptTagText = JSON.stringify(
      imageObjects.map(imageObject => (
        {
          ...imageObject,
          ...{ '@context': 'https://schema.org/', '@type': 'ImageObject' }
        }
      ))
    )
    if (scriptTag) {
      // Update pre-existing script tag
      scriptTag.text = scriptTagText
    } else {
      // Create new script tag
      scriptTag = document.createElement('script')
      scriptTag.setAttribute('type', scriptType)
      scriptTag.text = scriptTagText
      document.head.appendChild(scriptTag)
    }
  }
}

const writeMetaRefreshTag = (metaRefresh: URL | null): void => {
  let attrValue: string | null = null
  if (metaRefresh) {
    attrValue = `0; url=${metaRefresh.toString()}`
  }
  writeTag({
    tagName: 'meta',
    fixedAttrName: 'http-equiv',
    fixedAttrValue: 'refresh',
    attrName: 'content',
    attrValue
  })
}

function DocumentProvider({ children }) {
  const [canonicalAbsolutePath, setCanonicalAbsolutePath] = React.useState<string | null>(null)
  const [pageMetadata, setPageMetadata] = React.useState<PageMetadata>()
  const [pageImageObjects, setPageImageObjects] = React.useState<SchemaOrgImageObject[]>([])
  const [metaRobots, setMetaRobots] = useState<string | null>(null)
  const [metaRefresh, setMetaRefresh] = useState<URL | null>(null)

  React.useEffect(() => {
    writeCanonicalUrl(canonicalAbsolutePath)
  }, [canonicalAbsolutePath])
  React.useEffect(() => writePageMetadata(pageMetadata), [pageMetadata])
  React.useEffect(
    () => writePageImageObjects(pageImageObjects), [pageImageObjects]
  )
  useEffect(() => writeMetaRobotsTag(metaRobots), [metaRobots])
  useEffect(() => writeMetaRefreshTag(metaRefresh), [metaRefresh])

  const contextValue = {
    canonicalAbsolutePath,
    setCanonicalAbsolutePath,
    setPageImageObjects,
    setPageMetadata,
    setMetaRefresh,
    setMetaRobots,
  }
  return (
    <DocumentContext.Provider value={contextValue}>
      {children}
    </DocumentContext.Provider>
  )
}

export default DocumentProvider;
