import React, { createContext } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles'

const useFullWidthColumns = (): number | null => {
  const theme = useTheme()
  const useXs = useMediaQuery(theme.breakpoints.only('xs'))
  const useSm = useMediaQuery(theme.breakpoints.only('sm'))
  const useMd = useMediaQuery(theme.breakpoints.only('md'))
  const useLg = useMediaQuery(theme.breakpoints.only('lg'))
  const useXl = useMediaQuery(theme.breakpoints.only('xl'))
  const useXxl = useMediaQuery(theme.breakpoints.only('xxl'))
  const useXxxl = useMediaQuery(theme.breakpoints.only('xxxl'))
  const useXxxxl = useMediaQuery(theme.breakpoints.only('xxxxl'))

  if (useXs) {
    return 2;
  } else if (useSm) {
    return 3;
  } else if (useMd) {
    return 4;
  } else if (useLg) {
    return 5;
  } else if (useXl) {
    return 6;
  } else if (useXxl) {
    return 7;
  } else if (useXxxl) {
    return 8;
  } else if (useXxxxl) {
    return 9;
  }
  return null;
}

export type ColumnsValue = {
  fullWidthColumns: number | null
}

const defaultContextValue = {
  fullWidthColumns: null
}

export const ColumnsContext = createContext<ColumnsValue>(defaultContextValue)

type Props = {
  children: React.ReactNode
}

const ColumnsProvider = ({ children }: Props) => {
  const fullWidthColumns = useFullWidthColumns()

  return (
    <ColumnsContext.Provider value={{ fullWidthColumns }}>
      {children}
    </ColumnsContext.Provider>
  )
}

export default ColumnsProvider

