import React from 'react';
import AlertBanner from './AlertBanner'
import { useLocation } from 'react-router-dom'

const DiscordServerBottomAlert = () => {
  const location = useLocation()

  if (!location.pathname.match(/^\/subscribe/)) {
    return <AlertBanner />
  }

  return null
}

export default DiscordServerBottomAlert
