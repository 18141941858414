import React from 'react';
import { Box, IconButton as MuiIconButton, SxProps } from '@mui/material'

import Tooltip from 'components/Tooltip'

export const containIconClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
  evt.stopPropagation()
  evt.preventDefault()
}

type Props = {
  children: React.ReactNode,
  containIconClick?: boolean,
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  sx?: SxProps
  tooltipTitle: string
}

const IconButton = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    containIconClick: cic = true,
    sx: propsSx,
    onClick: propsOnClick,
    tooltipTitle,
    ...rest
  } = props;

  const onClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    if (cic) { containIconClick(evt) }
    if (propsOnClick) propsOnClick(evt)
  }

  return (
    <Box sx={{ marginLeft: '5px', float: 'right' }}>
      <Tooltip title={tooltipTitle} arrow>
        <MuiIconButton
          {...rest}
          ref={ref}
          sx={{
            background: 'white',
            '&:hover': { color: '#ccc', border: '1px solid #999' }
          }}
          onClick={onClick}
        />
      </Tooltip>
    </Box>
  )
})
IconButton.displayName = "IconButton"

export default IconButton
