import React from 'react'
import { LinearProgress } from '@mui/material'

export const DelayedLinearProgress = () => {
  const [showProgress, setShowProgress] = React.useState<boolean>(false)

  React.useEffect(() => {
    // Only show linear progress if the lazy load is taking a little bit of time
    setTimeout(() => setShowProgress(true), 100)
  }, [])

  return (
    <>{showProgress && <LinearProgress />}</>
  )
}

