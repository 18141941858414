import SplitTest from './SplitTest'

// Expired, listed here just to prevent future collisions
export const ExpiredSplitTests: SplitTest[] = [
  new SplitTest('a0sl', 'auth0_social_login', true),
  new SplitTest('abll', 'top_nav_logo_left', true),
  new SplitTest('abll_1_1', 'top_nav_logo_left_1_1', true),
  new SplitTest('cfudd', 'charge_for_unlimited_direct_downloads', true),
  new SplitTest('cfp', 'cloud_front_previews', true),
  new SplitTest('dmpr', 'desktop_map_page_redesign', true),
  new SplitTest('dsac', 'discord_server_ad_card', true),
  new SplitTest('dsba', 'discord_server_bottom_alert', true),
  new SplitTest('dsr', 'desktop_search_redesign', true),
  new SplitTest('dsr_1_1', 'desktop_search_redesign_1_1', true),
  new SplitTest('dsr_1_2', 'desktop_search_redesign_1_2', true),
  new SplitTest('eebp', 'engaged_explore_boosts_premium', true),
  new SplitTest('enm', 'environments_no_minimum', true),
  new SplitTest('ep', 'explore_page', true),
  new SplitTest('epccfp', 'explore_page_creator_chip_filters_premium', true),
  new SplitTest('fvba', 'first_visit_bottom_alert', true),
  new SplitTest('gpbfq', 'gate_premium_boost_in_filtered_queries', true),
  new SplitTest('hcf', 'hosted_chip_filter', true),
  new SplitTest('hmfm', 'hosted_maps_free_maps', true),
  new SplitTest('hmfm_1_1', 'hosted_maps_free_maps_1_1', true),
  new SplitTest('hmfm_1_2', 'hosted_maps_free_maps_1_2', true),
  new SplitTest('hmfm_1_3', 'hosted_maps_free_maps_1_3', true),
  new SplitTest('hmhpa', 'hosted_maps_on_home_page_for_anonymous_users', true),
  new SplitTest('hrpmc', 'hide_rough_premium_map_count', true),
  new SplitTest('ifpc', 'improved_front_page_copy', true),
  new SplitTest('inb', 'insertions_not_boosts', true),
  new SplitTest('hoc', 'highlight_our_creators', true),
  new SplitTest('lp_2_0', 'landing_page_2_0', true),
  new SplitTest('mcr', 'map_card_redesign', true),
  new SplitTest('mcpn', 'map_card_pseudo_name', true),
  new SplitTest('mcww', 'more_columns_for_wider_windows', true),
  new SplitTest('mpsm', 'map_page_similar_maps', true),
  new SplitTest('mrisf', 'map_rank_includes_sub_funnel', true),
  new SplitTest('mz2_1', 'map_zoom_2_1', true),
  new SplitTest('nfchp', 'no_featured_creator_on_home_page', true),
  new SplitTest('nmcw', 'no_map_card_watermarks', true),
  new SplitTest('nmz', 'new_map_zoom', true),
  new SplitTest('pb4', 'premium_boost_4', true),
  new SplitTest('pmpcta_2_0', 'premium_map_page_cta_2_0', true),
  new SplitTest('pmpcta_2_1', 'premium_map_page_cta_2_1', true),
  new SplitTest('sdsoka', "subscribe_dialog_shown_on_key_action", true),
  new SplitTest('sf', 'subscription_first', true),
  new SplitTest('sii', 'signed_in_insertions', true),
  new SplitTest('siwe', 'sign_in_welcome_email', true),
  new SplitTest('slprm', 'subscription_landing_page_relevant_maps', true),
  new SplitTest('slpsc', 'subscription_landing_page_supply_count', true),
  new SplitTest('snm', 'subscription_not_membership', true),
  new SplitTest('sdbem', 'subscribe_dialog_before_external_map', true),
  new SplitTest('srpmc1k', 'show_rough_premium_map_count_1k', true),
  new SplitTest('t30d', 'trending_means_30_days', true),
  new SplitTest('vipf', 'visitor_insertions_premium_first', true)
]

export const SplitTests = {
  SkipSuperTallMaps: new SplitTest('sstm', 'skip_super_tall_maps'),
}

export const currentSplitTests = (): SplitTest[] => ([
  SplitTests.SkipSuperTallMaps,
])
