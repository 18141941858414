import React from 'react'
import { BookmarkBorder, Bookmark } from '@mui/icons-material';
import BookmarkToggle, { BookmarkedProps, NotBookmarkedProps } from 'components/BookmarkToggle'

const IconPresentation = {
  LoadPending: () => (
    <BookmarkBorder color="disabled" data-testid="bookmark" />
  ),
  Bookmarked: ({ onClick }: BookmarkedProps) => (
    <Bookmark onClick={onClick} data-testid="bookmark" data-status="bookmarked"/>
  ),
  NotBookmarked: ({ onClick }: NotBookmarkedProps) => (
    <BookmarkBorder onClick={onClick} data-testid="bookmark" data-status="not-bookmarked" />
  ),
  LoginNeeded: () => <BookmarkBorder data-testid="bookmark" />
}

interface Props {
  mapId: string;
}

function BookmarkToggleIconButton({ mapId }:Props) {
  return (
    <BookmarkToggle
      mapId={mapId}
      presentation={IconPresentation}
    />
  )
}

export default BookmarkToggleIconButton
