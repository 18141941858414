import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';

type Props = {
  cancelLabel?: string
  confirmLabel?: string
  contentText: string | string[]
  open: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
}

const ConfirmationDialog = (props: Props) => {
  const {
    open,
    onClose,
    title,
    contentText,
    onConfirm,
    cancelLabel = 'Cancel',
    confirmLabel = 'OK',
  } = props

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {Array.isArray(contentText) && contentText.map((entry, i) => (
          <DialogContentText key={`content-text-entry-${i}`}>
            {entry}
          </DialogContentText>
        ))}
        {!Array.isArray(contentText) && (
          <DialogContentText>
            {contentText}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelLabel}</Button>
        <Button onClick={onConfirm}>{confirmLabel}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
