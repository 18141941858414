import React from 'react'
import PrivateAssetDownloadContext from './context'
import Iframes from './Iframes'
import { useDownloadPrivateAsset } from './hooks'

export { default as PrivateAssetDownloadContext } from './context'
export type { CreateDownloadUrlResponse } from './context'

type Props = {
  children: React.ReactNode
}

const PrivateAssetDownloadProvider = ({ children }: Props) => {
  const { downloadPrivateAsset, downloadUrls } = useDownloadPrivateAsset()

  return (
    <PrivateAssetDownloadContext.Provider value={{ downloadPrivateAsset }}>
      {children}
      <Iframes downloadUrls={downloadUrls} />
    </PrivateAssetDownloadContext.Provider>
  )
}

export default PrivateAssetDownloadProvider
