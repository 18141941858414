import isEmpty from 'lodash/isEmpty'
import { Map } from 'models/deprecated'
import { BattleMap, Creator, MapSubmission, Order } from 'models'

const fullPath = (path: string, queryParams?: {}): string => {
  let result = path
  if (!isEmpty(queryParams)) {
    result = `${result}?${new URLSearchParams(queryParams).toString()}`
  }
  return result
}

export const AutoDownloadQueryStringName = 'd'

const Paths = {
  afterSignInRedirectPath: () => '/sign-in/r',
  bookmarksPagePath: () => '/bookmarks',
  checkoutPagePath: () => '/buy-maps',
  creatorDashboardMapSubmissionsEditPath: (mapSubmission: Pick<MapSubmission, 'id'>) => `${Paths.creatorDashboardPath()}${Paths.creatorDashboardMapSubmissionsSegment()}/${mapSubmission.id}${Paths.creatorDashboardMapSubmissionsEditSegment()}`,
  creatorDashboardMapSubmissionsSegment: () => '/map_submissions',
  creatorDashboardMapSubmissionsEditSegment: () => '/edit',
  creatorDashboardPath: () => '/dashboard',
  creatorPagePath: (creator: Pick<Creator, 'slug'>) => `/c/${creator.slug}`,
  contactUsPagePath: () => `/contact-us`,
  discordBotPagePath: () => '/discord-bot',
  discordBotInstallPagePath: () => '/discord-bot/install',
  emailSubscriptionsPath: () => `/email-subscriptions`,
  emailSubscriptionUnsubscribePath: (subscriptionId: string) => `/email-subscriptions/unsubscribe/${subscriptionId}`,
  faqPagePath: () => '/faq',
  followingPagePath: () => '/following',
  foundryModulePagePath: () => '/foundry-module',
  mapPagePath: (map: Pick<Map, 'id'> | Pick<BattleMap, 'id'>, opts: { autoDownload?: boolean } = {}) => {
    const queryParams = {} as any
    if (opts.autoDownload) {
      queryParams[AutoDownloadQueryStringName] = 1
    }
    return fullPath(`/m/${map.id}`, queryParams)
  },
  orderPage: (order: Pick<Order, 'publicId'>) => `/o/${order.publicId}`,
  owlbearRodeoDevelopmentPath: () => '/obr',
  patreonRewardPath: (battleMap: Pick<BattleMap, 'id'>) => `/patreon/rewards/${battleMap.id}`,
  patreonRewardsPath: () => `/patreon/rewards`,
  patreonRewardsCreatorSpecificOnboardingPath: (creatorSlug) => `/patreon/rewards/c/${creatorSlug}`,
  privacyPolicyPath: () => '/privacy',
  purchasedMapsPath: () => '/purchased-maps',
  resubscribePath: () => '/resubscribe',
  searchPath: (queryParams?: {}) => fullPath('/search', queryParams),
  subscribeFromDiscordPath: () => '/subscribe/d',
  subscribeSuccessPath: () => '/thanks-for-subscribing',
  subscriptionLandingPagePath: (firstSubscriptionBattleMap?: Pick<BattleMap, 'id'>) => {
    const queryParams = {} as any
    if (firstSubscriptionBattleMap) {
      queryParams.m = firstSubscriptionBattleMap.id
    }
    return fullPath('/subscribe', queryParams)
  },
  subscriptionMapPath: (battleMap: Pick<BattleMap, 'id'>) => `/subscription/maps/${battleMap.id}`,
  subscriptionPath: () => '/subscription',
  termsOfServicePath: () => '/terms-of-service',
  zoomedInMapPath: (map: Pick<Map, 'id'> | Pick<BattleMap, 'id'>) => `/z/${map.id}`,
}

export default Paths;
