import React from 'react';
import FlashMessageProvider from 'providers/FlashMessageProvider';
import DocumentProvider from 'providers/DocumentProvider';
import { StylingProvider } from 'theme';
import ExternalMapsProvider from 'providers/ExternalMapsProvider'
import PrivateAssetDownloadProvider from 'providers/PrivateAssetDownloadProvider'
import AppLoader from 'AppLoader';
import { SplitTestParticipations } from 'splitTests'
import WindowProvider from 'providers/WindowProvider'
import ColumnsProvider from 'providers/ColumnsProvider'

type Props = {
  children: React.ReactNode
  splitTestParticipations: SplitTestParticipations
}

const Providers = ({ children, splitTestParticipations }: Props) => (
  <DocumentProvider>
    <StylingProvider>
      <AppLoader splitTestParticipations={splitTestParticipations}>
        <FlashMessageProvider>
          <ExternalMapsProvider>
            <PrivateAssetDownloadProvider>
              <WindowProvider>
                <ColumnsProvider>
                  {children}
                </ColumnsProvider>
              </WindowProvider>
            </PrivateAssetDownloadProvider>
          </ExternalMapsProvider>
        </FlashMessageProvider>
      </AppLoader>
    </StylingProvider>
  </DocumentProvider>
)

export default Providers
