import React from 'react';
import { Layout } from 'layouts'
import CenteredCircularProgress from 'components/CenteredCircularProgress'
import { Box } from '@mui/material'
import Rails from 'Rails'
import { useSearchParams } from 'react-router-dom'
import { AppLoaderContext, AppLoaderContextValue } from 'AppLoader'

const DiscordBotInstallRedirect = () => {
  const [searchParams] = useSearchParams()
  const { session } = React.useContext(AppLoaderContext) as AppLoaderContextValue

  React.useEffect(() => {
    if (session) {
      const queryParams = { success_path: '/discord-bot' }
      const utmParamTypes = ['source', 'campaign', 'medium', 'term']
      utmParamTypes.forEach(utmParamType => {
        const utmParamName = `utm_${utmParamType}`
        const utmParamValue = searchParams.get(utmParamName)
        if (utmParamValue) {
          queryParams[utmParamName] = utmParamValue
        }
      })
      window.location.href = Rails.discordOauthStartUrl(queryParams).toString()
    }
  }, [session])

  return (
    <Layout backgroundColor="light" padding="medium">
      <Box sx={{ position: 'relative', top: '75px', textAlign: 'center', fontSize: '1em' }}>Redirecting you to Discord ...</Box>
      <CenteredCircularProgress />
    </Layout>
  )
}

export default DiscordBotInstallRedirect;
