import React, { useEffect } from 'react';
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { colors } from 'theme';
import { SingleRowBar, LogoImg } from 'layouts/TopAppBarBase'
import { Box } from '@mui/material'
import { styled } from '@mui/system'
import { Event } from 'Tracker'

const FallbackContainer = styled('div')({
  width: '100vw',
  height: '100vh',
  background: colors.darkGrey,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

const FallbackTextContainer = styled('div')({
  borderRadius: '10px',
  background: colors.darkBackground,
  maxWidth: '70vw',
  textAlign: 'center',
  color: colors.offWhiteBackground,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '-20vh',
  padding: '20px 5vw 20px 5vw'
})

const ReturnLink = styled('a')({
  color: colors.offWhiteBackground,
})

export const Fallback = () => {
  useEffect(() => {
    window.tracker.track(Event.ErrorPageRendered);
  }, [])

  const onClick = () => {
    window.tracker.track(Event.ErrorPageReturnToHome);
  }

  return (
    <React.Fragment>
      <SingleRowBar>
        <Box sx={{ textAlign: "center", flexGrow: 1 }}>
          <a href="/">
            <LogoImg />
          </a>
        </Box>
      </SingleRowBar>
      <FallbackContainer>
        <FallbackTextContainer>
          <p>Sorry! Looks like there was an error. We&apos;ve been notified and are looking into it.</p>
          <p>In the meantime, you can get back to the home page by <span onClick={onClick}><ReturnLink href="/">clicking here.</ReturnLink></span></p>
        </FallbackTextContainer>
      </FallbackContainer>
    </React.Fragment>
  )
}

type Props = {
  children: JSX.Element
}

function ErrorBoundary({ children }: Props) {
  return (
    <SentryErrorBoundary fallback={<Fallback />}>
      {children}
    </SentryErrorBoundary>
  )
}

export default ErrorBoundary;
