import React, { useContext, useState } from 'react'
import { Box, FormControlLabel, Switch } from '@mui/material'
import HelpTooltip from 'components/HelpTooltip'
import { AppLoaderContext } from 'AppLoader'
import ApiClient from 'ApiClient'
import { CookieName } from 'storage'
import Cookies from 'js-cookie';
import { SxProps, Theme } from '@mui/material/styles';

type PresentationProps = {
  checked: boolean,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  sx?: SxProps<Theme>
}

export const Presentation = ({ checked, onChange, sx = {} }: PresentationProps) => (
  <FormControlLabel
    sx={sx}
    labelPlacement="start"
    control={<Switch checked={checked} onChange={onChange} />}
    label={
      <Box component="span" sx={{ marginTop: '5px' }}>
        <Box component="span">Optimize for Roll20 Free</Box>
        <HelpTooltip
          iconSx={{ verticalAlign: 'top' }}
          title="Roll20 Free users are limited to 10MB images. Choosing this option will downscale images for you automatically."
        />
      </Box>
    }
  />
)

const value = 'roll20_free'

type Props = {
  sx?: SxProps<Theme>
}

const VttDownloadTargetSelector = ({ sx = {} }: Props) => {
  const { session } = useContext(AppLoaderContext)
  const [optimized, setOptimized] = useState<boolean>(
    session?.user ?
      session.user.vttDownloadTarget === value :
      Cookies.get(CookieName.VttDownloadTarget) === value
  )

  if (!session) return null

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptimized(event.target.checked);
    const vttDownloadTarget = (event.target.checked ? value : null)
    if (session.user) {
      ApiClient.putCamelcased(
        "/user", { vtt_download_target: vttDownloadTarget }
      )
    } else if (vttDownloadTarget) {
      Cookies.set(CookieName.VttDownloadTarget, vttDownloadTarget)
    } else {
      Cookies.remove(CookieName.VttDownloadTarget)
    }
  }

  return (
    <Presentation
      sx={sx}
      checked={optimized}
      onChange={handleChange}
    />
  )
}

export default VttDownloadTargetSelector
