const Iframes = ({ downloadUrls }: { downloadUrls: string[] }) => (
  <>
    {downloadUrls.map((downloadUrl, i) => (
      <iframe
        title={`download-iframe-${i}`}
        key={`download-iframe-${i}`}
        data-testid={`download-iframe-${i}`}
        frameBorder="0"
        style={{ width: 0, height: 0, visibility: 'hidden' }}
        src={downloadUrl}
      />
    ))}
  </>
)

export default Iframes