import React from 'react';
import { Event } from 'Tracker'
import { withPageWrapper } from 'pages'
import { Layout } from 'layouts'
import { Header } from 'components/page'
import { Box, Button, Grid } from '@mui/material'
import { borderRadius } from 'components/cards'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { FlashMessageContext } from 'providers/FlashMessageProvider';
import { setInfoMessageAction } from 'reducers/useFlashMessageReducer';
const discordIcon = require('./discord-mark-white.svg').default as string;
import Rails from 'Rails'

const sectionCardSx = {
  borderRadius,
  boxShadow: '2px 2px 5px lightslategray',
  textAlign: 'center',
}

const installNow = () => {
  window.tracker.track(Event.DiscordBotPageInstallClicked, { sendImmediately: true })
  window.location.href = Rails.discordOauthStartUrl({ success_path: '/discord-bot' }).toString()
}

const DiscordBotPage = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { flashMessageDispatch } = React.useContext(FlashMessageContext)

  React.useEffect(() => {
    const discordBotInstalled = searchParams.get('discord_bot_installed')
    if (discordBotInstalled) {
      flashMessageDispatch(
        setInfoMessageAction({ message: "The bot has now been added to your Discord server. Open up Discord and start searching!" })
      )
      searchParams.delete('discord_bot_installed')
      let destination = "/discord-bot"
      const queryString = searchParams.toString()
      if (queryString !== '') {
        destination = `${destination}?${queryString}`
      }
      navigate(destination, { state: { discordBotInstalled: true } })
    }
  }, [searchParams])

  return (
    <Layout backgroundColor="light" padding="medium">
      <Box sx={{ marginBottom: '25px' }}>
        <Header>Discord bot</Header>
      </Box>

      <Grid container spacing={3}>
        <Grid item md={4}>
          <Box sx={sectionCardSx}>
            <Box
              component="img"
              src="/images/discord-bot.png"
              sx={{ maxWidth: '100%' }}
            />
          </Box>
        </Grid>

        <Grid item md={8}>
          <Box>
            Searching for battle maps has never been easier.  With Lost Atlas&apos; Discord bot, you can search our database of {process.env.REACT_APP_ROUGH_MAP_COUNT}+ free and premium maps without ever leaving the chat.
          </Box>
          <Box
            sx={{
              padding: { xs: '25px', sm: '25px 25px 25px 0', md: '25px' },
              textAlign: { xs: 'center', sm: 'left', md: 'center' }
            }}
          >
            <Button
              variant="contained"
              size="large"
              startIcon={<Box component="img" sx={{ width: '24px' }} src={discordIcon} />}
              onClick={installNow}
            >
              Install now
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default withPageWrapper(
  DiscordBotPage, Event.DiscordBotPageLoaded, { setCanonicalUrl: true }
)
