import { createContext } from 'react'

export type LayoutContextValue = {
  navOpen: boolean
  setNavOpen: (navOpen: boolean) => void
}

export const LayoutContext = createContext<LayoutContextValue>({
  navOpen: false,
  setNavOpen: (navOpen: boolean) => {}
})
