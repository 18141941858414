// These constants are legacy behavior left here because there are still
// back-references.  Most of this has been moved to the drawer-specific stuff
// in pages/SearchPage/components/mobile/events

import Event from './event'

export enum filterEventType {
  CANCEL = 'cancel',
  CHANGE = 'change',
  OPEN = 'open',
}

export type FilterEventFromSlug = (slug: filterEventType) => Event
