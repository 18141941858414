import React, { forwardRef, useContext } from 'react';
import { Box, IconButton } from '@mui/material'
import { Menu as MuiMenu } from '@mui/icons-material'
import { AppLoaderContext } from 'AppLoader'
import { AccountMenu, SingleRowBar, Container, Logo, Search } from 'layouts/TopAppBarBase'
import { Props } from './types'
import { Session } from 'models'
import { LayoutContext } from '../context'

type MenuProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const Menu = ({ onClick }: MenuProps) => (
  <IconButton
    sx={{
      color: 'white',
      padding: '8px 5px 12px 0',
      position: 'absolute'
    }}
    size='large'
    onClick={onClick}
  >
    <MuiMenu />
  </IconButton>
)

const TopRight = ({ session }: { session?: Session }) => (
  <Box
    sx={{
      position: 'absolute',
      right: {
        'xs': '8px',
        'lg': '16px',
      }
    }}
  >
    {session && <AccountMenu session={session} />}
  </Box>
)

const MobileAppBar = forwardRef<HTMLElement, Props>((props, ref) => {
  const { children, position = 'static' } = props
  const { session } = useContext(AppLoaderContext)
  const { navOpen, setNavOpen } = useContext(LayoutContext)

  return (
    <Container position={position}>
      <SingleRowBar ref={ref}>
        <Menu onClick={() => setNavOpen(!navOpen)} />
        <Search
          sx={{ position: 'absolute', left: { xs: '35px', lg: '50px' } }}
        />
        <Logo sx={{ textAlign: "center", flexGrow: 1 }} />

        <TopRight session={session} />
      </SingleRowBar>
      {children}
    </Container>
  )
})

MobileAppBar.displayName = 'MobileAppBar'

export default MobileAppBar;
