import Rails from 'Rails'

export const auth0OauthUrl = (queryParams = {}) => (
  Rails.url(
    '/users/auth/auth0/oauth',
    { ...({ destination: window.location.href }), ...queryParams }
  )
)

export const navigateToAuth0 = () => {
  window.location.assign(auth0OauthUrl())
}
