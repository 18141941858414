import React from 'react';
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { Box , CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
const SpinnerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '50px 0',
})
import { Layout } from 'layouts'
import { FlashMessageContext } from 'providers/FlashMessageProvider';
import { setInfoMessageAction } from 'reducers/useFlashMessageReducer';
import ApiClient from 'ApiClient'
import { Event } from 'Tracker'
import { Routes } from 'routing/deprecated'

const NewsletterUnsubscriber = () => {
  const [searchParams] = useSearchParams()
  const { subscriptionId } = useParams()
  const navigate = useNavigate()
  const { flashMessageDispatch } = React.useContext(FlashMessageContext) as { flashMessageDispatch }
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)

  const showUnsubscribeSuccess = () => {
    flashMessageDispatch(setInfoMessageAction({ message: "You have been unsubscribed." }))
    navigate(`/`)
  }

  React.useEffect(() => {
    if (subscriptionId) {
      let path = `/email/subscriptions/${subscriptionId}`
      const authorizationKey = searchParams.get('ak')
      if (authorizationKey) {
        path = path + `?ak=${authorizationKey}`
      }
      ApiClient
        .deleteCamelcased(path)
        .then(() => {
          window.tracker.track(Event.NewsletterUnsubscribed)
          showUnsubscribeSuccess()
        })
        .catch(({ response }) => {
          if (response.status === 404) {
            showUnsubscribeSuccess()
          } else if (response.data.errors.user_sign_in_required) {
            navigate(Routes.emailSubscriptionsPath())
          } else {
            setErrorMessage(
              "Looks like something was wrong with your unsubscribe link. Please try clicking the link in your email again, or contact us at support@lostatlas.co."
            )
          }
        })
    }
  }, [searchParams, subscriptionId])

  return (
    <Layout>
      <SpinnerContainer>
        {!errorMessage && <CircularProgress color="primary" size={80} />}
        {errorMessage && <Box>{errorMessage}</Box>}
      </SpinnerContainer>
    </Layout>
  );
}

export default NewsletterUnsubscriber
