import React, { useContext } from 'react';
import AppContext from 'AppContext'
import { Event } from 'Tracker'
import { withPageWrapper } from 'pages'
import { Layout } from 'layouts'
import { Header } from 'components/page'
import { Box, Grid } from '@mui/material'
import { styled } from '@mui/system';

const IframeContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    height: '600px'
  }
}))

const Iframe = styled('iframe', { shouldForwardProp: (prop) => true})({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%',
})

const FoundryModulePage = () => {
  const { deviceType } = useContext(AppContext)
  const usingPhone = deviceType === 'phone'

  let header: string
  if (usingPhone) {
    header = '[BETA] Search and download maps in Foundry'
  } else {
    header = '[BETA] Search and download maps in Foundry with the Lost Atlas module'
  }

  return (
    <Layout backgroundColor="light" padding="medium">
      <Box sx={{ marginBottom: '25px' }}>
        <Header>{header}</Header>
      </Box>
      <Grid container spacing={{ xs: 1, sm: 2, lg: 3 }}>
        <Grid item xs={12} sm={2} lg={1}>
            <Box
              component="img"
              src="/images/fvtt-d20.png"
              sx={{ maxWidth: { xs: '20%', sm: '100%' } }}
            />
        </Grid>
        <Grid item xs={12} sm={10} lg={5}>
          We&apos;re developing a Foundry VTT module that will let you search from thousands of maps and download them directly into your Foundry server. If you would like to join the beta test, enter your email address and we will contact you soon.
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <Box>
            <IframeContainer>
              <Iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSeeycq_c-pGd_iAirQmrc4O1GqqVOmm8vN_5dryviMXOru1iw/viewform?embedded=true"
                frameBorder={0}
                marginHeight={0}
                marginWidth={0}
              >
                Loading…
              </Iframe>
            </IframeContainer>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default withPageWrapper(
  FoundryModulePage, Event.FoundryModulePageLoaded, { setCanonicalUrl: true }
)
