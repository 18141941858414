import { useContext } from 'react'
import { MapVariationForProduct } from 'models'
import { createDownloadUrl } from 'models/MapVariationForProduct'
import { setInfoMessageAction } from 'reducers/useFlashMessageReducer';
import { Event } from 'Tracker'
import { FlashMessageContext } from 'providers/FlashMessageProvider'
import { PrivateAssetDownloadContext } from 'providers/PrivateAssetDownloadProvider'
import { Subscription } from '../Subscription'

const dateFormat = new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric' })

export type DownloadMapVariationFn = (mv: MapVariationForProduct) => void

export const useDownloadMapVariation = (subscription: Subscription | null): DownloadMapVariationFn => {
  const { flashMessageDispatch } = useContext(FlashMessageContext)
  const { downloadPrivateAsset } = useContext(PrivateAssetDownloadContext)

  if (subscription) {
    return (mapVariation: MapVariationForProduct) => {
      downloadPrivateAsset(
        () => createDownloadUrl(mapVariation),
        Event.SubscriptionMapDownloaded,
        mapVariation.battleMapId
      ).then(() => {
        const { currentPeriodEnd, downloadsRemainingThisPeriod } = subscription;
        const message = `You have ${downloadsRemainingThisPeriod} map download${downloadsRemainingThisPeriod !== 1 ? 's' : ''} remaining until your next billing date on ${dateFormat.format(currentPeriodEnd)}.`
        flashMessageDispatch(setInfoMessageAction({ message }))
      })
    }
  } else {
    return (_mapVariation: MapVariationForProduct) => {}
  }
}
