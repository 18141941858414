import React from 'react';
import Layout from 'layouts/Layout';
import Following from './Following';
import { Event } from 'Tracker';
import { FollowshipLoader } from 'loaders/FollowshipLoader';
import { withPageWrapper } from 'pages'

function FollowingPage() {
  return (
    <FollowshipLoader>
      <Layout backgroundColor="dark">
        <Following />
      </Layout>
    </FollowshipLoader>
  )
}

export default withPageWrapper(FollowingPage, Event.FollowingPageLoaded, { signInRequired: true })
