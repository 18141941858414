import React, { forwardRef } from 'react';
import AppBar from './AppBar'
import Toolbar from './Toolbar'

export const singleRowHeight = 56;

type Props = {
  children?: React.ReactNode;
}

const SingleRowBar = forwardRef<HTMLElement, Props>(({ children }, ref) => (
  <AppBar sx={{ height: `${singleRowHeight}px` }} ref={ref}>
    <Toolbar>
      {children}
    </Toolbar>
  </AppBar>
))

SingleRowBar.displayName = 'SingleRowBar'

export default SingleRowBar;
