import React from 'react';
import { Box } from '@mui/material'

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  return (
      <Box
        sx={{
          display: 'flex',
          height: {
            xs: '99vh', sm: '98vh', lg: '92vh'
          }
        }}
      >
        {children}
      </Box>
  )
}

export default Layout
