import React from 'react';
import { PopperProps } from '@mui/material'
import MaterialTooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { colors } from 'theme';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MaterialTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '.8em',
    fontWeight: 'bold',
    padding: '5px 10px',
    backgroundColor: colors.darkGrey,
    textTransform: 'uppercase'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: colors.darkGrey,
  }
}));

const Tooltip = ({
    children,
    ...props
  }: TooltipProps) => {
  return (
    <StyledTooltip
      placement={'top-start'}
      {...props}
      PopperProps={{ 'data-testid': 'tooltip'} as unknown as PopperProps}
    >
      {children}
    </StyledTooltip>
  )
}

export default Tooltip;
