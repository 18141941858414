import React from 'react'
import {
  DialogTitle, DialogContent, DialogActions, Button, TextField
} from '@mui/material'
import { Form as FormikForm, FormikProps } from 'formik'
import { ErrorMessage } from 'components/form'

type OnCloseFn = () => void

type Response = {
  battleMapMapId: string
  problem: string
}

type Props = {
  formikProps: FormikProps<Response>
  onClose: OnCloseFn
}

const Form = ({ formikProps, onClose }: Props) => {
  const { errors } = formikProps;

  return (
    <FormikForm>
      <DialogTitle>Report a problem</DialogTitle>
      <DialogContent>
        <TextField
          id="problem"
          inputProps={{ 'data-testid': "problem" }}
          fullWidth
          multiline
          rows={3}
          {...formikProps.getFieldProps('problem')}
        />
        {errors.problem && <ErrorMessage name='problem' />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </FormikForm>
  )
}

export default Form
