import { MapVariation } from './MapVariation'
import { PrivateAsset } from './PrivateAsset'
import ApiClient from 'ApiClient'
import { CreateDownloadUrlResponse } from 'providers/PrivateAssetDownloadProvider'

export interface MapVariationForProduct extends MapVariation {
  id: number
  privateAsset: PrivateAsset
}

export const createDownloadUrl = (mapVariation: MapVariationForProduct): Promise<CreateDownloadUrlResponse> => (
  new Promise((resolve, reject) => {
    ApiClient
      .postCamelcased("/d", { map_variation_id: mapVariation.id })
      .then(resp => resolve(resp.data))
      .catch(reject)
  })
)
