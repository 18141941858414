import mixpanel, { Dict, RequestOptions } from 'mixpanel-browser';
import Event from './event'
import { defaultMixpanelProperties, MixpanelProperties } from './mixpanel'
import { Session } from 'models'
import { Status } from 'models/Subscription'
import * as Sentry from "@sentry/react"

/*
Shims third-party tracking services: Mixpanel and Sentry.
*/

interface Props {
  mixpanel: {
    token?: string,
    debug: boolean,
  }
}

export interface EventOpts {
  sendImmediately?: boolean;
  mixpanelProperties?: MixpanelProperties;
}

type KeyedCallbackArrays<Type> = {
  [Property in keyof Type]: {
    [id: number]: () => void
  }
}

export type TrackFn = (event: Event, eventOpts?: EventOpts) => Promise<void>

class Tracker {
  readonly usingMixpanel: boolean;
  private session: Session | null
  private subscriptionIdSequence: number = 0
  readonly subscriptions: KeyedCallbackArrays<Event>

  constructor(props: Props) {
    const { mixpanel: mp } = props
    if (mp.token) {
      const opts = { debug: mp.debug }
      mixpanel.init(mp.token, opts);
      this.usingMixpanel = true;
    } else {
      this.usingMixpanel = false;
    }
    this.session = null
    this.subscriptions = {} as KeyedCallbackArrays<Event>
  }

  reset() {
    this.session = null;
    if (this.usingMixpanel) {
      mixpanel.reset()
    } else {
      console.log("Tracker.reset")
    }
  }

  track(event: Event, eventOpts: EventOpts = {}): Promise<void> {
    const eventName = event as unknown as string
    const { sendImmediately, mixpanelProperties } = eventOpts;
    const opts = {
      sendImmediately,
      mixpanelProperties: {
        ...defaultMixpanelProperties(event), ...mixpanelProperties
      }
    }
    let result: Promise<void>
    if (this.usingMixpanel) {
      result = this.trackInMixpanel(eventName, opts)
    } else {
      this.trackInConsole(eventName, opts)
      result = Promise.resolve()
    }
    if (this.subscriptions[event]) {
      Object.values<() => void>(this.subscriptions[event]).forEach(cb => {
        try { cb() } catch { }
      })
    }
    return result;
  }

  getMixpanelDistinctId(): string | undefined {
    if (this.usingMixpanel) {
      return mixpanel.get_distinct_id()
    }
  }

  setUserProperties(userProperties: any): void {
    if (this.session?.user) {
      if (this.usingMixpanel) {
        mixpanel.people.set(userProperties)
      } else {
        console.log('Tracker.setUserProperties', userProperties)
      }
      Sentry.setUser({ id: this.session.user.id })
    }
  }

  subscribe(event: Event, callback: () => void): number {
    const callbacks = this.subscriptions[event] || {}
    this.subscriptionIdSequence += 1
    callbacks[this.subscriptionIdSequence] = callback
    this.subscriptions[event] = callbacks
    return this.subscriptionIdSequence
  }

  unsubscribe(subscriptionId: number) {
    Object.keys(this.subscriptions).forEach((event) => {
      const subscriptions = this.subscriptions[event]
      if (subscriptions[subscriptionId]) {
        delete subscriptions[subscriptionId]
        this.subscriptions[event] = subscriptions
      }
    })
  }

  private trackInMixpanel(eventName: string, opts: EventOpts = {}): Promise<void> {
    let properties: Dict;
    if (typeof opts.mixpanelProperties === 'object') {
      properties = opts.mixpanelProperties;
    } else {
      properties = {};
    }
    let options: RequestOptions;
    if (opts.sendImmediately) {
      options = { send_immediately: true }
    } else {
      options = {}
    }
    return new Promise((resolve, reject) => {
      mixpanel.track(eventName, properties, options, (err) => {
        if (err) {
          reject(err)
        } else {
          resolve()
        }
      })
    })
  }

  private trackInConsole(eventName: string, opts: EventOpts = {}) {
    console.log('Tracker.track', eventName, opts)
  }

  trackLinks(query: string, eventName: string, href: string) {
    if (this.usingMixpanel) {
      // It's too easy to call this when these links have yet to be visible in
      // the browser, so we setTimeout 0 this.
      setTimeout(() => mixpanel.track_links(query, eventName, { href }), 0)
    }
  }

  setSession(session: Session): void {
    this.session = session
    if (this.usingMixpanel) {
      if (session.user) {
        mixpanel.identify(session.user.id.toString())
      }
    } else {
      console.log('Tracker.setSession', session)
    }
    const subscriber = session.user?.subscription?.status === Status.Active
    this.setSuperProperties({ signed_in: Boolean(session.user), subscriber })
  }

  setSuperProperties(props: Dict) {
    if (this.usingMixpanel) {
      mixpanel.register(props)
    } else {
      console.log('Tracker.setSuperProperties', props)
    }
  }

  setSuperPropertiesOnce(props: Dict) {
    if (this.usingMixpanel) {
      mixpanel.register_once(props)
    } else {
      console.log('Tracker.setSuperPropertiesOnce', props)
    }
  }
}

export default Tracker

