import { Creator } from './Creator'
import { Dimensions } from './Dimensions'
import { Environment, environmentFromId } from './Environment'
import { PageMetadata } from 'document'
import truncate from 'lodash/truncate'

export interface BattleMap {
  // Ugh let's clean up this ID situation one day
  id: string;
  numericId: number

  arHeight: number;
  arWidth: number;
  body: string;
  creator: Creator;
  environmentIds: string[];
  gridDimensions: Dimensions;
  name: string
  premium: boolean
  sanitizedBodyHtml: string;
}

export const aspectRatio = (bm: Pick<BattleMap, 'arWidth' | 'arHeight'>): number => {
  return bm.arWidth / bm.arHeight;
}

export const environments = (bm: BattleMap): Environment[] => {
  return (bm.environmentIds || []).map(
    environmentId => environmentFromId(environmentId)
  )
}

export const pageMetadataForBattleMap = (map: Pick<BattleMap, 'name' | 'body'> ): PageMetadata => {
  const title = truncate(
    `Lost Atlas - ${map.name || map.body}`, { length: 60, omission: '…' }
  )
  return { title, metaDescription: map.body }
}

export const pseudoName = (battleMap: BattleMap, length: number): string => (
  truncate(battleMap.body, { length, omission: '…', separator: /\s+/ })
)
