import React, { useContext } from 'react';
import { Logo, AccountMenu } from 'layouts/TopAppBarBase'
import { AppLoaderContext, AppLoaderContextValue } from 'AppLoader'
import { Box } from '@mui/material'
import { desktopTopRowLogoWidth } from './theme'
import { Props } from './props'
import SearchTextField from './SearchTextField'
import MiscMenu from './MiscMenu'

export * from './theme'

const DesktopSearchRedesign = (props: Props) => {
  const { SearchTextFieldProps = {} } = props
  const { ref: searchTextFieldRef, ...searchTextFieldRest } = SearchTextFieldProps
  const { session } = useContext(AppLoaderContext) as AppLoaderContextValue

  return (
    <>
      <Logo sx={{ width: desktopTopRowLogoWidth }} />
      <Box
        sx={{
          flexGrow: 1,
            paddingRight: '50px',
        }}
      >
        <SearchTextField
          ref={searchTextFieldRef}
          {...searchTextFieldRest}
        />
      </Box>
      <MiscMenu />
      {session && <AccountMenu session={session} />}
    </>
  )
}

export default DesktopSearchRedesign
