export const zIndexes = {
  zoomInBlurBackground: 0,
  zoomInButton: 10,
  resultCardEnvironments: 90,
  resultCardActions: 100,
  mapCardImage: 200,
  appBar: 1000,
  popper: 1150,
  overlay: 1200,
}
