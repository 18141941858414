import React, { forwardRef } from 'react';
import { AppBar as MuiAppBar, SxProps } from '@mui/material'
import { colors } from 'theme'

type Props = {
  children?: React.ReactNode;
  position?: 'static' | 'fixed'
  sx?: SxProps
}

const AppBar = forwardRef<HTMLElement, Props>((props, ref) => {
  const { children, position = 'static', sx = {} } = props

  return (
    <MuiAppBar
      position={position}
      sx={{ backgroundColor: colors.headerBackground, ...sx }}
      ref={ref}
    >
      {children}
    </MuiAppBar>
  )
})

AppBar.displayName = 'AppBar'

export default AppBar;
