export enum AccessType {
  External = 'external',
  Hosted = 'hosted',
  Premium = 'premium',
}

export type DeprecatedAccessType = 'premium' | 'free_external' | 'direct_download'

export const accessTypeFromApiResponse = (accessType: DeprecatedAccessType | AccessType): AccessType => {
  switch (accessType) {
    case 'direct_download':
      return AccessType.Hosted;
    case 'free_external':
      return AccessType.External;
    case 'premium':
      return AccessType.Premium;
  }
  return accessType
}
