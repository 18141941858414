import React from 'react'
import * as Sentry from "@sentry/react"
import { BrowseableBattleMap, SrcsetEntry } from 'models'
import { imgProps as variationImgProps } from 'models/MapVariation'

type ImgProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

type BuildImgPropsProps = {
  battleMap: BrowseableBattleMap
  fullWidthColumns: number
  srcFromNarrowestPreview: string | null
  srcsetEntries: SrcsetEntry[]
}

export const buildImgProps = (props: BuildImgPropsProps): ImgProps => {
  const { battleMap, fullWidthColumns, srcFromNarrowestPreview, srcsetEntries } = props

  const imgProps = variationImgProps({
    battleMap,
    srcsetEntries,
    setIntrinsicDimensionsFromBattleMap: true
  })
  if (srcFromNarrowestPreview) {
    imgProps.src = srcFromNarrowestPreview
  } else {
    const intrinsicWidthsDesc = srcsetEntries.map(e => e.intrinsicWidth).sort((a, b) => (b - a))
    const fallbackTargetWidth = 350
    const intrinsicWidth = intrinsicWidthsDesc.find(iw => iw < fallbackTargetWidth)
    const fallbackSrcsetEntry = srcsetEntries.find(e => e.intrinsicWidth === intrinsicWidth)
    if (fallbackSrcsetEntry) {
      imgProps.src = fallbackSrcsetEntry.url
    } else {
      Sentry.captureMessage(`Could not find fallback entry for map ${battleMap.id}`)
    }
  }
  if (srcsetEntries.length > 0) {
    imgProps.sizes = `${Math.round(100 / fullWidthColumns)}vw`
  } else {
    imgProps.sizes = ''
  }
  return imgProps
}
