import { Creator } from './Creator';
import { AccessType, accessTypeFromApiResponse } from './AccessType'

export type MapSubmission = {
  id?: number;
  creator: Creator;
  name: string;
  descriptionSanitizedHtml: string;
  gridWidth: number;
  gridHeight: number;
  accessTypes: AccessType[]
  publicationJobInProgress: boolean
}

export const mapSubmissionFromApiResponse = (data): MapSubmission => ({
  ...data,
  accessTypes: data.accessTypes.map(at => accessTypeFromApiResponse(at))
})
