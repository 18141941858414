import Cookies from 'js-cookie';
import { getRandomInt } from 'misc';
import { CookieName } from 'storage';
import SplitTest from './SplitTest'
import { currentSplitTests } from './current'

export { SplitTests } from './current'
export { default as SplitTest } from './SplitTest'

export class SplitTestParticipations {
  readonly participations: {}

  static newFromCookieString(cookieString: string) {
    const obj = JSON.parse(cookieString)
    const result = new SplitTestParticipations()
    currentSplitTests().forEach(splitTest => {
      if (Object.keys(obj).indexOf(splitTest.cookieKey) > -1) {
        result.set(splitTest, obj[splitTest.cookieKey] === 1)
      }
    })
    return result
  }

  constructor() {
    this.participations = {}
  }

  set(splitTest: SplitTest, participation: boolean): void {
    if (splitTest.expired) throw new Error("Cannot use expired cookieKeys") 
    this.participations[splitTest.cookieKey] = participation
  }

  get(splitTest: SplitTest): boolean {
    return this.participations[splitTest.cookieKey]
  }

  hasParticipation(splitTest: SplitTest): boolean {
    return (splitTest.cookieKey in this.participations)
  }

  toCookieString(): string {
    const obj = {}
    currentSplitTests().forEach(splitTest => {
      obj[splitTest.cookieKey] = (this.get(splitTest) ? 1 : 0)
    })
    return JSON.stringify(obj)
  }
}

export const mixpanelProperties = (participations: SplitTestParticipations): any => {
  const result = {}
  currentSplitTests().forEach(splitTest => {
    result[splitTest.mixpanelKey] = participations.get(splitTest)
  })
  return result
}

// Set up this visitor for all current split tests:
// - Ensure this visitor is assigned to be either participating or not in each
//   current split test.  If split-test participations were previously set and
//   saved in the cookie, respect those participations and assign for new split
//   tests
// - Save those participations in the cookie for future visits
// - Set those participations as Mixpanel super-properties, which will be added
//   to all Mixpanel events
// - Return a split-test object for use in React
export const ensureSplitTests = ():SplitTestParticipations => {
  let participations: SplitTestParticipations
  const userSplitTestsStr: string | undefined = Cookies.get(CookieName.SplitTests)
  if (userSplitTestsStr) {
    participations = SplitTestParticipations.newFromCookieString(userSplitTestsStr)
  } else {
    participations = new SplitTestParticipations()
  }
  let participationSet = false
  currentSplitTests().forEach(splitTest => {
    if (!participations.hasParticipation(splitTest)) {
      participations.set(splitTest, getRandomInt(2) === 1)
      participationSet = true
    }
  })
  if (participationSet) {
    Cookies.set(
      CookieName.SplitTests, participations.toCookieString(), { expires: 3650 }
    )
  }
  window.tracker.setSuperProperties(mixpanelProperties(participations))
  return participations
}
