import { BrowseableBattleMap } from './BrowseableBattleMap'
import { s3BackedImageUrl, mapThumbnailUrl } from 'images';
import { AccessType } from './AccessType'
import { PrivateAsset } from './PrivateAsset';
import { MapVariationCloudFrontPreview } from './MapVariationCloudFrontPreview'
import { MapVariationUploadcarePreview } from './MapVariationUploadcarePreview'
import { SrcsetEntry } from './SrcsetEntry'

export interface MapVariation {
  id: number
  battleMapId: string
  imageSlug: string;
  cfPreviews?: MapVariationCloudFrontPreview[]
  ucPreviews?: MapVariationUploadcarePreview[]
  environments: string[];
  thumbnailImage: string;
  privateAsset: PrivateAsset;
}

// Images that were pre-shrunk and stored that way in S3 -- these aren't
// ideal and we're trying to move away from them, but they are present on
// every map of every type
export const preShrunkImageUrl = (mv: Pick<MapVariation, 'imageSlug'>): string => mapThumbnailUrl(mv.imageSlug)

export const watermarkedPreviewUrl = (mv: Pick<MapVariation, 'battleMapId' | 'imageSlug'>, bm: BrowseableBattleMap): string | null => {
  if (bm.accessType === AccessType.External) {
    return null;
  } else {
    return premiumWatermarkedPreviewUrl(mv)
  }
}

// For premium map variations only
export const premiumWatermarkedPreviewUrl = (mv: Pick<MapVariation, 'imageSlug'>): string => (
  s3BackedImageUrl('maps/watermarked_previews', mv.imageSlug)
)

export const srcset = (entries: SrcsetEntry[]): string => {
  return entries.map(({ url, intrinsicWidth }) => `${url} ${intrinsicWidth}w`).join(', ')
}

type HTMLImgProps = Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'ref'>;

type ImgPropsProps = {
  battleMap: BrowseableBattleMap
  srcsetEntries: SrcsetEntry[],
  setIntrinsicDimensionsFromBattleMap?: boolean
}

export const imgProps = (props: ImgPropsProps): HTMLImgProps => {
  const {
    battleMap,
    srcsetEntries,
    setIntrinsicDimensionsFromBattleMap = false
  } = props

  const result: HTMLImgProps = {
    alt: battleMap.name,
    srcSet: srcset(srcsetEntries)
  }
  if (setIntrinsicDimensionsFromBattleMap) {
    result.width = battleMap.arWidth;
    result.height = battleMap.arHeight;
  }
  return result
}
