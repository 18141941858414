import React from 'react';
import {
  IconButton, Snackbar as MuiSnackbar
} from '@mui/material';
import { Close } from '@mui/icons-material';

type SnackbarProps = {
  open: boolean;
  setOpen: (open: boolean) => void
}

function Snackbar({ open, setOpen }: SnackbarProps) {
  return (
    <MuiSnackbar
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          <Close fontSize="small" />
        </IconButton>
      }
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={() => setOpen(false)}
      autoHideDuration={2000}
      message="You have been subscribed."
    />
  )
}

export default Snackbar
