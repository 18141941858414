import React from 'react';
import { Toolbar as MuiToolbar } from '@mui/material'
import { SxProps } from '@mui/material/styles'

type Props = {
  children?: React.ReactNode;
  sx?: SxProps
  variant?: 'dense' | 'regular'
}

const Toolbar = ({ children, sx = {}, variant = 'regular' }: Props) => (
  <MuiToolbar
    sx={{
      paddingLeft: {
        'xs': '5px !important',
        'lg': '16px !important'
      },
      paddingRight: {
        'xs': '8px !important',
        'lg': '16px !important'
      },
      ...sx
    }}
    variant={variant}
  >
    {children}
  </MuiToolbar>
)

export default Toolbar;
