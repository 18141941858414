import React from 'react'
import { MapVariationForProduct } from 'models'
import { MapVariationZoom as DownloadPageMapVariationZoom } from 'components/mapDownloadPage'

type Props = {
  battleMapAspectRatio: number
  downloadMapVariation: (mv: MapVariationForProduct) => void
  mapVariations: MapVariationForProduct[]
  setZoomedInMapVariation: (mv: MapVariationForProduct | null) => void
  zoomedInMapVariation: MapVariationForProduct
  zoomOut: () => void
}

const MapVariationZoom = (props: Props) => {
  const {
    battleMapAspectRatio,
    downloadMapVariation,
    mapVariations,
    setZoomedInMapVariation,
    zoomedInMapVariation,
    zoomOut
  } = props

  return(
    <DownloadPageMapVariationZoom
      download={() => downloadMapVariation(zoomedInMapVariation)}
      battleMapAspectRatio={battleMapAspectRatio}
      mapVariations={mapVariations}
      setZoomedInMapVariation={setZoomedInMapVariation}
      zoomedInMapVariation={zoomedInMapVariation}
      zoomOut={zoomOut}
    />
  )
}

export default MapVariationZoom

