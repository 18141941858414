import React from 'react';
import { styled } from '@mui/material/styles';

export type PaddingValue = 'none' | 'small' | 'medium'

export const SmallPadding = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8px'
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '13px',
    }
}))

export const MediumPadding = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px 16px 16px'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '16px 32px 32px 32px',
    }
}))

type Props = {
  children: React.ReactNode,
  padding?: PaddingValue
}

const Padding = ({ children, padding }: Props) => {
  if (padding === 'small') return <SmallPadding>{children}</SmallPadding>
  if (padding === 'medium') return <MediumPadding>{children}</MediumPadding>
  return <>{children}</>
}

export default Padding
