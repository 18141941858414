export const colors = {
  controlHeader: '#1b1d21',
  filterLabel: '#1b1d21',
  headerBackground: '#121315',
  darkBackground: '#1b1d21',
  offWhiteBackground: '#fcfcfc',
  darkBlue: '#2F41A5',
  lightBlue: '#5e97d9',
  lightGrey: '#ececec',
  darkGrey: '#333333',
  orange: '#f22e38',
}