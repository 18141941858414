import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DocumentValue, DocumentContext } from './context'
import { writeTag } from './document'

export const writeCanonicalUrl = (canonicalAbsolutePath: string | null): void => {
  let attrValue: string | null = null;
  if (canonicalAbsolutePath) {
    const baseUrl = new URL(window.location.href)
    const url = new URL(canonicalAbsolutePath, baseUrl)
    attrValue = url.toString()
  }
  writeTag({
    tagName: 'link',
    fixedAttrName: 'rel',
    fixedAttrValue: 'canonical',
    attrName: 'href',
    attrValue
  })
}

export const useUrlAsCanonicalUrl = () => {
  const location = useLocation()
  const { setCanonicalAbsolutePath } = useContext(DocumentContext) as DocumentValue

  useEffect(() => {
    setCanonicalAbsolutePath(location.pathname)
    return () => setCanonicalAbsolutePath(null)
  }, [location])
}
