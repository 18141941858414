import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter,
  Routes as ReactRouterRoutes,
  Route,
  Navigate,
  useParams
} from "react-router-dom";
import Redirect from './Redirect'

import AfterSignInRedirect from 'redirects/AfterSignInRedirect';
import CreatorOnboardRedirect from 'redirects/CreatorOnboardRedirect';
import FollowingPage from 'pages/FollowingPage';
import ContactUsPage from 'pages/ContactUsPage';
import OrderThanksRedirect from 'redirects/OrderThanksRedirect';
import PatreonRewardPage from 'pages/PatreonRewardPage';
import PatreonRewardsCreatorSpecificOnboarding from 'pages/PatreonRewardsCreatorSpecificOnboarding';
import HomePage from 'pages/HomePage';
import OwlbearRodeoDevelopmentRedirect from 'redirects/OwlbearRodeoDevelopmentRedirect'
import Paths from './Paths'
import SubscriptionMapPage from 'pages/SubscriptionMapPage';
import NewsletterUnsubscriber from 'redirects/NewsletterUnsubscriber';
import SubscribeSuccessRedirect from 'redirects/SubscribeSuccessRedirect'
import SubscribeFromDiscordRedirect from 'redirects/SubscribeFromDiscordRedirect'
import DiscordBotPage from 'pages/DiscordBotPage'
import DiscordBotInstallRedirect from 'redirects/DiscordBotInstallRedirect'
import ResubscribePage from 'pages/ResubscribePage';
import SuspenseRouteMapPageElement from './SuspenseRouteMapPageElement';
import SuspenseRouteSearchElement from './SuspenseRouteSearchElement';
import FoundryModulePage from 'pages/FoundryModulePage'
import EmailGuestConfirmedRedirect from 'redirects/EmailGuestConfirmedRedirect'
import SuspenseRouteElement from './SuspenseRouteElement'
import FlashMessageRedirect from 'redirects/FlashMessageRedirect'
import Layout from 'layouts/Layout'
import PageGlobals from './PageGlobals'

const Admin = lazy(() => import('sections/admin'))
const BookmarksPage = lazy(() => import('pages/BookmarksPage'))
const CheckoutPage = lazy(() => import('pages/CheckoutPage'))
const CreatorPage = lazy(() => import('pages/CreatorPage'))
const CreatorDashboard = lazy(() => import('sections/creatorDashboard'))
const EmailSubscriptionsPage = lazy(() => import('pages/EmailSubscriptionsPage'))
const FaqPage = lazy(() => import('pages/FaqPage'))
const OrderPage = lazy(() => import('pages/OrderPage'))
const PatreonRewardsPage = lazy(() => import('pages/PatreonRewardsPage'))
const PurchasedMapsPage = lazy(() => import('pages/PurchasedMapsPage'))
const MapPage = lazy(() => import('pages/MapPage'))
const PrivacyPolicyPage = lazy(() => import('pages/PrivacyPolicyPage'))
const SearchPage = lazy(() => import('pages/SearchPage'))
const SubscribeSuccessPage = lazy(() => import('pages/SubscribeSuccessPage'))
const SubscriptionLandingPage = lazy(() => import('pages/SubscriptionLandingPage'))
const SubscriptionPage = lazy(() => import('pages/SubscriptionPage'))
const TermsOfServicePage = lazy(() => import('pages/TermsOfServicePage'))

const MapRouteRedirect = () => {
  const { mapId } = useParams();

  if (!mapId) return null;

  return <Navigate to={Paths.mapPagePath({ id: mapId })} />
}

function Router() {
  const [isFirstRender, setIsFirstRender] = React.useState(true)

  React.useEffect(() => {
    setIsFirstRender(false)
  }, [])

  return (
    <BrowserRouter>
      <ReactRouterRoutes>
        <Route
          path="/admin/*"
          element={<Suspense fallback={<div>loading</div>}><Admin /></Suspense>}
        />
        <Route path={Paths.contactUsPagePath()} element={<ContactUsPage />} />
        <Route
          path="/creator-onboard/:token"
          element={<CreatorOnboardRedirect />}
        />

        <Route
          element={
            <Layout backgroundColor='light' padding='none'>
              <SuspenseRouteElement />
            </Layout>
          }
        >
          <Route path={Paths.checkoutPagePath()} element={<CheckoutPage />} />
          <Route
            path={Paths.creatorDashboardPath() + '/*'}
            element={<CreatorDashboard />}
          />
          <Route path={Paths.emailSubscriptionsPath()} element={<EmailSubscriptionsPage />} />
          <Route path={Paths.faqPagePath()} element={<FaqPage />} />
          <Route path="/f" element={<FlashMessageRedirect />} />
          <Route path="/o/:publicId" element={<OrderPage />} />
          <Route
            path={Paths.patreonRewardsPath()}
            element={<PatreonRewardsPage />}
          />
          <Route path={Paths.privacyPolicyPath()} element={<PrivacyPolicyPage />} />
          <Route path={Paths.purchasedMapsPath()} element={<PurchasedMapsPage />} />
          <Route path={Paths.subscribeSuccessPath()} element={<SubscribeSuccessPage />} />
          <Route path={Paths.subscriptionPath()} element={<SubscriptionPage />} />
          <Route path={Paths.termsOfServicePath()} element={<TermsOfServicePage />} />
        </Route>

        <Route
          element={
            <Layout backgroundColor='dark' padding='none'>
              <SuspenseRouteElement />
            </Layout>
          }
        >
          <Route path={Paths.bookmarksPagePath()} element={<BookmarksPage />} />
          <Route
            path={Paths.creatorPagePath({ slug: ':creatorId' })}
            element={<CreatorPage />}
          />
        </Route>

        <Route element={<SuspenseRouteElement />}>
          <Route
            path={Paths.subscriptionLandingPagePath()}
            element={<SubscriptionLandingPage />}
          />
        </Route>

        <Route element={<SuspenseRouteMapPageElement />}>
          <Route path={Paths.mapPagePath({ id: ':mapId' })} element={<MapPage />} />
        </Route>

        <Route element={<SuspenseRouteSearchElement />}>
          <Route path="/search" element={<SearchPage />} />
        </Route>

        <Route path={Paths.afterSignInRedirectPath()} element={<AfterSignInRedirect />} />
        <Route path={Paths.discordBotPagePath()} element={<DiscordBotPage />} />
        <Route
          path={Paths.discordBotInstallPagePath()}
          element={<DiscordBotInstallRedirect />}
        />
        <Route path={Paths.followingPagePath()} element={<FollowingPage />} />
        <Route path={Paths.foundryModulePagePath()} element={<FoundryModulePage />} />
        {isFirstRender &&
          <Route
            path="z/:mapId"
            element={
              <MapRouteRedirect />
            }
          />
        }
        <Route path="/email-guest-confirmed" element={<EmailGuestConfirmedRedirect />} />
        <Route path={Paths.emailSubscriptionUnsubscribePath(':subscriptionId')} element={<NewsletterUnsubscriber />} />

        <Route path="/order-thanks" element={<OrderThanksRedirect />} />
        <Route path={Paths.owlbearRodeoDevelopmentPath()} element={<OwlbearRodeoDevelopmentRedirect />} />

        <Route
          path={Paths.patreonRewardPath({ id: ':battleMapId' })}
          element={<PatreonRewardPage />}
        />
        <Route
          path={Paths.patreonRewardsCreatorSpecificOnboardingPath(':creatorId')}
          element={<PatreonRewardsCreatorSpecificOnboarding />}
        />
        <Route path="/thanks-for-subscribing/r" element={<SubscribeSuccessRedirect/>} />
        <Route path={Paths.resubscribePath()} element={<ResubscribePage />} />
        <Route path={Paths.subscribeFromDiscordPath()} element={<SubscribeFromDiscordRedirect />} />
        <Route
          path={Paths.subscriptionMapPath({ id: ':battleMapId' })}
          element={<SubscriptionMapPage />}
        />
        <Route
          path="/unsubscribe/:subscriptionId"
          element={
            <Redirect
              to={({ subscriptionId }) => Paths.emailSubscriptionUnsubscribePath(subscriptionId)}
            />
          }
        />
        <Route path="*" element={<HomePage />} />
      </ReactRouterRoutes>
      <PageGlobals />
    </BrowserRouter>
  )
}

export default Router;

