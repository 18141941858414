import React, { forwardRef } from 'react'
import { IconButton, InputAdornment, TextField as MuiTextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from 'theme'
import { Search, Close } from '@mui/icons-material'
import { SearchTextFieldProps } from './props'
import { useNavigate } from 'react-router-dom'
import { Routes } from 'routing/deprecated'

const searchColor = colors.lightGrey

const TextField = styled(MuiTextField)({
  '& .MuiFormLabel-root': {
    color: searchColor,
  },
  '& .MuiFormLabel-root.Mui-focused': {
    display: 'none',
  },

  '& .MuiInputBase-root': {
    color: searchColor,
    marginBottom: '9px',

    '&:hover': {
      border: '1px solid rgb(63, 81, 181)',
      borderRadius: '20px',
    }
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(63, 81, 181, 0.5)',
    borderRadius: '20px',
  },
  // Hack to override Chrome's wonky autofill styling
  // https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied
  '& .MuiInputBase-input:autofill': {
    transition: 'background-color 0s 600000s, color 0s 600000s !important',
  }
})

const SearchTextField = forwardRef<HTMLDivElement, SearchTextFieldProps>((props, ref) => {
  const navigate = useNavigate()

  const nonSearchPageProps = {
    onClick: () => navigate(Routes.searchPath()),
  }

  const {
    onChange, onClick, handleClear, value
  } = { ...nonSearchPageProps, ...props }

  return (
    <TextField
      variant="outlined"
      fullWidth
      ref={ref}
      size="small"
      onChange={onChange}
      onClick={onClick}
      value={value}
      InputProps={{
        placeholder: 'Search',
        startAdornment: (
          <InputAdornment position="start" sx={{ color: searchColor }}>
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {value && (
              <IconButton sx={{ color: searchColor }} onClick={handleClear}>
                <Close />
              </IconButton>
            )}
          </InputAdornment>
        )
      }}
    />
  )
})

SearchTextField.displayName = 'SearchTextField'

export default SearchTextField
