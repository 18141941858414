import React, { useContext } from 'react';
import { OpenInNew } from '@mui/icons-material'
import { TrackerContext } from 'Tracker'
import { BattleMap } from 'models'
import { IconButton } from 'components/MapCardBase'
import { ExternalMapsContext } from 'providers/ExternalMapsProvider'

type Props = {
  battleMap: BattleMap,
}

const OpenExternalMapPageButton = ({ battleMap }:Props) => {
  const { openExternalBattleMap } = useContext(ExternalMapsContext)
  const { mixpanelProperties } = useContext(TrackerContext)

  const onClick = () => {
    openExternalBattleMap(battleMap, mixpanelProperties)
  }

  return (
    <IconButton onClick={onClick} tooltipTitle="Open Page (external)">
      <OpenInNew data-testid="open-in-new" fontSize="small" />
    </IconButton>
  )
}

export default OpenExternalMapPageButton
