import Paths from './Paths'

export const Routes = {
    bookmarksPagePath: Paths.bookmarksPagePath,
    checkoutPagePath: Paths.checkoutPagePath,
    contactUsPagePath: Paths.contactUsPagePath,
    discordBotPagePath: Paths.discordBotPagePath,
    emailSubscriptionsPath: Paths.emailSubscriptionsPath,
    faqPagePath: Paths.faqPagePath,
    followingPagePath: Paths.followingPagePath,
    foundryModulePagePath: Paths.foundryModulePagePath,
    mapPagePath: Paths.mapPagePath,
    orderPage: Paths.orderPage,
    patreonRewardPath: Paths.patreonRewardPath,
    patreonRewardsPath: Paths.patreonRewardsPath,
    purchasedMapsPath: Paths.purchasedMapsPath,
    resubscribePath: Paths.resubscribePath,
    searchPath: Paths.searchPath,
    subscribeSuccessPath: Paths.subscribeSuccessPath,
    subscriptionPath: Paths.subscriptionPath,
    subscriptionMapPath: Paths.subscriptionMapPath,
    subscriptionLandingPagePath: Paths.subscriptionLandingPagePath,
    zoomedInMapPath: Paths.zoomedInMapPath
}
