import React from 'react';
import MultiVariationPage from './MultiVariationPage'
import { Product } from 'models'
import { Routes } from 'routing/deprecated';
import { ReverseNavigationPrompt } from 'components/navigation'

type Props = {
  product: Product
}

const Page = ({ product }: Props) => (
  <>
    <ReverseNavigationPrompt linkTo={Routes.patreonRewardsPath()}>
      Patreon rewards
    </ReverseNavigationPrompt>
    <MultiVariationPage product={product} />
  </>
)

export default Page
