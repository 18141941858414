import React from 'react'
import { tooltipClasses } from '@mui/material/Tooltip';
import Tooltip from 'components/Tooltip'
import { Help } from '@mui/icons-material';
import { SxProps, Theme } from '@mui/material/styles';

type Props = {
  title: string,
  iconSx?: SxProps<Theme>
}

const HelpTooltip = ({ title, iconSx = {} }: Props) => (
  <Tooltip
    title={title}
    arrow
    sx={{
      [`& .${tooltipClasses.tooltip}`]: {
        textTransform: 'none'
      },
    }}
  >
    <Help fontSize="small" sx={{ marginLeft: '5px', ...iconSx }} />
  </Tooltip>
)

export default HelpTooltip
