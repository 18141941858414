import React from 'react';
import { BrowseableBattleMap } from 'models'
import Image from './Image'

const RotatingVariationImage = ({ battleMap }:{ battleMap: BrowseableBattleMap }) => {
  const [imageIdx, setImageIdx] = React.useState(0)

  const variationsForImageRotation = battleMap.premium ? battleMap.variations : [battleMap.variations[0]]

  React.useEffect(() => {
    if (variationsForImageRotation.length === 1) return

    const timeout = setTimeout(() => {
      let newIdx = imageIdx + 1;
      if (newIdx > variationsForImageRotation.length - 1) {
        newIdx = 0
      }
      setImageIdx(newIdx)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [imageIdx, battleMap])

  return (
    <Image
      battleMap={battleMap}
      variation={variationsForImageRotation[imageIdx]}
    />
  )
}

export default RotatingVariationImage
