import { BrowseableBattleMap } from '../BrowseableBattleMap'
import { SrcsetEntry } from '../SrcsetEntry'
import { MapVariation } from '../MapVariation'
import { narrowestCloudFrontPreviewImageUrl, srcsetEntriesFromCloudFrontPreviews } from '../MapVariationCloudFrontPreview'
import * as Sentry from "@sentry/react"

export type NarrowestPreviewImageUrlFn = (bm: BrowseableBattleMap, mv: MapVariation) => string | null
export type SrcsetEntriesFn = (bm: BrowseableBattleMap, mv: MapVariation, resizeTargetWidths: number[]) => SrcsetEntry[]

type PreviewsType = {
  narrowestPreviewImageUrl: NarrowestPreviewImageUrlFn
  srcsetEntries: SrcsetEntriesFn
}

export const usePreviews = (): PreviewsType => {
  const narrowestPreviewImageUrl = (bm: BrowseableBattleMap, mv: MapVariation): string | null => {
    if (mv.cfPreviews && mv.cfPreviews.length > 0) {
      return narrowestCloudFrontPreviewImageUrl(mv.cfPreviews);
    } else {
      Sentry.captureMessage(`narrowestCloudFrontPreviewImageUrl missing previews for ${bm.id}`)
      return null
    }
  }

  const srcsetEntries = (bm: BrowseableBattleMap, mv: MapVariation, resizeTargetWidths: number[]): SrcsetEntry[] => {
    if (mv.cfPreviews && mv.cfPreviews.length > 0) {
      return srcsetEntriesFromCloudFrontPreviews(mv.cfPreviews, resizeTargetWidths)
    } else {
      Sentry.captureMessage(`srcsetEntries missing previews for ${bm.id}`)
      return []
    }
  }

  return { narrowestPreviewImageUrl, srcsetEntries }
}

