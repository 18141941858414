import React from 'react';
import {
  MenuControl, MenuControlAnchor, Menu, MenuItemLink
} from 'layouts/TopAppBarBase'
import { MoreVert } from '@mui/icons-material'
import { colors } from 'theme'
import { useMiscMenuItems } from 'layouts/miscMenu'

const MiscMenu = () => {
  const miscMenuItems = useMiscMenuItems()

  return (
    <MenuControl>
      <MenuControlAnchor>
        <MoreVert
          sx={{ color: colors.lightGrey, width: '30px', height: '30px' }}
        />
      </MenuControlAnchor>
      <Menu originHorizontal="right">
        {miscMenuItems.map(miscMenuItem => (
          <MenuItemLink
            href={miscMenuItem.href}
            key={miscMenuItem.name}
            icon={miscMenuItem.icon}
          >
            {miscMenuItem.name}
          </MenuItemLink>
        ))}
      </Menu>
    </MenuControl>
  )
}

export default MiscMenu
