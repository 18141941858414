import * as Sentry from "@sentry/react";
import { Event, ErrorEvent } from '@sentry/types'

type BeforeSendResult = null | Event | PromiseLike<null | Event>

export const beforeSend = (evt: ErrorEvent): BeforeSendResult => {
  const filenamesToSkip = [
    'chrome-extension://bkkbcggnhapdmkeljlodobbkopceiche/injectScript.js'
  ]
  if (evt.exception?.values) {
    const skippable = evt.exception.values.some(exceptionValue => {
      if (exceptionValue.stacktrace?.frames) {
        return exceptionValue.stacktrace.frames.some(frame => (
          filenamesToSkip.some(filenameToSkip => frame.filename === filenameToSkip)
        ))
      }
    })
    if (skippable) return null
  }

  return evt
}

export const initializeSentry = (dsn: string) => {
  Sentry.init({
    dsn,
    integrations: [new Sentry.BrowserTracing()],
    sampleRate: 1,
    tracesSampleRate: 0.05,
    ignoreErrors: [
      'Non-Error promise rejection captured with value: undefined',
      'Non-Error promise rejection captured with value: Not implemented on this platform',
      'Non-Error promise rejection captured with value: 404',
      'onError ApolloError: Unexpected token < in JSON at position 0'
    ],
    beforeSend
  });
}
