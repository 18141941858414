import React from 'react';
import * as Sentry from "@sentry/react";
import { Button } from '@mui/material'
import { GetApp } from '@mui/icons-material'
import { useNavigate } from "react-router-dom";
import ApiClient from 'ApiClient';
import { BattleMap } from 'models';

type Props = {
  battleMap: BattleMap,
}

const NavigateToOrderPageToDownloadButton = ({ battleMap }: Props) => {
  const navigate = useNavigate()

  const redirectToOrderPage = () => {
    ApiClient.getRaw(`/orders/public_id_from_map_id?map_id=${battleMap.id}`)
      .then(({ data: { order_id: orderId } }) => {
        if (orderId) {
          navigate(`/o/${orderId}`)
        } else {
          Sentry.captureMessage(`Could not find an associated order for map ${battleMap.id}`)
        }
      })
  }

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<GetApp />}
      sx={{ fontSize: { 'xs': '0.7rem', 'sm': '0.9375rem' }, width: '100%' }}
      onClick={redirectToOrderPage}
      size="large"
    >
      Download
    </Button>
  )
}

export default NavigateToOrderPageToDownloadButton
