import React, { useContext, forwardRef, LegacyRef } from 'react';
import MobileAppBar from './mobile'
import DesktopAppBar from './desktop'
import { Props } from './types'
import AppContext from 'AppContext'

type ComponentProps = Props & {
  ref?: ((instance: HTMLElement | null) => void) | LegacyRef<HTMLElement>
}

const AppBar = forwardRef<HTMLElement, Props>((props, ref) => {
  const { deviceType } = useContext(AppContext)
  const isDesktop = deviceType === 'desktop'

  let Component: React.FC<ComponentProps>
  if (isDesktop) {
    Component = DesktopAppBar
  } else {
    Component = MobileAppBar
  }
  const componentProps: ComponentProps = { ...props }
  if (ref) componentProps.ref = ref

  return <Component {...componentProps} />
})

AppBar.displayName = 'AppBar'

export default AppBar
