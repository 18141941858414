import React, { useContext } from 'react'
import ZoomOverlay, { Contents, Layout, PrevButton, NextButton } from 'components/ZoomOverlay'
import AppContext from 'AppContext'
import { Box, Button, IconButton } from '@mui/material'
import { Download, Close } from '@mui/icons-material'
import { MapVariationForProduct } from 'models'
import { MapVariationZoomImage } from 'components/mapDownloadPage'
import { styled } from '@mui/material/styles';

const ButtonWrapper = styled(Box)(({ theme }) => ({
  margin: '0 16px',
  alignItems: 'center',

  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  }
}))

type Props = {
  download: () => void
  battleMapAspectRatio: number
  mapVariations: MapVariationForProduct[]
  setZoomedInMapVariation: (mv: MapVariationForProduct | null) => void
  zoomedInMapVariation: MapVariationForProduct
  zoomOut: () => void
}

const MapVariationZoom = (props: Props) => {
  const {
    download,
    battleMapAspectRatio,
    mapVariations,
    setZoomedInMapVariation,
    zoomedInMapVariation,
    zoomOut
  } = props
  const { deviceType } = useContext(AppContext)
  const isDesktop = deviceType === 'desktop'

  const mapVariationsIndexOf = (mapVariation: MapVariationForProduct) => {
    for (let i = 0; i < mapVariations.length; i++) {
      if (mapVariations[i].id === mapVariation.id) {
        return i
      }
    }
    return -1;
  }

  const mapVariationsIdx = mapVariationsIndexOf(zoomedInMapVariation)

  const prevMapVariation = (mapVariationsIdx > 0) ? mapVariations[mapVariationsIdx - 1] : null
  const nextMapVariation = (mapVariationsIdx < mapVariations.length - 1) ? mapVariations[mapVariationsIdx + 1] : null

  const onKeyUp = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Escape' && zoomOut) {
      zoomOut();
    } else if (evt.key === 'ArrowLeft' && prevMapVariation) {
      setZoomedInMapVariation(prevMapVariation)
    } else if (evt.key === 'ArrowRight' && nextMapVariation) {
      setZoomedInMapVariation(nextMapVariation)
    }
  }

  return (
    <ZoomOverlay zoomOut={zoomOut} onKeyUp={onKeyUp} testId="zoomed-in-variation">
      <Layout>
        <ButtonWrapper>
          <PrevButton
            disabled={!prevMapVariation}
            onClick={() => setZoomedInMapVariation(prevMapVariation)}
            size={isDesktop ? 'medium' : 'small'}
          />
        </ButtonWrapper>
        <Contents>
          <Box sx={{ padding: { xs: 0, sm: '0' }, width: '100%', height: '100%' }}>
            <IconButton
              sx={{ top: '15px', right: '14px', position: 'absolute' }}
              onClick={() => setZoomedInMapVariation(null)}
            >
              <Close />
            </IconButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box
                component="h2"
                sx={{ fontSize: '2em', padding: { xs: '0 10px', sm: '0 60px 0 25px', } }}
              >
                {zoomedInMapVariation.privateAsset.filename}
              </Box>
              <MapVariationZoomImage
                battleMapAspectRatio={battleMapAspectRatio}
                zoomedInMapVariation={zoomedInMapVariation}
              />
              <Box sx={{ textAlign: 'center', padding: '25px 0' }}>
                <Button
                  variant="contained"
                  startIcon={<Download />}
                  onClick={download}
                >
                  Download
                </Button>
              </Box>
            </Box>
          </Box>
        </Contents>
        <ButtonWrapper>
          <NextButton
            disabled={!nextMapVariation}
            onClick={() => setZoomedInMapVariation(nextMapVariation)}
            size={isDesktop ? 'medium' : 'small'}
          />
        </ButtonWrapper>
      </Layout>
    </ZoomOverlay>
  )
}

export default MapVariationZoom
