import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Dialog } from '@mui/material';
import { Event } from 'Tracker';

const DialogContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: "20px"
})

const DialogHeader = styled('div')({
  margin: '10px'
})

const DialogBody = styled('div')({
  display: 'flex',
  justifyContent: 'right',
  margin: '10px 0px'
})

const DiagloButton = styled(Button)({
  margin: '0 5px'
})

interface Props {
  closeDialog: () => void,
  creatorName: string,
  handleUnfollow: () => void,
  open: boolean
}

const UnfollowModal = ({ closeDialog, creatorName, handleUnfollow, open }: Props) => {
  const trackUnfollowEvent = () => window.tracker.track(Event.UnfollowCreator)

  const handleUnfollowClick = () => {
    trackUnfollowEvent()
    closeDialog()
    handleUnfollow()
  }

  return (
    <Dialog onClose={closeDialog} open={open}>
      <DialogContainer>
        <DialogHeader>
          Are you sure you want to unfollow {creatorName}?
        </DialogHeader>
        <DialogBody>
          <DiagloButton
            onClick={closeDialog}
            variant="outlined"
            color="primary"
            data-testid="unfollow-cancel-button-modal"
          >
            Cancel
          </DiagloButton>
          <DiagloButton
            variant="contained"
            color="primary"
            onClick={handleUnfollowClick}
            data-testid="unfollow-confirm-button-modal"
          >
            Unfollow
          </DiagloButton>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  )
}

export default UnfollowModal;
