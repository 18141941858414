import React from 'react'
import {
  DialogTitle, DialogContent, DialogActions, Button, Dialog
} from '@mui/material'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ApiClient from 'ApiClient'
import snakecaseKeys from 'snakecase-keys'
import Form from './Form'
import { BattleMap } from 'models'

type OnCloseFn = () => void

type PostSubmitThanksProps = {
  onClose: OnCloseFn
}

const PostSubmitThanks = ({ onClose }: PostSubmitThanksProps) => (
  <>
    <DialogTitle>Report a problem</DialogTitle>
    <DialogContent>
      Thanks for your report! We&apos;ll take a look at your issue shortly.
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </>
)

type Props = {
  open: boolean
  onClose: OnCloseFn
  battleMap: BattleMap
}

const ReportProblemDialog = ({ open, onClose, battleMap }: Props) => {
  const [reportSubmitted, setReportSubmitted] = React.useState<boolean>(false)

  const initialValues = {
    battleMapMapId: battleMap.id,
    problem: '',
  }

  const onSubmit = (values) => {
    ApiClient
      .postCamelcased('/battle_map_problem_reports', snakecaseKeys(values))
      .then(() => setReportSubmitted(true))
  }

  const validationSchema = Yup.object({
    battleMapMapId: Yup.string().required('Required'),
    problem: Yup.string().required('Please describe the problem.'),
  })

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      {!reportSubmitted && <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {formikProps => <Form formikProps={formikProps} onClose={onClose} />}
      </Formik>}
      {reportSubmitted && <PostSubmitThanks onClose={onClose} />}
    </Dialog>
  )
}

export default ReportProblemDialog
