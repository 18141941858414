import React from 'react'
import { premiumWatermarkedPreviewUrl } from 'models/MapVariation'
import { Box, SxProps } from '@mui/material'
import { MapVariation } from 'models'
import { useElementClientDimensions } from 'hooks'

type Props = {
  battleMapAspectRatio: number
  zoomedInMapVariation: MapVariation
}

const MapVariationZoomImage = (props: Props) => {
  const { battleMapAspectRatio, zoomedInMapVariation } = props
  const [containerRef, containerDimensions] = useElementClientDimensions<HTMLDivElement>()
  const imgSrc = premiumWatermarkedPreviewUrl(zoomedInMapVariation)

  const containerSx: SxProps = {
    textAlign: 'center',
    flexBasis: '100%'
  }
  const imgSx: SxProps = {
    margin: '0 auto',
    display: 'block',
    maxHeight: 'calc(95vh - 200px)'
  }
  if (containerDimensions) {
    const containerAspectRatio = containerDimensions.width / containerDimensions.height
    if (containerAspectRatio > battleMapAspectRatio) {
      imgSx.height = '100%'
    } else {
      imgSx.maxWidth = '100%'
      containerSx.display = 'flex'
      containerSx.flexDirection = 'column'
      containerSx.justifyContent = 'center'
    }
  }

  return (
    <Box ref={containerRef} sx={containerSx}>
      <Box
        component="img"
        sx={imgSx}
        src={imgSrc}
      />
    </Box>
  )
}

export default MapVariationZoomImage
