import React from 'react';
import { Box, SxProps } from '@mui/material'
import { applicationBackedImageUrl } from 'images'
import { Link } from 'react-router-dom'

export const LogoImg = () => (
  <Box
    component="img"
    alt="Lost Atlas"
    src={applicationBackedImageUrl('logo.png')}
    sx={{ height: '25px', width: '190px' }}
  />
)

type Props = {
  sx?: SxProps
}

const Logo = ({ sx = {} }: Props) => (
  <Box sx={sx}><Link to="/"><LogoImg /></Link></Box>
)

export default Logo;
