import React from 'react';
import { Box } from '@mui/material'
import { Product, MapVariationForProduct } from 'models'
import { aspectRatio } from 'models/BattleMap'
import Header from './Header'
import MapVariationCard from './MapVariationCard'
import MapVariationZoom from './MapVariationZoom'

type Props = {
  product: Product
}

const MultiVariationPage = ({ product }: Props) => {
  const [zoomedInMapVariation, setZoomedInMapVariation] = React.useState<MapVariationForProduct | null>(null)

  const battleMap = product.battleMap
  const battleMapAspectRatio = aspectRatio(battleMap)

  return (
    <>
      {zoomedInMapVariation && (
          <MapVariationZoom
            battleMapAspectRatio={battleMapAspectRatio}
            mapVariations={product.mapVariations}
            setZoomedInMapVariation={setZoomedInMapVariation}
            zoomedInMapVariation={zoomedInMapVariation}
            zoomOut={() => setZoomedInMapVariation(null)}
          />
      )}
      <Header battleMap={battleMap} />
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {product.mapVariations.map(mapVariation => (
          <MapVariationCard
            mapVariation={mapVariation}
            key={mapVariation.id}
            zoomIn={() => setZoomedInMapVariation(mapVariation)}
          />
        ))}
      </Box>
    </>
  )
}

export default MultiVariationPage
