import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShoppingCartOutlined } from '@mui/icons-material'
import { TrackerContext } from 'Tracker'
import { addToCart } from 'actions'
import { AppLoaderContext } from 'AppLoader';
import { Product } from 'models'
import { IconButton } from 'components/MapCardBase'
import { SxProps } from '@mui/material'

type Props = {
  product: Product,
  sx?: SxProps
}

const AddToCartButton = ({ product, sx = {} }: Props) => {
  const { shoppingCartDispatch } = useContext(AppLoaderContext)
  const { mixpanelProperties } = useContext(TrackerContext)
  const navigate = useNavigate();

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    addToCart({ product, navigate, shoppingCartDispatch, mixpanelProperties })
  }

  return (
    <IconButton sx={sx} onClick={onClick} tooltipTitle="Buy Now">
      <ShoppingCartOutlined data-testid="add-to-cart" fontSize="small" />
    </IconButton>
  )
}

export default AddToCartButton
