import { s3BackedImageUrl } from 'images'
import { SrcsetEntry } from './SrcsetEntry'

export type MapVariationCloudFrontPreview = {
  width: number
  subPath: string
}

const imageUrl = (preview: MapVariationCloudFrontPreview): string => (
  s3BackedImageUrl('map_variations/previews', preview.subPath)
)

export const narrowestCloudFrontPreviewImageUrl = (previews: MapVariationCloudFrontPreview[]): string => {
  const previewsByWidth = [...previews].sort((p1, p2) => (p1.width - p2.width))
  const narrowestPreview = previewsByWidth[0]
  return imageUrl(narrowestPreview)
}

export const srcsetEntriesFromCloudFrontPreviews = (previews: MapVariationCloudFrontPreview[], resizeTargetWidth: number[]): SrcsetEntry[] => (
  previews.map(preview => (
    { url: imageUrl(preview), intrinsicWidth: preview.width }
  ))
)
