import React from 'react';

import useFlashMessageReducer, { FlashMessageAction, FlashMessageState } from 'reducers/useFlashMessageReducer'

export type FlashMessageDispatch = React.Dispatch<FlashMessageAction>

interface FlashMessageContextType {
  flashMessage: FlashMessageState,
  flashMessageDispatch: FlashMessageDispatch
}

export const FlashMessageContext = React.createContext<FlashMessageContextType>({
  flashMessage: {
    open: false,
    message: "",
    status: undefined,
    autoHideDuration: undefined
  },
  flashMessageDispatch: () => {}
})

type Props = {
  children: React.ReactNode
}

function FlashMessageProvider({ children }: Props) {
  const [flashMessage, flashMessageDispatch] = useFlashMessageReducer();

  return (
    <FlashMessageContext.Provider value={{ flashMessage, flashMessageDispatch }}>
      {children}
    </FlashMessageContext.Provider>
  )
}

export default FlashMessageProvider
