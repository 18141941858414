import React from 'react';
import { Bookmark, BookmarkBorder } from '@mui/icons-material'

import BookmarkToggle, { BookmarkedProps, NotBookmarkedProps } from 'components/BookmarkToggle'
import { BattleMap } from 'models'
import { IconButton, containIconClick } from 'components/MapCardBase'

type Props = {
  battleMap: BattleMap,
}

const BookmarkButton = ({ battleMap }: Props) => {
  const Presentation = {
    LoadPending: () => (
      <IconButton tooltipTitle="Bookmark">
        <BookmarkBorder data-testid="bookmark" fontSize="small" />
      </IconButton>
    ),
    Bookmarked: ({ onClick }: BookmarkedProps) => (
      <IconButton tooltipTitle="Bookmark" onClick={onClick}>
        <Bookmark data-testid="bookmark" fontSize="small" data-status="bookmarked" />
      </IconButton>
    ),
    NotBookmarked: ({ onClick }: NotBookmarkedProps) => (
      <IconButton tooltipTitle="Bookmark" onClick={onClick}>
        <BookmarkBorder data-testid="bookmark" fontSize="small" data-status="not-bookmarked" />
      </IconButton>
    ),
    LoginNeeded: () => (
      <IconButton tooltipTitle="Bookmark" containIconClick={false}>
        <BookmarkBorder data-testid="bookmark" fontSize="small" />
      </IconButton>
    )
  }

  return (
    <span onClick={containIconClick}>
      <BookmarkToggle
        mapId={battleMap.id}
        presentation={Presentation}
        showDialogFirst={true}
      />
    </span>
  )
}

export default BookmarkButton
