import React from 'react';
import { Link as MuiLink } from 'react-router-dom';
import { styled } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { colors } from 'theme';

const Link = styled(MuiLink)({
  display: 'flex',
  alignItems: 'center',
  color: colors.darkGrey,
  fontWeight: 'bold',
  cursor: 'pointer',
  marginTop: '.75vh',
  textDecoration: 'none',
})

type Props = {
  linkTo: string
  children: React.ReactNode
}

const ReverseNavigationPrompt = (props: Props) => {
  const { linkTo, children } = props

  return (
    <Link to={linkTo}>
      <ArrowBack sx={{ marginRight: '8px' }} />
      <span>
        {children}
      </span>
    </Link>
  )
}

export default ReverseNavigationPrompt
