import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import { createThemeV5 } from './theme5';

const themeV5 = createThemeV5();

type Props = {
  children: JSX.Element
}

// Catch-all provider of mixed styling functionality for the whole app
export const StylingProvider = ({ children }: Props) => (
  <ThemeProviderV5 theme={themeV5}>
    {children}
  </ThemeProviderV5>
)
