import React, { forwardRef } from 'react';
import { SingleRowBar, Container, DesktopTopRow } from 'layouts/TopAppBarBase'
import { Props } from './types'

const DesktopAppBar = forwardRef<HTMLElement, Props>(({ children }, ref) => (
  <Container>
    <SingleRowBar ref={ref}>
      <DesktopTopRow />
    </SingleRowBar>
    {children}
  </Container>
))

DesktopAppBar.displayName = 'DesktopAppBar'

export default DesktopAppBar
