import React from 'react';

import { Session } from 'models'
import NotSignedIn from './NotSignedIn'
import SignedIn from './SignedIn'

const AccountMenu = ({ session }:{ session:Session }) => {
  if (session.user) return <SignedIn user={session.user} />

  return <NotSignedIn />
}

export default AccountMenu;
