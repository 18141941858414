import React, { Dispatch } from 'react'

import ApiClient from 'ApiClient';
import useFollowshipsReducer, { FollowshipActionType, FollowshipDispatchAction } from 'reducers/useFollowshipsReducer';
import { AppLoaderContext } from 'AppLoader';
import { Followship } from 'models';

interface FollowshipLoaderInterface {
  followships: Followship[] | undefined
  followshipsDispatch: Dispatch<FollowshipDispatchAction>
}

export const FollowshipLoaderContext = React.createContext<FollowshipLoaderInterface | null>(null)

type Props = {
  children: JSX.Element
}

export const FollowshipLoader = ({ children }: Props) => {
  const { session } = React.useContext(AppLoaderContext)
  const [followshipsState, followshipsDispatch] = useFollowshipsReducer();

  React.useEffect(() => {
    if (session?.user) {
      ApiClient.getCamelcased("/users/followships").then((resp) => {
        followshipsDispatch({ type: FollowshipActionType.LOAD, followships: resp.data })
      })
    }
  }, [session])

  return (
    <FollowshipLoaderContext.Provider value={{ followships: followshipsState.followships, followshipsDispatch }}>
      {children}
    </FollowshipLoaderContext.Provider>
  )
}

export const useFollowships = () => {
  const followshipsContext = React.useContext(FollowshipLoaderContext)

  if (!followshipsContext) {
    throw new Error("useFollowships must be used within a FollowshipLoad")
  }

  return followshipsContext
}
