import { MixpanelProperties } from 'Tracker'
import { MapsResultSetMap } from 'search'

export const variablesToMixpanelProperties = (maps: MapsResultSetMap[], variables): MixpanelProperties => {
  const mapsCount = maps.length
  const productsCount = maps.filter(m => m.product).length
  const mixpanelProperties: MixpanelProperties = {
    maps_count: mapsCount,
    refined: false,
    sort_by: variables.sortBy,
  }
  if (productsCount > 0) {
    mixpanelProperties.products_percent =
      Math.round(productsCount * 100 / mapsCount)
  }
  variables.filters.forEach(filter => {
    const valuesKey = `${filter.identifier}_filter`
    const valuesAry = mixpanelProperties[valuesKey] || []
    valuesAry.push(filter.value)
    mixpanelProperties[valuesKey] = valuesAry
    mixpanelProperties.refined = true
  })
  if (variables.query) {
    mixpanelProperties.search_query = variables.query
    mixpanelProperties.refined = true
  }
  return mixpanelProperties;
}
