import React from 'react';
import { Icon } from '@mui/material';
import { ExternalLink } from 'components/navigation'
import { Layout } from 'layouts'

function ContactUsPage() {
  const iconSx = {
    paddingTop: '2px',
    marginRight: '5px',
  }

  return (
    <Layout padding='medium'>
      <h1>Contact us</h1>
      <p>Questions? Suggestions? We&apos;d love to hear your thoughts.</p>
      <h2>
        <Icon sx={iconSx} aria-label="Email">email</Icon>
        Email
      </h2>
      <p>
        <ExternalLink href="mailto:support@lostatlas.co">Email us</ExternalLink> at support@lostatlas.co.
      </p>
      <h2>
        <Icon sx={iconSx} aria-label="Reddit">reddit</Icon>
        Reddit
      </h2>
      <p>
        <ExternalLink
          href="https://www.reddit.com/user/LostAtlasBattleMaps">Chat with us</ExternalLink> on Reddit at u/LostAtlasBattleMaps.
      </p>
    </Layout>
  )
}

export default ContactUsPage;
