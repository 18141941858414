import axios, { AxiosResponse, AxiosPromise } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import Rails from './Rails';
import { CookieName } from 'storage';

export type { AxiosError } from 'axios'

axios.defaults.xsrfCookieName = CookieName.CsrfToken
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
axios.defaults.withCredentials = true;

const camelcaseResponseData = (resp: AxiosResponse) => {
  resp.data = camelcaseKeys(resp.data, { deep: true })
  return resp
}

interface ApiClientObject {
  deleteRaw: (path: string, config?: any) => AxiosPromise
  getRaw: (path: string, config?: any) => AxiosPromise
  postRaw: (path: string, data?: any) => AxiosPromise
  putRaw: (path: string, data?: any) => AxiosPromise
  deleteCamelcased: (path: string, config?: any) => AxiosPromise
  getCamelcased: (path: string, config?: any) => AxiosPromise
  postCamelcased: (path: string, data?: any) => AxiosPromise
  putCamelcased: (path: string, data?: any) => AxiosPromise
  request: (method: string, path: string, data?: any, config?: any) => AxiosPromise
}

const ApiClient: ApiClientObject = {
  deleteRaw: (path, config = {}) => {
    return axios.delete(Rails.apiUrl(path).toString(), config);
  },

  getRaw: (path, config = {}) => {
    return axios.get(Rails.apiUrl(path).toString(), config);
  },

  postRaw: (path, data = {}) => {
    return axios.post(Rails.apiUrl(path).toString(), data);
  },

  putRaw: (path, data = {}) => {
    return axios.put(Rails.apiUrl(path).toString(), data);
  },

  deleteCamelcased: (path, config = {}) => ApiClient.request('delete', path, {}, config),

  getCamelcased: (path, config = {}) => ApiClient.request('get', path, {}, config),

  postCamelcased: (path, data = {}) => ApiClient.request('post', path, data),

  putCamelcased: (path, data = {}) => ApiClient.request('put', path, data),

  request: (method: string, path: string, data = {}, config = {}) => {
    return axios
      .request({
        ...config,
        data,
        method,
        url: Rails.apiUrl(path).toString(),
      })
      .then(camelcaseResponseData)
  },
}

export default ApiClient;
