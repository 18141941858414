import { ALaCarteGuest } from './ALaCarteGuest'
import { MapVariationForProduct } from './MapVariationForProduct'
import { OrderLineItem } from './OrderLineItem'
import { User } from './User'
import ApiClient from 'ApiClient'
import { CreateDownloadUrlResponse } from 'providers/PrivateAssetDownloadProvider'

export interface Order {
  publicId: string
  createdAt: Date
  disabled: boolean
  guestEmail?: string
  lineItems: OrderLineItem[]
  paymentMethodLast4: string
  total: number
  user?: User
  visitor: User | ALaCarteGuest
}

export const convertApiRecord = (attrs): Pick<Order, 'createdAt' | 'publicId'> => ({
  createdAt: new Date(attrs.createdAt),
  publicId: attrs.publicId,
})

export const createDownloadUrl = (order: Order, mapVariation: Pick<MapVariationForProduct, 'id'>): Promise<CreateDownloadUrlResponse> => {
  return new Promise((resolve, reject) => {
    ApiClient
      .postCamelcased(
        "/d", { map_variation_id: mapVariation.id, order_id: order.publicId }
      )
      .then(resp => resolve(resp.data))
      .catch(reject)
  })
}
