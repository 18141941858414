import { useReducer } from 'react';
import { ShoppingCart } from 'models'

enum ActionType {
  SET_ACTION = "SET"
}

export type ShoppingCartAction = {
  type: ActionType.SET_ACTION,
  shoppingCart: ShoppingCart
}

export type ShoppingCartState = ShoppingCart | null

export const setShoppingCartAction = (shoppingCart: ShoppingCart): ShoppingCartAction => ({
  type: ActionType.SET_ACTION,
  shoppingCart
})

const useShoppingCartReducer = () => {
  const reducer = (_state: ShoppingCartState, action: ShoppingCartAction): ShoppingCartState => {
    switch (action.type) {
      case ActionType.SET_ACTION:
        return action.shoppingCart
      default:
        throw new Error()
    }
  }

  const initialState = null
  return useReducer(reducer, initialState)
}

export default useShoppingCartReducer
