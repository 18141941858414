import { useLocation } from 'react-router-dom'
import { Routes } from 'routing/deprecated'

const ROUTES_THAT_CANNOT_SHOW_EMAIL_SUBSCRIPTION_DIALOG = [
  Routes.subscriptionLandingPagePath()
]

export const useRouteCanShowEmailSubscriptionDialog = () => {
  const location = useLocation()

  return !ROUTES_THAT_CANNOT_SHOW_EMAIL_SUBSCRIPTION_DIALOG.includes(location.pathname)
}
