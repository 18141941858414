import { Followship } from 'models';
import { useReducer } from 'react';

interface FollowshipReducerState {
  followships: Followship[] | undefined
}

export enum FollowshipActionType {
  LOAD,
  UPDATE,
  DELETE,
  INSERT
}
interface FollowshipLoadAction {
  type: FollowshipActionType.LOAD,
  followships: Followship[]
}

interface FollowshipUpdateAction {
  type: FollowshipActionType.UPDATE,
  followship: Followship
}

interface FollowshipDeleteAction {
  type: FollowshipActionType.DELETE,
  id: number
}

interface FollowshipInsertAction {
  type: FollowshipActionType.INSERT,
  followship: Followship
}

export type FollowshipDispatchAction = FollowshipLoadAction | FollowshipUpdateAction | FollowshipDeleteAction | FollowshipInsertAction

const useFollowshipsReducer = () => {
  const reducer = (state: FollowshipReducerState, action: FollowshipDispatchAction): FollowshipReducerState => {
    let newFollowships: Followship[];
    let typedAction: FollowshipDispatchAction;
    switch (action.type) {
      case FollowshipActionType.LOAD:
        typedAction = action as FollowshipLoadAction
        return {
          followships: typedAction.followships.map(apiRecord => ({
            id: apiRecord.id,
            emailMe: apiRecord.emailMe,
            creatorSlug: apiRecord.creatorSlug,
            createdAt: new Date(apiRecord.createdAt)
          }))
        }
      case FollowshipActionType.UPDATE:
        newFollowships = (state.followships || []).map((followship) => {
          if (action.followship.id === followship.id) {
            return { ...followship, ...action.followship }
          } else {
            return followship
          }
        })
        return { followships: newFollowships };
      case FollowshipActionType.DELETE:
        newFollowships = (state.followships || []).filter((followship) => (
          followship.id !== action.id
        ))
        return { followships: newFollowships };
      case FollowshipActionType.INSERT:
        return { followships: [...(state.followships || []), action.followship] };
      default:
        throw new Error();
    }
  }

  const initialState = { followships: undefined } 

  return useReducer(reducer, initialState)
}

export default useFollowshipsReducer
