import { SubscriptionTier } from './SubscriptionTier'

export enum Status {
  Active = 'active',
  PastDue = 'past_due',
  Unpaid = 'unpaid',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  Trialing = 'trialing',
  Paused = 'paused',
}

export const statusesToNames = {
  [Status.Active]: 'Active',
  [Status.PastDue]: 'Past due',
  [Status.Unpaid]: 'Unpaid',
  [Status.Canceled]: 'Canceled',
  [Status.Incomplete]: 'Incomplete',
  [Status.IncompleteExpired]: 'Expired',
  [Status.Trialing]: 'Trialing',
  [Status.Paused]: 'Paused'
}

export interface Subscription {
  createdAt: Date
  currentPeriodEnd: Date
  currentPeriodStart: Date
  downloadsRemainingThisPeriod: number
  status: Status
  subscriptionTier: SubscriptionTier
}

export const subscriptionHasLapsed = (subscription: Subscription) => (
  [Status.Canceled, Status.Unpaid].includes(subscription.status)
)

interface ApiSubscription {
  createdAt: string
  currentPeriodEnd: string
  currentPeriodStart: string
  downloadsRemainingThisPeriod: number
  status: Status
  subscriptionTier: SubscriptionTier
}

export const deserializeSubscription = (apiSubscription: ApiSubscription): Subscription => ({
  ...apiSubscription,
  createdAt: new Date(apiSubscription.createdAt),
  currentPeriodStart: new Date(apiSubscription.currentPeriodStart),
  currentPeriodEnd: new Date(apiSubscription.currentPeriodEnd),
})

export const isDepleted = (subscription: Subscription): boolean => (
  subscription.downloadsRemainingThisPeriod <= 0
)
