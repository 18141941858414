import React, { Suspense } from 'react'
import { Outlet } from "react-router-dom";
import { DelayedLinearProgress } from './suspense'

type SuspensePageProps = {
  children: JSX.Element
}

const SuspensePage = ({ children }: SuspensePageProps) => {
  return (
    <Suspense fallback={<DelayedLinearProgress />}>
      {children}
    </Suspense>
  )
}

const SuspenseRouteElement = () => (
  <SuspensePage>
    <Outlet />
  </SuspensePage>
)

export default SuspenseRouteElement
