// Any image backed from a file checked into the source repository, via CloudFront
export const applicationBackedImageUrl = (path: string): string => (
  `${process.env.PUBLIC_URL}/images/${path}`
)

type S3BackedImagePrefix =
  'creators/heroes' | 'maps/thumbnails' | 'maps/watermarked_previews' |
  'map_variations/previews'

// Any image backed from a file in an S3 bucket, via CloudFront
export const s3BackedImageUrl = (prefix: S3BackedImagePrefix, basename: string): string => (
  `${process.env.REACT_APP_IMAGE_PREFIX}${prefix}/${basename}`
)

export const mapThumbnailUrl = (imageSlug: string): string => (
  s3BackedImageUrl('maps/thumbnails', imageSlug)
)
