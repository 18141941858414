import isEqual from 'lodash/isEqual'

export const MapPathRegexp = /^\/m\/(.*)/

// Return a random number from 0 to max - 1
export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
}

export const EnvironmentsWithTooFewMaps = ['docks', 'mine']

export const firstVisitExplorePageRedirectPath = (firstVisit: boolean, initialLocationHref: string): string | null => {
  let exploreEnvironment = "";
  if (firstVisit && window.location.href === initialLocationHref) {
    const url = new URL(window.location.href)
    if (isEqual(Array.from(url.searchParams.keys()), ['environments'])) {
      const selectedEnvironments = url.searchParams.get("environments") as string
      if (selectedEnvironments.indexOf(',') === -1) {
        exploreEnvironment = selectedEnvironments
      }
    }
  }
  if (exploreEnvironment && !EnvironmentsWithTooFewMaps.includes(exploreEnvironment)) {
    return `/explore/environments/${exploreEnvironment}`
  } else {
    return null
  }
}
