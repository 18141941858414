import React from 'react';
import { Collections } from '@mui/icons-material'
import { IconButton } from 'components/MapCardBase'
import { SxProps } from '@mui/material'

type Props = {
  sx?: SxProps
}

const AlreadyPurchasedButton = ({ sx }: Props) => (
  <IconButton sx={sx} containIconClick={false} tooltipTitle="Purchased">
    <Collections fontSize="small" />
  </IconButton>
)

export default AlreadyPurchasedButton
