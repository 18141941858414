import { writeTag } from './document'

export const writeMetaRobotsTag = (metaRobots: string | null): void => {
  writeTag({
    tagName: 'meta',
    fixedAttrName: 'name',
    fixedAttrValue: 'robots',
    attrName: 'content',
    attrValue: metaRobots
  })
}
