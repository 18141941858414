import React from 'react';
import ApiClient from 'ApiClient'

import { Product } from "models";

export const useMapProductLoader = (battleMapId: string | undefined) => {
  const [product, setProduct] = React.useState<Product>()

  React.useEffect(() => {
    if (!product && battleMapId) {
      ApiClient.getCamelcased(`/products/?map_id=${battleMapId}`).then(resp => {
        const rawProduct = resp.data[0]
        const product = {
          id: rawProduct.sku,
          battleMap: rawProduct.battleMap,
          mapVariations: rawProduct.mapVariations,
          name: rawProduct.name,
        }
        setProduct(product)
      })
    }
  }, [product, battleMapId])

  return product
}
