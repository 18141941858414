import React, { createContext, useState, useContext } from 'react';
import { IconButton, Menu as MuiMenu } from '@mui/material'

export { default as MenuItemLink } from './MenuItemLink'

type MenuControlContextValue = {
  anchorEl: HTMLElement | null
  anchorOnClick: (event: React.MouseEvent<HTMLElement>) => void
  handleClose: () => void
}

const MenuControlContext = createContext<MenuControlContextValue>({
  anchorEl: null,
  anchorOnClick: () => {},
  handleClose: () => {}
})

type MenuProps = {
  children: React.ReactNode
  originHorizontal: 'left' | 'right'
}

export const Menu = ({ children, originHorizontal }: MenuProps) => {
  const { anchorEl, handleClose } = useContext(MenuControlContext)
  const open = Boolean(anchorEl);

  return (
    <MuiMenu
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: originHorizontal, vertical: 'top' }}
      anchorOrigin={{ horizontal: originHorizontal, vertical: 'bottom' }}
      sx={{
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      }}
    >
      {children}
    </MuiMenu>
  )
}

type MenuControlAnchorProps = {
  children: React.ReactNode
}

export const MenuControlAnchor = ({ children }: MenuControlAnchorProps) => {
  const { anchorOnClick } = useContext(MenuControlContext)

  return (
    <IconButton
      size='large'
      onClick={anchorOnClick}
      sx={{ paddingRight: '0 !important', paddingTop: '5px', }}
    >
      {children}
    </IconButton>
  )
}

type Props = {
  children: React.ReactNode
}

const MenuControl = ({ children }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const anchorOnClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const contextValue = { anchorEl, anchorOnClick, handleClose }

  return (
    <MenuControlContext.Provider value={contextValue}>
      {children}
    </MenuControlContext.Provider>
  )
}

export default MenuControl
