import React, { useContext } from 'react';
import { Box } from '@mui/material'
import { BrowseableBattleMap, MapVariation  } from 'models'
import { aspectRatio } from 'models/BattleMap'
import { colors , zIndexes } from 'theme'
import { borderRadius } from 'components/cards'
import { buildImgProps } from './imgProps'
import { styled } from '@mui/material/styles';
import { ColumnsContext } from 'providers/ColumnsProvider'
import { usePreviews } from 'models/MapVariation/hooks'

// Simple dark gray box to take up space until the image is loaded by the
// browser
const Skeleton = styled(Box)({
  width: '100%',
  borderRadius,
  display: 'block',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: zIndexes.mapCardImage - 1,
  background: colors.darkGrey
})

const containerPaddingTop = (battleMap: BrowseableBattleMap): string => {
  const ar = aspectRatio(battleMap)
  return `${1 / ar * 100}%`
}

type Props = {
  battleMap: BrowseableBattleMap
  variation: MapVariation
}

const Image = ({ battleMap, variation }: Props) => {
  const { fullWidthColumns } = useContext(ColumnsContext)
  const { narrowestPreviewImageUrl, srcsetEntries } = usePreviews()

  if (!fullWidthColumns) return null;

  const srcFromNarrowestPreview = narrowestPreviewImageUrl(battleMap, variation)
  const resizeTargetWidths = [375, 270]
  const entries = srcsetEntries(battleMap, variation, resizeTargetWidths);
  const imgProps = buildImgProps({
    battleMap,
    fullWidthColumns,
    srcFromNarrowestPreview,
    srcsetEntries: entries
  })

  // We use <img> below, instead of <Box component="img">, because MUI's Box
  // strips out the width and height HTML attributes in `imgProps`, and it's
  // important to keep these for Cumulative Layout Shift, and thus Google SEO.
  //
  // https://github.com/mui/material-ui/issues/41586
  const imgStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    display: 'block',
    position: 'absolute' as React.CSSProperties['position'],
    objectFit: 'cover' as React.CSSProperties['objectFit'],
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: zIndexes.mapCardImage,
  }
  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
      paddingTop: containerPaddingTop(battleMap)
    }}>
      <img {...imgProps} style={imgStyle} />
      <Skeleton />
    </Box>
  )
}

export default Image
