import React from 'react';
import ErrorBoundary from "./ErrorBoundary";
import Cookies from 'js-cookie';
import { initializeSentry } from 'sentry'
import Tracker from 'Tracker';
import { Router } from 'routing';
import { CookieName } from 'storage';
import { SplitTestParticipations, ensureSplitTests } from 'splitTests'
import AppContext, { initialAppContextValue } from 'AppContext'
import { subscribe as subscribeToKeyActions } from 'keyActions'
import Providers from 'App/Providers'
import { useDeviceType } from 'responsive'

// Tracker initialization must be the first thing that happens in the React app
window.tracker = new Tracker({
  mixpanel: {
    token: process.env.REACT_APP_MIXPANEL_TOKEN,
    debug: (process.env.REACT_APP_MIXPANEL_DEBUG === 'true')
  },
});

if (process.env.REACT_APP_SENTRY_DSN) {
  initializeSentry(process.env.REACT_APP_SENTRY_DSN)
}

function App() {
  const [firstVisit, setFirstVisit] = React.useState<boolean>()
  const [splitTestParticipations, setSplitTestParticipations] = React.useState<SplitTestParticipations>()
  const deviceType = useDeviceType()

  React.useEffect(() => {
    // The first visit condition depends on testing cookies, so make sure to
    // run this *before* anything that might set cookies, such as
    // ensureSplitTests()
    const cookiesAlreadySet = Object.values(CookieName).some(cookieName => {
      return (cookieName !== CookieName.CsrfToken) && Cookies.get(cookieName)
    })
    setFirstVisit(!cookiesAlreadySet)
    setSplitTestParticipations(ensureSplitTests())
    if (new URL(window.location.href).host.match(/^blog\./)) {
      window.location.replace(process.env.REACT_APP_BASE_URL as string)
    }
    subscribeToKeyActions()
  }, [])

  if (!splitTestParticipations) return null
  if (typeof firstVisit === 'undefined') return null;

  const appContextValue = initialAppContextValue({
    deviceType, firstVisit, splitTestParticipations,
  })

  return (
    <AppContext.Provider value={appContextValue}>
      <ErrorBoundary>
        <Providers splitTestParticipations={splitTestParticipations}>
          <Router />
        </Providers>
      </ErrorBoundary>
    </AppContext.Provider>
  )
}

export default App;
