import React from 'react'
import { Box } from '@mui/material'

type Props = {
  children: React.ReactNode
}

const InputNote = ({ children }: Props) => (
  <Box
    sx={{
      marginTop: '5px',
      textAlign: 'right',
      fontSize: { xs: '0.9em', sm: 'initial' }
    }}
  >
    {children}
  </Box>
)

export default InputNote
