import ApiClient from 'ApiClient'
import { BrowseableBattleMap } from 'models'
import { Event, EventOpts, MixpanelProperties } from 'Tracker'

export const subscribeWithStripe = (event: Event, battleMap?: BrowseableBattleMap, mp?: MixpanelProperties) => {
  let mixpanelProperties: MixpanelProperties = mp || {}
  if (battleMap) {
    mixpanelProperties = { access_type: battleMap.accessType }
  }
  const eventOpts = { sendImmediately: true, mixpanelProperties } as EventOpts
  window.tracker.track(event, eventOpts)
  const params = {} as any
  if (battleMap) params.map_id = battleMap.id
  ApiClient.postCamelcased('/stripe/checkout/sessions', params).then(resp => {
    window.location = resp.data.url
  })
}
