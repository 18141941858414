import React, { useContext } from 'react';
import {
  Box, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText
} from '@mui/material'
import { Menu } from '@mui/icons-material'
import { useMatch } from 'react-router-dom'
import { singleRowHeight } from 'layouts/TopAppBarBase'
import { colors } from 'theme'
import { MiscMenuItem, useMiscMenuItems } from 'layouts/miscMenu'
import { LayoutContext } from '../context'

const LeftNavItemButton = ({ miscMenuItem }:{ miscMenuItem: MiscMenuItem }) => {
  const currentPage = (useMatch(miscMenuItem.href) && miscMenuItem.href.match(/^\//))

  if (currentPage) {
    return (
      <ListItemButton selected={true} sx={{ paddingRight: '25px' }}>
        <ListItemIcon>{miscMenuItem.icon}</ListItemIcon>
        <ListItemText primary={miscMenuItem.name} />
      </ListItemButton>
    )
  } else {
    return (
      <ListItemButton href={miscMenuItem.href} sx={{ paddingRight: '25px' }}>
        <ListItemIcon>{miscMenuItem.icon}</ListItemIcon>
        <ListItemText primary={miscMenuItem.name} />
      </ListItemButton>
    )
  }
}

const LeftNavDrawer = () => {
  const miscMenuItems = useMiscMenuItems()
  const { navOpen, setNavOpen } = useContext(LayoutContext)

  return (
      <Drawer open={navOpen} onClose={() => setNavOpen(false)}>
        <Box sx={{ height: `${singleRowHeight - 10}px` }}>
          <IconButton
            sx={{
              color: colors.headerBackground,
              margin: {
                'lg': '6px 0 0 4px',
              },
              padding: {
                'xs': '14px 0 0 5px',
                'sm': '18px 0 0 5px',
                'lg': '12px'
              }
            }}
            size='large'
            onClick={() => setNavOpen(!navOpen)}
          >
            <Menu />
          </IconButton>
        </Box>
        <List>
          {miscMenuItems.map(miscMenuItem => (
            <LeftNavItemButton
              miscMenuItem={miscMenuItem}
              key={miscMenuItem.href}
            />
          ))}
        </List>
      </Drawer>
  )
}

export default LeftNavDrawer
