import { useEffect } from 'react'
import { useSearchParams, useNavigate } from "react-router-dom";
import { Event } from 'Tracker'

const AfterSignInRedirect = () => {
  const [searchParams, _setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    window.tracker.track(Event.SignedIn)
    const destination = searchParams.get("destination") || '/'
    if (new URL(document.baseURI).origin === new URL(destination, document.baseURI).origin) {
      navigate(destination)
    } else {
      window.location.href = destination
    }
  }, [])

  return null
}

export default AfterSignInRedirect
