import React from 'react'
import LoggedInSubscribeDialog from 'components/dialogs/LoggedInSubscribeDialog';
import NotLoggedInSubscribeDialog from 'components/dialogs/NotLoggedInSubscribeDialog';
import { Session } from 'models'

type Props = {
  session: Session
}

const SuggestedMapsSubscribeDialog = ({ session }: Props) => (
  <>
    {session.user && <LoggedInSubscribeDialog user={session.user} />}
    {!session.user && <NotLoggedInSubscribeDialog />}
  </>
)

export default SuggestedMapsSubscribeDialog
