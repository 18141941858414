import { createContext } from 'react'
import { PageMetadata, SchemaOrgImageObject } from 'document';

export type DocumentValue = {
  canonicalAbsolutePath: string | null;
  setCanonicalAbsolutePath: (cp: string | null) => void
  setMetaRefresh: (mr: URL | null) => void
  setMetaRobots: (mr: string | null) => void
  setPageImageObjects: (pios: SchemaOrgImageObject[]) => void
  setPageMetadata: (pm?: PageMetadata) => void
}

export const DocumentContext = createContext<DocumentValue | {}>({})
