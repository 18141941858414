import React, { useContext } from 'react'
import { AppLoaderContext, AppLoaderContextValue } from 'AppLoader'
import { BrowseableBattleMap } from 'models'
import { createHostedUrl } from 'models/BrowseableBattleMap'
import { IconButton } from 'components/MapCardBase'
import { openUrlInNewWindow } from 'window'
import { Event, TrackerContext } from 'Tracker'
import { Routes } from 'routing/deprecated'
import { Download } from '@mui/icons-material'
import { PrivateAssetDownloadContext } from 'providers/PrivateAssetDownloadProvider'
import Cookies from 'js-cookie'
import { CookieName } from 'storage'

type Props = {
  battleMap: BrowseableBattleMap,
}

const HostedButton = ({ battleMap }: Props) => {
  const { session } = React.useContext(AppLoaderContext) as AppLoaderContextValue
  const { downloadPrivateAsset } = React.useContext(PrivateAssetDownloadContext)
  const { mixpanelProperties } = useContext(TrackerContext)

  if (!session) return null;

  const onClick = () => {
    if (session.user) {
      const creatorId = battleMap.creator.id
      if (session.user.followedCreatorIds.includes(creatorId)) {
        if (Cookies.get(CookieName.OwlbearRodeoDevelopment)) {
          createHostedUrl(battleMap)
            .then(({ url }) => {
              const message = {
                name: battleMap.name,
                gridDimensions: battleMap.gridDimensions,
                url,
              }
              window.opener.postMessage(message, '*')
            })
        } else {
          downloadPrivateAsset(
            () => createHostedUrl(battleMap),
            Event.DirectDownloadMapDownloaded,
            battleMap.id,
            mixpanelProperties
          )
        }
      } else {
        openUrlInNewWindow(Routes.mapPagePath(battleMap, { autoDownload: true }))
      }
    } else {
      openUrlInNewWindow(Routes.mapPagePath(battleMap, { autoDownload: true }))
    }
  }

  return (
    <IconButton onClick={onClick} tooltipTitle="Download">
      <Download fontSize="small" />
    </IconButton>
  )
}

export default HostedButton;
