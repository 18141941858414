import React from 'react';
import { MenuItem, ListItemIcon } from '@mui/material'
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom'
import { colors } from 'theme';

const StyledLink = styled(Link)({
  color: colors.darkGrey,
  textDecoration: 'none',
  '&:hover': {
    color: colors.darkGrey,
    textDecoration: 'none',
  },
  '&:visited': {
    color: colors.darkGrey,
    textDecoration: 'none',
  },
  '&:active': {
    color: colors.darkGrey,
    textDecoration: 'none',
  }
})

type Props = {
  href: string,
  children: React.ReactNode
  icon: React.ReactElement
}

const MenuItemLink = ({ href, children, icon }: Props) => (
  <MenuItem>
    <ListItemIcon>{icon}</ListItemIcon>
    <StyledLink to={href}>
      {children}
    </StyledLink>
  </MenuItem>
)

export default MenuItemLink
