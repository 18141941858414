import { IconButton as MuiIconButton } from '@mui/material'
import { colors } from 'theme'
import { Close, NavigateBefore, NavigateNext } from '@mui/icons-material'

type IconButtonProps = {
  disabled?: boolean
  icon: JSX.Element
  onClick: () => void
  size?: 'medium' | 'small'
}

const IconButton = ({ disabled = false, size = 'medium', icon, onClick }: IconButtonProps) => (
  <MuiIconButton
    sx={{
      background: colors.offWhiteBackground,
      '&:hover': {
        background: colors.offWhiteBackground,
        opacity: '0.75',
      }
    }}
    size={size}
    color="secondary"
    onClick={onClick}
    disabled={disabled}
  >
    {icon}
  </MuiIconButton>
)

type ButtonProps = {
  disabled?: boolean
  onClick: () => void
  size?: 'medium' | 'small'
}

export const CloseButton = (props: ButtonProps) => (
  <IconButton {...props} icon={<Close />} />
)

export const PrevButton = (props: ButtonProps) => (
  <IconButton {...props} icon={<NavigateBefore />} />
)

export const NextButton = (props: ButtonProps) => (
  <IconButton {...props} icon={<NavigateNext />} />
)
