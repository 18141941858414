export const mapCardFields = `
  id
  accessType
  arWidth
  arHeight
  creator {
    id
    slug
    name
  }
  gridWidth
  gridHeight
  environments
  name
  body
  sanitizedBodyHtml
  premium
  product {
    id
    mapVariations {
      imageSlug
    }
  }
  variations {
    imageSlug
    cfPreviews {
      width
      subPath
    }
    ucPreviews {
      width
      uuid
    }
  }
`

export const queryMaps = `
  count
  maps(page: $page) {
    ${mapCardFields}
  }
`
