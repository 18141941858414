import React, { forwardRef, ForwardedRef, MutableRefObject } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grow,
  ClickAwayListener,
  Paper,
  Switch,
  Popper,
} from '@mui/material';

import ApiClient from 'ApiClient';
import { Event } from 'Tracker';
import { Followship } from 'models';
import { colors , zIndexes } from 'theme';
import { useFollowships } from 'loaders/FollowshipLoader';
import { FollowshipActionType } from 'reducers/useFollowshipsReducer';

const DropdownMenu = styled('div')({
  zIndex: zIndexes.popper,
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 15px',
  maxWidth: '300px'
})

const EmailNotificationsHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '7px'
})

const EmailNotificationsHeaderText = styled('div')({
  fontWeight: 'bold'
})

const EmailToggle = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: colors.darkGrey,
  },
}))

interface Props {
  followship: Followship
  creatorName: string
  open: boolean
  closeDropdown: () => any
}

const FollowshipDropdown = forwardRef(({ followship, creatorName, open, closeDropdown }: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  const { followshipsDispatch } = useFollowships()
  const trackToggleEmailEvent = () => window.tracker.track(Event.CreatorEmailNotificationsToggled)
  const handleEmailToggle = () => {
    trackToggleEmailEvent()
    ApiClient.putCamelcased(`/users/followships/${followship.id}`, { email_me: !followship.emailMe }).then(resp => {
      const followship = resp.data
      followshipsDispatch({ type: FollowshipActionType.UPDATE, followship })
    })
  }

  return (
    <Popper
      anchorEl={ref && (ref as MutableRefObject<HTMLButtonElement>).current}
      open={open}
      sx={{ zIndex: zIndexes.popper }}
      placement="bottom-end"
      transition
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: 'right bottom' }}>
          <Paper sx={{ zIndex: zIndexes.popper }}>
            <ClickAwayListener onClickAway={closeDropdown}>
              <DropdownMenu>
                <EmailNotificationsHeader>
                  <EmailNotificationsHeaderText>
                    Email Notifications
                  </EmailNotificationsHeaderText>
                  <div>
                    <EmailToggle
                      checked={followship.emailMe}
                      onClick={handleEmailToggle}
                      role="switch"
                      color="default"
                      data-testid="followship-email-switch"
                    />
                  </div>
                </EmailNotificationsHeader>
                <div>Email me when {creatorName} releases a new map</div>
              </DropdownMenu>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
})

FollowshipDropdown.displayName = "FollowshipDropdown"

export default FollowshipDropdown;
