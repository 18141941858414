import { useContext } from 'react'
import { AppLoaderContext } from 'AppLoader'
import FlashMessage from 'components/FlashMessage';
import DiscordServerBottomAlert from 'components/DiscordServerBottomAlert'
import SuggestedMapsSubscribeDialog from 'components/dialogs/SuggestedMapsSubscribeDialog'

// Catch-all for components that should be present on every page. Used in the
// Router.
const PageGlobals = () => {
  const { session } = useContext(AppLoaderContext)

  return (
    <>
      {session && <SuggestedMapsSubscribeDialog session={session} />}
      <FlashMessage />
      <DiscordServerBottomAlert />
    </>
  )
}

export default PageGlobals
