import { useContext, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Layout from 'layouts/Layout';
import CenteredCircularProgress from 'components/CenteredCircularProgress'
import Page from './Page';
import { withPageWrapper } from 'pages'
import { Event } from 'Tracker'
import { AppLoaderContext } from 'AppLoader'
import { Paths } from 'routing'
import { useMapProductLoader } from 'loaders/MapProductLoader';
import { subscriptionHasLapsed } from 'models';
import AppContext from 'AppContext'

const SubscriptionMapPage = () => {
  const { battleMapId } = useParams() as { battleMapId: string }
  const { session } = useContext(AppLoaderContext)
  const product = useMapProductLoader(battleMapId)
  const navigate = useNavigate();
  const { deviceType } = useContext(AppContext)

  useEffect(() => {
    if (deviceType !== 'desktop' && session?.user?.subscription && !subscriptionHasLapsed(session.user.subscription)) {
      // Stay on this page
    } else if (session?.user?.subscription && subscriptionHasLapsed(session.user.subscription)) {
      navigate(Paths.resubscribePath())
    } else {
      navigate(Paths.mapPagePath({ id: battleMapId }))
    }
  }, [JSON.stringify(session), battleMapId, deviceType])

  if (!product || !session) {
    return (
      <Layout backgroundColor="light" padding="medium">
        <CenteredCircularProgress />
      </Layout>
    )
  }

  return (
    <Layout backgroundColor="light" padding="medium">
      <Page product={product} />
    </Layout>
  )
}

export default withPageWrapper(
    SubscriptionMapPage, Event.SubscriptionMapPageLoaded, { signInRequired: true }
)
