import { styled } from '@mui/material/styles';
import { colors } from 'theme'

export type BackgroundColorValue = 'light' | 'dark'

export const LightContentContainer = styled('div')({
  background: colors.offWhiteBackground,
})

export const DarkContentContainer = styled('div')({
  background: colors.darkBackground,
})
