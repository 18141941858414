import React, { lazy, Suspense, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import Layout from 'layouts/Layout';
import { DelayedLinearProgress } from 'routing'
import AppContext, { AppContextValue } from 'AppContext'
import { firstVisitExplorePageRedirectPath } from 'misc';
import { searchParamsKeys } from 'search'

const ExplorePage = lazy(() => import('pages/ExplorePage'))

const HomePage = () => {
  const { firstVisit, initialLocationHref } = useContext(AppContext) as AppContextValue

  const url = new URL(window.location.href)
  const navigate = useNavigate()
  const firstVisitRedirectPath = firstVisitExplorePageRedirectPath(firstVisit, initialLocationHref)
  let searchPageParamFound = false;
  url.searchParams.forEach((_value, key) => {
    if (!searchPageParamFound) {
      searchPageParamFound = (searchParamsKeys.indexOf(key) !== -1)
    }
  })

  if (firstVisitRedirectPath) {
    navigate(firstVisitRedirectPath)
    return null
  } else {
    return (
      <Layout backgroundColor="dark">
        <Suspense fallback={<DelayedLinearProgress />}>
          <ExplorePage />
        </Suspense>
      </Layout>
    )
  }
}

export default HomePage
