import React, { useState } from 'react';
import { Feature } from 'Tracker';
import SignInWithAuth0Dialog from 'components/dialogs/SignInWithAuth0Dialog'

interface Props {
  children: React.ReactNode
  feature?: Feature
  postSignInAction?: string
}

const SignInWithAuth0DialogOpener = (props: Props) => {
  const { children, feature, postSignInAction } = props
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <span onClick={() => setModalOpen(true)}>
        {children}
      </span>
      <SignInWithAuth0Dialog
        feature={feature}
        postSignInAction={postSignInAction}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  )
}

export default SignInWithAuth0DialogOpener
