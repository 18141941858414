import React, { useContext } from 'react';
import { Button, IconButton } from '@mui/material'
import { Person } from '@mui/icons-material'
import SignInClickTrigger from 'components/SignInClickTrigger'
import AppContext from 'AppContext'

const NotSignedIn = () => {
  const { deviceType } = useContext(AppContext)
  const isPhone = deviceType === 'phone'

  const button = () => {
    if (isPhone) {
      return (
        <IconButton
          color="secondary"
          sx={{ marginBottom: '6px' }}
        >
          <Person data-testid="nav-not-signed-in "/>
        </IconButton>
      )
    } else {
      return (
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<Person data-testid="nav-not-signed-in "/>}
          sx={{
            borderRadius: '20px',
            marginBottom: '9px',
            marginLeft: '15px',
          }}
        >
          Sign in
        </Button>
      )
    }
  }

  return <SignInClickTrigger>{button()}</SignInClickTrigger>
}

export default NotSignedIn;
