import React from 'react';
import { InputLabel, TextField as MuiTextField, TextFieldProps } from '@mui/material';

function TextField(props: TextFieldProps) {
  const { label, ...rest } = props;
  return (
    <React.Fragment>
      {label && <InputLabel htmlFor={props.id}>{label}</InputLabel>}
      <MuiTextField {...rest} />
    </React.Fragment>
  )
}

export default TextField
