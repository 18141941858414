export const borderRadius = '10px'

type ContainerSxProps = {
  hoverCursor?: string
} | undefined

export const containerSx = (props?: ContainerSxProps) => {
  const { hoverCursor } = props || { hoverCursor: 'pointer' }

  return {
    marginBottom: '15px !important',
    '&:hover': { cursor: hoverCursor },
    textDecoration: 'none'
  }
}
