import { ErrorMessage as FormikErrorMessage } from 'formik'
import { Box } from '@mui/material'

type Props = {
  name: string,
}

const ErrorMessage = ({ name }: Props) => (
  <Box
    sx={{
      color: 'red',
      fontSize: '0.9em',
      position: 'absolute',
      marginTop: '5px',
    }}
  >
    <FormikErrorMessage name={name} />
  </Box>
)

export default ErrorMessage
