import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

/*
 * General use of breakpoints, using MUI 5 defaults plus added sizes in theme5.ts:
 *
 * XS:          Phone
 * SM, MD:      Tablet
 * LG and up:   Desktop
 */

const useDesktop = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up('lg'));
}

const useTablet = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('sm', 'lg'));
}

const usePhone = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.only('xs'))
}

export type DeviceType = 'desktop' | 'phone' | 'tablet'

export const useDeviceType = (): DeviceType | null => {
  const usingDesktop = useDesktop()
  const usingPhone = usePhone()
  const usingTablet = useTablet()

  if (usingDesktop) {
    return 'desktop';
  } else if (usingPhone) {
    return 'phone'
  } else if (usingTablet) {
    return 'tablet'
  }

  return null
}
