import React, { Suspense } from 'react'
import { Layout } from 'layouts'
import { Outlet } from "react-router-dom";
import { DelayedLinearProgress } from './suspense'

type Props = {
}

const SuspenseRouteSearchElement = (props: Props) => (
  <Suspense
    fallback={
      <Layout backgroundColor="dark" padding="small">
        <DelayedLinearProgress />
      </Layout>
    }
  >
    <Outlet />
  </Suspense>
)

export default SuspenseRouteSearchElement
