import React from 'react'
import { Box } from '@mui/material'
import { zIndexes } from 'theme'

type Props = {
  children: React.ReactNode
  onKeyUp: (evt: React.KeyboardEvent<HTMLInputElement>) => void
  testId?: string
  zoomOut: () => void
  opacity?: number
}

const ZoomOverlay = (props: Props) => {
  const { children, opacity = 0.75, onKeyUp, testId, zoomOut } = props
  const ref = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    // If the overlay is viewable, focus on the overlay so key presses will
    // work
    ref.current && ref.current.focus();
  }, [ref])

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: zIndexes.overlay,
        padding: {
          xs: '5px', sm: '10px', md: '25px'
        }
      }}
      onKeyUp={onKeyUp}
      ref={ref}
      id={testId}
      data-testid={testId}
      tabIndex={0}
    >
      <Box
        sx={{
          backgroundColor: `rgba(0,0,0,${opacity})`,
          position: 'fixed',
          zIndex: -1,
          inset: 0,
          WebkitTapHighlightColor: 'transparent',
        }}
        onClick={zoomOut}
      />
      {children}
    </Box>
  )
}

export default ZoomOverlay
