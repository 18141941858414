import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Button, Fade, Paper, Box } from '@mui/material';
import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'
import { colors } from 'theme'
import { CookieName } from 'storage'
import { discordLink } from 'discord'
import { ExternalLink } from 'components/navigation'
import AppContext from 'AppContext'

const WelcomeMessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    margin: '10px 20px',
    gap: '20px',
  },
  [theme.breakpoints.up('sm')]: {
    margin: '15px 25px',
    gap: '30px',
  },
}))

const Description = styled(Box)({
  flexGrow: 1
})

const alertBannerDelay = 100000 // 100 seconds
const hideCookieValue = '1'
const hideCookieExpirationDays = 90

const AlertBanner = () => {
  const [showBanner, setShowBanner] = useState<boolean>(false)
  const theme = useTheme()
  const { deviceType } = useContext(AppContext)
  const isPhone = deviceType === 'phone'
  const isDesktop = deviceType === 'desktop'
  const isTablet = deviceType === 'tablet'
  const areSizesUnavailable = deviceType === null

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const cookieValue = Cookies.get(CookieName.DiscordServerBottomAlert)
      if (cookieValue !== hideCookieValue) {
        setShowBanner(true)
      }
    }, alertBannerDelay)
    return () => clearTimeout(timeoutId)
  }, [])

  if (areSizesUnavailable) { return null }

  const closeBannerForever = () => {
    setShowBanner(false)
    Cookies.set(
      CookieName.DiscordServerBottomAlert,
      hideCookieValue,
      { expires: hideCookieExpirationDays }
    )
  }

  return (
    <Fade in={showBanner}>
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          borderRadius: 0,
          color: 'white',
          backgroundColor: theme.palette.info.dark,
        }}
        elevation={3}
      >
        <WelcomeMessageContainer>
          {isDesktop && <Description>
            Our Discord server is the perfect place to talk about maps, learn about new Lost Atlas features, and chat with other game masters. Join now!
          </Description>}
          {isTablet && <Description>
            Our Discord server is the perfect place to talk about maps and learn about new Lost Atlas features. Join now!
          </Description>}
          {isPhone && <Description>
            Join our Discord server now!
          </Description>}
          <Button
            onClick={closeBannerForever}
            variant="text"
            sx={{ color: colors.lightGrey }}
          >
            No&nbsp;thanks
          </Button>
          <ExternalLink href={discordLink} newTab>
            <Button
              onClick={closeBannerForever}
              variant="outlined"
              sx={{
                height: 'fit-content',
                backgroundColor: colors.offWhiteBackground,
                '&:hover': {
                  color: colors.darkBlue,
                  backgroundColor: colors.lightGrey
                }
              }}
            >
              Join
            </Button>
          </ExternalLink>
        </WelcomeMessageContainer>
      </Paper>
    </Fade>
  )
}

export default AlertBanner
