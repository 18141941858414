import React, { useContext } from 'react'
import { Feature, TrackerContext, MixpanelProperties, Event } from 'Tracker';
import SignInWithAuth0DialogOpener from 'components/SignInWithAuth0DialogOpener'
import { navigateToAuth0 } from 'auth0'
import Cookies from 'js-cookie'
import { CookieName } from 'storage'

const trackSignInStarted = (mixpanelProperties: MixpanelProperties | undefined) => window.tracker.track(
  Event.SignInStarted,
  { mixpanelProperties: (mixpanelProperties || {}), sendImmediately: true }
)

type StartAuth0SignInProps = {
  mixpanelProperties?: MixpanelProperties,
  postSignInAction?: string
}

const startAuth0SignIn = (props: StartAuth0SignInProps) => {
  const { mixpanelProperties, postSignInAction } = props

  trackSignInStarted(mixpanelProperties)
  if (postSignInAction) {
    Cookies.set(CookieName.PostSignInAction, postSignInAction)
  }
  navigateToAuth0()
}

type SignInWithAuth0ClickTargetProps = {
  children: React.ReactNode
  feature?: Feature
  postSignInAction?: string
}

const SignInWithAuth0ClickTarget = (props: SignInWithAuth0ClickTargetProps) => {
  const { children, feature, postSignInAction } = props
  const { mixpanelProperties: mpFromContext } = useContext(TrackerContext)

  const mixpanelProperties = { ...mpFromContext }
  if (feature) mixpanelProperties.feature = feature

  return (
    <span
      onClick={() => startAuth0SignIn({ mixpanelProperties, postSignInAction })}
    >
      {children}
    </span>
  )
}

interface Props {
  children: React.ReactNode
  feature?: Feature
  postSignInAction?: string
  showDialogFirst?: boolean
}

const SignInClickTrigger = (props: Props) => {
  const {
    children, feature, postSignInAction, showDialogFirst = false
  } = props

  if (showDialogFirst) {
    return (
      <SignInWithAuth0DialogOpener
        feature={feature}
        postSignInAction={postSignInAction}
      >
        {children}
      </SignInWithAuth0DialogOpener>
    )
  } else {
    return (
      <SignInWithAuth0ClickTarget
        feature={feature}
        postSignInAction={postSignInAction}
      >
        {children}
      </SignInWithAuth0ClickTarget>
    )
  }
}

export default SignInClickTrigger
