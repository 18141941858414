import React, { useContext } from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { FlashMessageContext } from 'providers/FlashMessageProvider';
import { clearMessageAction, ERROR_STATUS, INFO_STATUS, setErrorMessageAction, setInfoMessageAction } from 'reducers/useFlashMessageReducer';

const FLASH_MESSAGE_PARAM = "flashMessage"
const FLASH_STATUS_PARAM = "flashStatus"

export const flashMessageQueryParams = (message: string, status: string) => (
  `${FLASH_MESSAGE_PARAM}=${message}&${FLASH_STATUS_PARAM}=${status}`
)

const FlashMessage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { flashMessage, flashMessageDispatch } = useContext(FlashMessageContext)

  React.useEffect(() => {
    if (searchParams.get(FLASH_MESSAGE_PARAM)) {
      const flashMessage = searchParams.get(FLASH_MESSAGE_PARAM)
      const flashStatus = searchParams.get(FLASH_STATUS_PARAM)

      if (flashStatus === ERROR_STATUS) {
        flashMessageDispatch(setErrorMessageAction({ message: flashMessage }))
      } else if (flashStatus === INFO_STATUS) {
        flashMessageDispatch(setInfoMessageAction({ message: flashMessage }))
      }
      searchParams.delete(FLASH_MESSAGE_PARAM)
      searchParams.delete(FLASH_STATUS_PARAM)
      setSearchParams(searchParams.toString())
    }
  }, [searchParams.toString()])

  const handleClose = () => {
    flashMessageDispatch(clearMessageAction());
  };

 return (
  <Snackbar
    open={flashMessage.open}
    autoHideDuration={flashMessage.autoHideDuration}
    onClose={handleClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  >
    <Alert onClose={handleClose} severity={(flashMessage.status || "") as AlertColor}>
      {flashMessage.message || ""}
    </Alert>
  </Snackbar>
 )
}

export default FlashMessage;
