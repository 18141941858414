import React from 'react'
import ApiClient from 'ApiClient';
import { setShoppingCartAction, ShoppingCartAction } from 'reducers/useShoppingCartReducer'
import { Event, MixpanelProperties } from 'Tracker'
import { Routes } from 'routing/deprecated'
import { Product } from 'models'

type AddToCartProps = {
  product: Pick<Product, 'mapVariations' | 'battleMap' | 'id'>
  navigate: (path: string) => void
  shoppingCartDispatch: React.Dispatch<ShoppingCartAction>
  mixpanelProperties?: MixpanelProperties
}

export const addToCart = (props: AddToCartProps) => {
  const { product, navigate, shoppingCartDispatch, mixpanelProperties = {} } = props
  const finalMixpanelProperties = {
    ...mixpanelProperties,
    variation_count: product.mapVariations?.length,
    map_id: product.battleMap.id
  }
  window.tracker.track(
    Event.PremiumMapAddedToCart, { mixpanelProperties: finalMixpanelProperties }
  );
  const params = {
    mixpanel_source_component: finalMixpanelProperties.source_component,
    mixpanel_source_page: finalMixpanelProperties.source_page,
    products: [{ id: product.id }]
  }
  ApiClient.postCamelcased("/shopping_cart/add_item", params).then((resp) => {
    shoppingCartDispatch(
      setShoppingCartAction(resp.data)
    )
    navigate(Routes.checkoutPagePath())
  })
}

export const signOut = () => {
  ApiClient.deleteRaw("/users/session").then(() => {
    window.tracker.reset()
    window.location.reload()
  })
}
