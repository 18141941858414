import { BattleMap } from './BattleMap'
import { Subscription } from './Subscription'
import { Visitor } from './Visitor'

export enum VttDownloadTarget {
  Roll20Free = 'roll20_free',
  Roll20Paid = 'roll20_paid',
  Foundry = 'foundry',
  FantasyGrounds = 'fantasy_grounds'
}

export interface User extends Visitor {
  id: number;
  admin: boolean;
  administeredCreatorId?: number;
  avatarUrl: string;
  email: string;
  firstName: string;
  followedCreatorIds: number[];
  subscription?: Subscription;
  patreonBackCatalogCreatorIds: number[];
  promptedToSubscribeToBlogEmails: boolean;
  vttDownloadTarget?: VttDownloadTarget
}

export const hasBattleMapAccessViaPatreon = (user: User, bm: BattleMap) => (
  user.patreonBackCatalogCreatorIds.includes(bm.creator.id)
)
