import React from 'react';
import { Box, SxProps } from '@mui/material'

type ContainerProps = {
  children: React.ReactNode,
  sx?: SxProps
}

export const Container = (props: ContainerProps) => {
  const { children, sx: sxOverride = {} } = props

  const sx: SxProps = {
    width: '100%',
    fontSize: '16px',
    '& *, & *:before, & *:after': {
      boxSizing: 'border-box',
    },
  }
  return <Box sx={{ ...sx, ...sxOverride }}>{children}</Box>
}
