import React from 'react';
import { Box } from '@mui/material'
import { colors , zIndexes } from 'theme'

type Props = {
  children?: React.ReactNode;
  position?: string;
}

const Container = ({ children, position = "static" }: Props) => (
  <Box
    sx={{
      position,
      top: 0,
      zIndex: zIndexes.appBar,
      width: '100%',
      backgroundColor: colors.headerBackground,
      borderBottom: '1px solid #803c33',
    }}
  >
    {children}
  </Box>
)

export default Container;
