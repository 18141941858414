import React from 'react';
import { Box } from '@mui/material';
import Layout from 'layouts/Layout';
import { Header } from 'components/page';
import { SignInWithPatreonButton } from 'components/buttons';
import { flashMessageQueryParams } from 'components/FlashMessage';
import { Routes } from 'routing/deprecated';
import { Event } from 'Tracker';
import { withPageWrapper } from 'pages/wrapper';
import { CreatorLoader, useCreator } from 'loaders/CreatorLoader'

const Inner = () => {
  const { creator } = useCreator();

  if (!creator ) return null

  const flashMessage = "Your Lost Atlas account is now connected to Patreon."
  const redirectPath = `${Routes.patreonRewardsPath()}?${flashMessageQueryParams(flashMessage, "info")}`

  return (
    <Layout backgroundColor="light" padding="medium">
      <Box
        sx={{
          paddingLeft: { lg: '16px' },
          minHeight: { xs: '1000px', lg: '700px' },
        }}
      >
        <Header>
          Thanks for supporting {creator.name}
        </Header>
        <Box sx={{ paddingTop: '10px' }}>
          As a Patreon patron, you&apos;ll be able to search and download their maps through Lost Atlas, at no extra cost. Let&apos;s connect your Lost Atlas account to Patreon to get you started.
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
          <SignInWithPatreonButton destination={redirectPath}>
            Connect to Patreon
          </SignInWithPatreonButton>
        </Box>
      </Box>
    </Layout>
  )
}

const PatreonRewardsCreatorSpecificOnboarding = () => (
  <CreatorLoader>
    <Inner />
  </CreatorLoader>
)

export default withPageWrapper(
  PatreonRewardsCreatorSpecificOnboarding,
  Event.PatreonCreatorRewardsOnboardingPageLoaded,
  { signInRequired: false }
) 
