import { BrowseableBattleMap, Creator, Product } from 'models'
import { DeprecatedAccessType, accessTypeFromApiResponse } from 'models/AccessType'

export interface MapsResultSetMap {
  id: string;
  accessType: DeprecatedAccessType
  body: string;
  creator: Creator;
  environments: string[];
  externalUrl: string | null;
  gridWidth: number;
  gridHeight: number;
  arWidth: number;
  arHeight: number;
  product?: Product;
  sanitizedBodyHtml: string;
  name: string;
  premium: boolean;
  variations: {
    id: number
    imageSlug: string
    cfPreviews?: {
      width: number
      subPath: string
    }[]
    ucPreviews?: {
      width: number
      uuid: string
    }[]
  }[]
}

export interface MapsResultSet {
  count: number
  maps: MapsResultSetMap[]
}

export const convertMapsResultSetMapToBrowseableBattleMap = (map: MapsResultSetMap): BrowseableBattleMap => {
  const result = {
    id: map.id,
    accessType: accessTypeFromApiResponse(map.accessType),
    arHeight: map.arHeight,
    arWidth: map.arWidth,
    body: map.body,
    creator: map.creator,
    environmentIds: map.environments,
    externalUrl: map.externalUrl,
    name: map.name,
    premium: map.premium,
    product: map.product,
    sanitizedBodyHtml: map.sanitizedBodyHtml,
    variations: map.variations.map(v => ({
      id: v.id,
      battleMapId: map.id,
      cfPreviews: v.cfPreviews,
      imageSlug: v.imageSlug,
      ucPreviews: v.ucPreviews,
    }))
  } as any
  if (map.gridWidth && map.gridHeight) {
    result.gridDimensions = { width: map.gridWidth, height: map.gridHeight }
  }
  return result as BrowseableBattleMap
}

export const convertMapsResultSetToBrowseableBattleMaps = (mapsResultSet: MapsResultSet): BrowseableBattleMap[] => (
  mapsResultSet.maps.map(m => convertMapsResultSetMapToBrowseableBattleMap(m))
)
