import { useState, useContext } from 'react';
import { FlashMessageContext, FlashMessageDispatch } from 'providers/FlashMessageProvider';
import { DownloadPrivateAssetFn } from './context'
import { setErrorMessageAction } from 'reducers/useFlashMessageReducer';
import * as Sentry from "@sentry/react";
import { Event } from 'Tracker'

export const handleCreateDownloadUrlError = (error: any, flashMessageDispatch: FlashMessageDispatch) => {
  const errorCode = error.response?.data?.error_code
  let message: string
  if (errorCode === "subscription_reached_quota") {
    message = "Sorry, you have reached your download limit for this month. Please try again next month."
  } else {
    message = "Sorry, there was a problem downloading this file. We are looking into the issue. Please try again later."
  }
  flashMessageDispatch(setErrorMessageAction({ message }))
  if (errorCode !== "subscription_reached_quota") {
    const errorAttrs = {
      axiosCode: error.code,
      axiosMessage: error.message,
      status: error.response?.status,
      errorCode
    }
    Sentry.captureMessage(
      `Could not create download URL: ${JSON.stringify(errorAttrs)}`
    )
  }
}

type SuccessProps = {
  url: string
  follower: boolean
  vttDownloadTarget: string | undefined,
  setDownloadUrls: React.Dispatch<React.SetStateAction<string[]>>
  downloadUrls: string[]
  downloadEventProperties: any
  mapId: string
  downloadEvent: Event
}
        
const handleCreateDownloadUrlSuccess = (props: SuccessProps) => {
  const { url, follower, vttDownloadTarget, setDownloadUrls, downloadUrls, downloadEventProperties, mapId, downloadEvent } = props
  setDownloadUrls(downloadUrls.concat(url))
  const eventOpts = {
    mixpanelProperties: {
      ...(downloadEventProperties || {}),
      ...{ map_id: mapId, vtt_download_target: vttDownloadTarget }
    }
  }
  eventOpts.mixpanelProperties.follower = follower
  window.tracker.track(downloadEvent, eventOpts)
}

// ERR_NETWORK is indicates a network error. This function retries the function fn up to retriesLeft times, with an interval between each retry. If the error code is something other than ERR_NETWORK, the function rejects the promise immediately, without retrying.
const retryErrNetwork = (fn: any, retriesLeft: number, interval: number): Promise<any> => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: any) => {
        if (error.response?.data?.error_code !== "ERR_NETWORK") {
          reject(error)
        } else {
          if (retriesLeft === 1) {
            reject(error)
            return
          }
          setTimeout(() => {
            retryErrNetwork(fn, retriesLeft - 1, interval).then(resolve, reject)
          }, interval)
        }
      })
  })
}

export const useDownloadPrivateAsset = () => {
  const [downloadUrls, setDownloadUrls] = useState<string[]>([])
  const { flashMessageDispatch } = useContext(FlashMessageContext)

  const downloadPrivateAsset: DownloadPrivateAssetFn = (createDownloadUrl, downloadEvent, mapId, downloadEventProperties) => (
    new Promise((resolve) => {
      retryErrNetwork(createDownloadUrl, 5, 100)
        .then(({ url, follower, vttDownloadTarget }) => {
          handleCreateDownloadUrlSuccess({
            url,
            follower,
            vttDownloadTarget,
            setDownloadUrls,
            downloadUrls,
            downloadEventProperties,
            mapId,
            downloadEvent
          })
          resolve(url)
        })
        .catch((error) => handleCreateDownloadUrlError(error, flashMessageDispatch))
    })
  )

  return { downloadPrivateAsset, downloadUrls }
}