import React from 'react';
import { AddToCartButton, AlreadyPurchasedButton, IconButton } from 'components/MapCardBase'
import { BrowseableBattleMap, Product, Session } from 'models'
import { AppLoaderContext, AppLoaderContextValue } from 'AppLoader';
import { useNavigate } from 'react-router-dom'
import { Routes } from 'routing/deprecated'
import { Download, Map } from '@mui/icons-material';

type Props = {
  battleMap: BrowseableBattleMap,
  product: Product,
  session: Session
}

const ProductButton = ({ battleMap, product, session }: Props) => {
  const navigate = useNavigate()
  const { catalog, purchasedMapIds } = React.useContext(AppLoaderContext) as AppLoaderContextValue

  let hasPatreonAccess = false
  let hasSubscriptionAccess = false
  let hasDownloadsRemaining = false
  if (session.user) {
    const { patreonBackCatalogCreatorIds, subscription } = session.user;
    if (subscription) {
      hasSubscriptionAccess = true;
      hasDownloadsRemaining = subscription.downloadsRemainingThisPeriod > 0
    }
    hasPatreonAccess = patreonBackCatalogCreatorIds.includes(battleMap.creator.id)
  }
  const alreadyPurchased = purchasedMapIds.includes(battleMap.id)

  if (hasPatreonAccess) {
    return (
      <IconButton
        tooltipTitle="Patreon reward"
        onClick={() => navigate(Routes.patreonRewardPath(battleMap))}
      >
        <img data-testid="patreon-reward" src="/images/patreon-icon-gray.png" height="20px" width="20px" />
      </IconButton>
    )
  } else if (hasSubscriptionAccess) {
      if (hasDownloadsRemaining) {
        return (
          <IconButton
            tooltipTitle="Download"
            onClick={() => navigate(Routes.subscriptionMapPath(battleMap))}
          >
            <Download data-testid="gold-subscription" fontSize="small"/>
          </IconButton>
        )
      } else {
        return <AddToCartButton product={product} />
      }
  } else if (alreadyPurchased) {
    return <AlreadyPurchasedButton />
  } else {
    if (!catalog) return null;
    const { defaultSubscriptionTier } = catalog;

    return (
      <IconButton
        tooltipTitle={`Lost Atlas ${defaultSubscriptionTier.name}`}
        onClick={() => navigate(Routes.subscriptionLandingPagePath(battleMap))}
      >
        <Map data-testid="subscribe" fontSize="small"/>
      </IconButton>
    )
  }
}

export default ProductButton;
