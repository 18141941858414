import React from 'react';
import { Link, LinkProps } from '@mui/material'

function NewTabInternalLink({ children, ...props }: LinkProps) {
  return (
    <Link
      target="_blank"
      {...props}
    >
      {children}
    </Link>
  )
}

export default NewTabInternalLink;
