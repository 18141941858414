// Tracked here to prevent collisions in Mixpanel
export enum UnusedEvents {
  DirectDownloadSubscribeClicked = 'Direct download subscribe clicked',
  DirectDownloadSubscribeShown = 'Direct download subscribe shown',

  ExplorePageFilteredMapsFetchedMore = 'Explore page filtered maps fetched more',

  LoginModalLoggedIn = 'Login modal logged-in',

  MapCardClicked = 'Map card clicked',
  MapCollectionScrollLastPageLoaded = 'Map collection scroll last page loaded',
  MapZoomedIn = 'Map zoomed in',
  MapZoomIconClicked = 'Map zoom icon clicked',
  MapZoomShown = 'Map zoom shown',
  MembershipLearnMoreShown = 'Membership learn more shown',
  MembershipInfoPageOpened = 'Membership info page opened',
  MembershipLeadSubmitted = 'Membership lead submitted',

  NpsSurveyShown = 'NPS survey shown',
  NpsSurveyFinished = 'NPS survey finished',

  PremiumFilterChanged = 'Premium filter changed',

  SearchBoxEntry = 'Search box text entered',
  SearchResultFetchedMore = 'Search result fetched more',

  ZoomedInMapSlided = 'Zoomed-in map slided',
}

enum Event {
  AnimatedFilterChanged = 'Animated filter changed',
  AccessTypeFilterCancelled = 'Access type filter cancelled',
  AccessTypeFilterChanged ='Access type filter changed',
  AccessTypeFilterOpened = 'Access type filter opened',

  BookmarkedFilterCancelled = 'Bookmarked filter cancelled',
  BookmarkedFilterChanged ='Bookmarked filter changed',
  BookmarkedFilterOpened = 'Bookmarked filter opened',
  BookmarkToggled = 'Bookmark toggled',
  BookmarksPageLoaded = 'Bookmarks page loaded',
  BookmarksPageFetchedMore = 'Bookmarks page fetched more',

  CheckoutAttempted = 'Checkout attempted',
  CheckoutGuestEmailEntered = 'Checkout guest email entered',
  CheckoutPageLoaded = 'Checkout page loaded',
  CheckoutSucceeded = 'Checkout succeeded',
  CreatorDashboardMainPageLoaded = 'Creator dashboard main page loaded',
  CreatorDashboardMapsPageLoaded = 'Creator dashboard maps page loaded',
  CreatorDashboardSalesPageLoaded = 'Creator dashboard sales page loaded',
  CreatorEmailNotificationsToggled = 'Creator email notifications toggled',
  CreatorPageLoaded = 'Creator page loaded',
  CreatorPageFetchedMore = 'Creator page fetched more',
  CreatorsFilterCancelled = 'Creators filter cancelled',
  CreatorsFilterChanged = 'Creators filter changed',
  CreatorsFilterOpened = 'Creators filter opened',

  DirectDownloadMapDownloaded = 'Direct download map downloaded',
  DirectDownloadDialogOpened = 'Direct download dialog opened',
  DiscordBotMapCardDisplayed = 'Discord bot map card displayed',
  DiscordBotPageLoaded = 'Discord bot page loaded',
  DiscordBotPageInstallClicked = 'Discord bot page install clicked',
  DiscordServerAdCardDisplayed = 'Discord server ad card displayed',

  EmailSubscriptionsPageLoaded = 'Email subscriptions page loaded',
  EnvironmentsFilterCancelled = 'Environments filter cancelled',
  EnvironmentsFilterChanged = 'Environments filter changed',
  EnvironmentsFilterOpened = 'Environments filter opened',
  ErrorPageRendered = 'Error page rendered',
  ErrorPageReturnToHome = 'Error page return to home',
  ExplorePageLoaded = 'Explore page loaded',
  ExplorePageChipFilterChosen = 'Explore page chip filter chosen',
  ExplorePageFilterSectionFilterOpened = 'Explore page filter section filter opened',
  ExplorePageFilterSectionFilterChosen = 'Explore page filter section filter chosen',
  ExplorePageTrendingMapsFetchedMore = 'Explore page trending maps fetched more',
  ExternalCreatorPageOpened = 'External creator page opened',
  ExternalMapPageOpened = 'External map page opened',

  FaqPageLoaded = 'FAQ page loaded',
  FollowingPageLoaded = 'Following page loaded',
  FollowCreator = 'Creator followed',
  FoundryModuleMapCardDisplayed = 'Foundry module map card displayed',
  FoundryModulePageLoaded = 'Foundry module page loaded',

  LinkedEnvironmentChipClicked = 'Linked environment chip clicked',

  MapPageLoaded = 'Map page loaded',
  MapPageResubscribeClicked = 'Map page resubscribe clicked',
  MapSubmissionMetadataPageLoaded = 'Map submission metadata page loaded',
  MapSubmissionPublishPageLoaded = 'Map submission publish page loaded',
  MapSubmissionUploadPageLoaded = 'Map submission upload page loaded',
  MapSubmissionVariationsPageLoaded = 'Map submission variations page loaded',
  MapVariationZoomed = 'Map variation zoomed',

  NewFilterChanged = 'New filter changed',
  NewsletterUnsubscribed = 'Newsletter unsubscribed',

  OrderPageLoaded = 'Order page loaded',

  PatreonCreatorRewardsOnboardingPageLoaded = 'Patreon creator rewards onboarding page loaded',
  PatreonRewardDownloaded = 'Patreon reward downloaded',
  PatreonRewardPageLoaded = 'Patreon reward page loaded',
  PatreonRewardsPageLoaded = 'Patreon rewards page loaded',
  PremiumMapAddedToCart = 'Premium map added to cart',
  PremiumMapDownloaded = 'Premium map downloaded',
  PrivacyPolicyPageLoaded = 'Privacy policy page loaded',
  PurchasedMapsPageLoaded = 'Purchased maps page loaded',

  ResubscribePageLoaded = 'Resubscribe page loaded',
  ResubscribePageSubscribeClicked = 'Resubscribe page subscribe clicked',

  SearchPageLoaded = 'Search page loaded',
  SearchPageFirstResultsLoaded = 'Search page first results loaded',
  SearchRefined = 'Search refined',
  SignInModalShown = 'Sign-in modal shown',
  SignInModalDeclined = 'Sign-in modal declined',
  SignInStarted = 'Sign-in started',
  SignedIn = 'Signed in',
  SizesFilterCancelled = 'Sizes filter cancelled',
  SizesFilterChanged = 'Sizes filter changed',
  SizesFilterOpened = 'Sizes filter opened',
  SortByCancelled = 'Sort by cancelled',
  SortByChanged = 'Sort by changed',
  SortByOpened = 'Sort by opened',
  SubscribeSuccessPageLoaded = 'Subscribe success page loaded',
  SubscriptionCheckoutSignInPageLoaded = 'Subscription checkout sign-in page loaded',
  SubscriptionLandingPageLoaded = 'Subscription landing page loaded',
  SubscriptionLandingPageSubscribeClicked = 'Subscription landing page subscribe clicked',
  SubscriptionLandingPageAllWithOneVisible = 'Subscription landing page all with one section visible',
  SubscriptionLandingPageOrganizeYourAdventureVisible = 'Subscription landing page organize your adventure visible',
  SubscriptionLandingPageFAQVisible = 'Subscription landing page FAQ visible',
  SubscriptionLandingPageBottomCtaVisible = 'Subscription landing page bottom CTA visible',
  SubscriptionMapDownloaded = 'Subscription map downloaded',
  SubscriptionMapPageLoaded = 'Subscription map page loaded',
  SubscriptionPageCancelClicked = 'Subscription page cancel clicked',
  SubscriptionPageLoaded = 'Subscription page loaded',
  SubscriptionPageFetchedMore = 'Subscription page fetched more',
  SuggestedMapsSubscribeModalDeclined = 'Suggested maps subscribe modal declined',
  SuggestedMapsSubscribeModalShown = 'Suggested maps subscribe modal shown',
  SuggestedMapsSubscribeModalSubscribed = 'Suggested maps subscribe modal subscribed',

  TermsOfServicePageLoaded = 'Terms of service page loaded',

  UnfollowCreator = 'Creator unfollowed',
  UserJourneyInterviewCardDisplayed = 'User journey interview card displayed',

  VttFilterChanged = 'VTT filter changed',
}

export const PageLoadedEvents = [
  Event.BookmarksPageLoaded,

  Event.CheckoutPageLoaded,
  Event.CreatorDashboardMainPageLoaded,
  Event.CreatorDashboardMapsPageLoaded,
  Event.CreatorDashboardSalesPageLoaded,
  Event.CreatorPageLoaded,

  Event.DiscordBotPageLoaded,

  Event.ExplorePageLoaded,

  Event.FollowingPageLoaded,
  Event.FoundryModulePageLoaded,

  Event.MapPageLoaded,
  Event.MapSubmissionMetadataPageLoaded,
  Event.MapSubmissionPublishPageLoaded,
  Event.MapSubmissionUploadPageLoaded,
  Event.MapSubmissionVariationsPageLoaded,

  Event.OrderPageLoaded,

  Event.PatreonCreatorRewardsOnboardingPageLoaded,
  Event.PatreonRewardPageLoaded,
  Event.PatreonRewardsPageLoaded,
  Event.PrivacyPolicyPageLoaded,
  Event.PurchasedMapsPageLoaded,

  Event.ResubscribePageLoaded,

  Event.SearchPageLoaded,
  Event.SubscribeSuccessPageLoaded,
  Event.SubscriptionLandingPageLoaded,
  Event.SubscriptionMapPageLoaded,
  Event.SubscriptionPageLoaded,

  Event.TermsOfServicePageLoaded
]

export const ProductActionEvents = [
  // Product filter action
  Event.AccessTypeFilterCancelled,
  Event.AccessTypeFilterChanged,
  Event.AccessTypeFilterOpened,
  Event.BookmarkedFilterCancelled,
  Event.BookmarkedFilterChanged,
  Event.BookmarkedFilterOpened,

  // Product bookmark action
  Event.BookmarkToggled,
  Event.BookmarksPageLoaded,
  Event.BookmarksPageFetchedMore,

  // Product a-la-carte action
  Event.CheckoutAttempted,
  Event.CheckoutGuestEmailEntered,
  Event.CheckoutPageLoaded,
  Event.CheckoutSucceeded,
  Event.OrderPageLoaded,
  Event.PremiumMapAddedToCart,
  Event.PremiumMapDownloaded,
  Event.PurchasedMapsPageLoaded,

  // Product creator following action
  Event.CreatorEmailNotificationsToggled,
  Event.FollowingPageLoaded,
  Event.FollowCreator,
  Event.UnfollowCreator,

  // Product hosted maps action
  Event.DirectDownloadMapDownloaded,
  Event.DirectDownloadDialogOpened,

  // Product Discord action
  Event.DiscordBotPageLoaded,
  Event.DiscordBotPageInstallClicked,

  // Product sign-in action
  Event.SignInModalShown,
  Event.SignInModalDeclined,

  // Product subscription action
  Event.ResubscribePageLoaded,
  Event.ResubscribePageSubscribeClicked,
  Event.SubscribeSuccessPageLoaded,
  Event.SubscriptionLandingPageLoaded,
  Event.SubscriptionLandingPageSubscribeClicked,
  Event.SubscriptionLandingPageAllWithOneVisible,
  Event.SubscriptionLandingPageOrganizeYourAdventureVisible,
  Event.SubscriptionLandingPageBottomCtaVisible,
  Event.SubscriptionMapDownloaded,
  Event.SubscriptionMapPageLoaded,
  Event.SubscriptionPageCancelClicked,
  Event.SubscriptionPageLoaded,
  Event.SubscriptionPageFetchedMore,

  // Product email action
  Event.NewsletterUnsubscribed,
  Event.SuggestedMapsSubscribeModalDeclined,
  Event.SuggestedMapsSubscribeModalShown,
  Event.SuggestedMapsSubscribeModalSubscribed,

  // Product Patreon action
  Event.PatreonCreatorRewardsOnboardingPageLoaded,
  Event.PatreonRewardDownloaded,
  Event.PatreonRewardPageLoaded,
  Event.PatreonRewardsPageLoaded,

  // Misc
  Event.FoundryModulePageLoaded,
]

export default Event
