import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { BattleMap } from 'models'
import { Header as PageHeader } from 'components/page'

type Props = {
  battleMap: Pick<BattleMap, 'name' | 'creator'>
}

const Header = ({ battleMap }: Props) => {
  return (
    <>
      <PageHeader>
        Download &ldquo;{battleMap.name}&rdquo;
      </PageHeader>
      <Box sx={{ position: 'relative', marginBottom: '20px' }}>
        <Box>
          By <Link to={'#'}>{battleMap.creator.name}</Link>
        </Box>
      </Box>
    </>
  )
}

export default Header
