import React from 'react';
import { Box } from '@mui/material'
import { MapVariationForProduct } from 'models'
import { premiumWatermarkedPreviewUrl } from 'models/MapVariation'
import { borderRadius } from 'components/cards'
import { Download } from '@mui/icons-material'

type Props = {
  download: () => void
  mapVariation: MapVariationForProduct
  zoomIn: () => void
}

const MapVariationCard = (props: Props) => {
  const { download, mapVariation, zoomIn } = props
  const imgSrc = premiumWatermarkedPreviewUrl(mapVariation)

  return (
    <Box sx={{ width: { xs: '50%', sm: '33%', md: '20%' }, paddingRight: '16px' }}>
      <Box sx={{ marginBottom: '25px !important'}}>
        <Box
          component="img"
          sx={{
            width: '100%',
            display: 'block',
            borderRadius,
            cursor: 'zoom-in',
          }}
          src={imgSrc}
          onClick={zoomIn}
        />
        <Box
          sx={{
            position: 'relative',
            padding: '5px 30px 0 10px',
          }}
        >
          <Box
            component="span"
            sx={{
              cursor: 'zoom-in',
              display: 'inline-block',
              overflow: 'hidden',
              maxWidth: '95%'
            }}
            onClick={zoomIn}
          >
            {mapVariation.privateAsset.filename}
          </Box>
          <Download
            sx={{
              position: 'absolute',
              right: '5px',
              top: '5px',
              cursor: 'pointer'
            }}
            onClick={download}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default MapVariationCard
