import { useEffect } from 'react'

// TypeScript stuff is cribbed from
// https://github.com/juliencrn/usehooks-ts/blob/master/packages/usehooks-ts/src/useEventListener/useEventListener.ts

function useEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: (event: WindowEventMap[K]) => void,
): void

function useEventListener<KW extends keyof WindowEventMap>(
  eventName: KW,
  handler: (event: WindowEventMap[KW]) => void
): void {
  useEffect(() => {
    window.addEventListener(eventName, handler, false)
    return () => window.removeEventListener(eventName, handler, false)
  }, [eventName])
}

export { useEventListener }
