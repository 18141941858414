import React from 'react'
import { Link, LinkProps } from '@mui/material'
import { getRandomInt } from 'misc'

type Props = {
  href: string
  children: React.ReactNode
  newTab?: boolean
}

const ExternalLink = ({ href, children, newTab = false }: Props) => {
  const [linkId, setLinkId] = React.useState<string>()

  React.useEffect(() => {
    if (linkId) {
      window.tracker.trackLinks(`#${linkId}`, 'External link clicked', href)
    } else {
      setLinkId(`link-${getRandomInt(1000000)}`)
    }
  }, [linkId])

  if (!linkId) return null

  const linkProps: LinkProps = { id: linkId, href }
  if (newTab) linkProps.target = '_blank'

  return <Link {...linkProps}>{children}</Link>
}

export default ExternalLink
