import React from 'react'
import { useNavigate } from "react-router-dom";
import { FlashMessageContext } from 'providers/FlashMessageProvider';
import { setInfoMessageAction } from 'reducers/useFlashMessageReducer';

const EmailGuestConfirmedRedirect = () => {
  const { flashMessageDispatch } = React.useContext(FlashMessageContext)
  const navigate = useNavigate()

  React.useEffect(() => {
    flashMessageDispatch(
        setInfoMessageAction({ message: "Your subscription has been confirmed." })
    )
    navigate(`/`)
  }, [])

  return null;
}

export default EmailGuestConfirmedRedirect
