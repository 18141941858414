import React from 'react';
import { MapVariationCard as DownloadPageMapVariationCard } from 'components/mapDownloadPage'
import { MapVariationForProduct } from 'models'

type Props = {
  downloadMapVariation: (mv: MapVariationForProduct) => void
  mapVariation: MapVariationForProduct
  zoomIn: () => void
}

const MapVariationCard = (props: Props) => {
  const { downloadMapVariation, mapVariation, zoomIn } = props

  return(
    <DownloadPageMapVariationCard
      download={() => downloadMapVariation(mapVariation)}
      mapVariation={mapVariation}
      zoomIn={zoomIn}
    />
  )
}

export default MapVariationCard
