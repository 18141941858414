import { ReactEventHandler } from 'react'
import {
  createTheme as muiCreateThemeV5,
} from '@mui/material/styles';
import { fontFamily } from './fontFamily';
import { colors } from './colors'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true,
    sm: true,
    md: true,
    lg: true,
    xl: true,
    xxl: true,
    xxxl: true,
    xxxxl: true,
  }

  interface Palette {
    gray: Palette['primary'];
    offWhitePrimary: Palette['primary'];
  }

  interface PaletteOptions {
    gray: PaletteOptions['primary'];
    offWhitePrimary: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    gray: true;
    offWhitePrimary: true;
  }
}


// The following is included to prevent TS errors when not adding nonce, onResize, and onResizeCapture
// to the Popper component (see https://github.com/mui/material-ui/issues/35287#issuecomment-1337250566).
// Hopefully we can remove this as soon as this is a fix, or if we upgrade to React 18 
declare global {
  namespace React {
    interface DOMAttributes<T> {
      onResize?: ReactEventHandler<T> | undefined;
      onResizeCapture?: ReactEventHandler<T> | undefined;
      nonce?: string | undefined;
    }
  }
}

const baseTheme = muiCreateThemeV5({})

export const createThemeV5 = () => muiCreateThemeV5({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
      xxxl: 2560,
      xxxxl: 3100,
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily,
        },
        containedSecondary: {
          color: 'rgba(0, 0, 0, 0.87)',
          backgroundColor: '#e0e0e0',
          '&:hover, &:focus': {
            backgroundColor: '#e0e0e0',
          }
        }
      },
    },
    MuiInputBase: { styleOverrides: { input: { fontFamily } } },
    MuiInputLabel: { styleOverrides: { root: { fontSize: '0.9em', fontWeight: 'bold' } } },
    MuiFormControlLabel: { styleOverrides: { label: { fontFamily } } },
    MuiDialog: {
      styleOverrides: {
        paper: baseTheme.unstable_sx({
          borderRadius: { md: '20px' }, padding: { md: '10px 15px 15px 10px' }
        })
      }
    }
  },
  typography: { fontFamily },
  palette: {
    primary: {
      main: '#3f51b5',
    },
    gray: {
      main: '#E0E0E0',
      dark: '#D5D5D5'
    },
    offWhitePrimary: {
      main: colors.offWhiteBackground,
      dark: '#ebebeb'
    }
  }
});

