import React from 'react';
import { Event } from 'Tracker'
import { useRouteCanShowEmailSubscriptionDialog } from '../misc';
import Dialog from './Dialog'
import Snackbar from './Snackbar'
import { visitorCanBePromptedToSubscribe } from 'suggestedMaps'

function NotLoggedInSubscribeDialog() {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false)
  const routeCanShowEmailSubscriptionDialog = useRouteCanShowEmailSubscriptionDialog()

  const openDialog = () => {
    setDialogOpen(true)
    window.tracker.track(Event.SuggestedMapsSubscribeModalShown)
  }

  React.useEffect(() => {
    if (routeCanShowEmailSubscriptionDialog && visitorCanBePromptedToSubscribe()) {
      const subscriptionId = window.tracker.subscribe(
        Event.ExternalMapPageOpened, openDialog
      )
      return () => window.tracker.unsubscribe(subscriptionId)
    }
  }, [routeCanShowEmailSubscriptionDialog])

  return (
    <>
      <Dialog
        setSnackbarOpen={setSnackbarOpen}
        setDialogOpen={setDialogOpen}
        dialogOpen={dialogOpen}
      />
      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
      />
    </>
  )
}

export default NotLoggedInSubscribeDialog
