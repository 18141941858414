import Cookies from 'js-cookie';
import { CookieName } from 'storage'
import { Event } from 'Tracker'

const timestampLimit = 5

export const appendTimestamp = () => {
  let trailingKeyActions: number[]
  const cookieString = Cookies.get(CookieName.TrailingKeyActions)
  if (cookieString) {
    trailingKeyActions = JSON.parse(cookieString)
  } else {
    trailingKeyActions = []
  }
  trailingKeyActions.push(Date.now())
  if (trailingKeyActions.length > timestampLimit) {
    trailingKeyActions.splice(0, trailingKeyActions.length - timestampLimit)
  }
  Cookies.set(CookieName.TrailingKeyActions, JSON.stringify(trailingKeyActions))
}

export const subscribe = () => {
  const keyActions = [
    Event.ExternalMapPageOpened,
    Event.DirectDownloadMapDownloaded,
    Event.PremiumMapDownloaded,
    Event.PatreonRewardDownloaded,
    Event.SubscriptionMapDownloaded
  ]
  keyActions.forEach(keyAction => window.tracker.subscribe(keyAction, appendTimestamp))
}
