import { useReducer } from 'react';

enum actionType {
  SET_MESSAGE_ACTION = 'set_message',
  CLEAR_MESSAGE_ACTION = 'clear_message'
}

const AUTO_HIDE_DURATION_DEFAULT = 6_000

export const ERROR_STATUS = 'error'
export const INFO_STATUS = 'info'

export const setErrorMessageAction = ({ message, autoHideDuration = AUTO_HIDE_DURATION_DEFAULT }) => ({
  type: actionType.SET_MESSAGE_ACTION,
  status: ERROR_STATUS,
  autoHideDuration,
  message
})

export const setInfoMessageAction = ({ message, autoHideDuration = AUTO_HIDE_DURATION_DEFAULT }) => ({
  type: actionType.SET_MESSAGE_ACTION,
  status: INFO_STATUS,
  autoHideDuration,
  message
})

export const clearMessageAction = () => ({
  type: actionType.CLEAR_MESSAGE_ACTION
})

export interface FlashMessageAction {
  type: actionType,
  message?: string,
  status?: string
  autoHideDuration?: number
}

export interface FlashMessageState {
  open: boolean,
  message: string | undefined
  status: string | undefined
  autoHideDuration: number | undefined
}

const useFlashMessageReducer = () => {
  const reducer = (_state: FlashMessageState, action: FlashMessageAction): FlashMessageState => {
    switch (action.type) {
      case actionType.SET_MESSAGE_ACTION:
        return {
          open: true,
          status: action.status,
          message: action.message,
          autoHideDuration: action.autoHideDuration
        }
      case actionType.CLEAR_MESSAGE_ACTION:
        return {
          open: false,
          status: undefined,
          message: action.message,
          autoHideDuration: undefined
        }
      default:
        throw new Error()
    }
  }

  const initialState = { open: false, message: "", status: undefined, autoHideDuration: undefined }

  return useReducer(reducer, initialState)
}

export default useFlashMessageReducer
