import React from 'react';
import MultiVariationPage from './MultiVariationPage'
import { Product } from 'models'
import { ReverseNavigationPrompt } from 'components/navigation'
import { Routes } from 'routing/deprecated';
import { AppLoaderContext } from 'AppLoader'

type Props = {
  product: Product
}

const Page = ({ product }: Props) => {
  const { session } = React.useContext(AppLoaderContext)

  if (!session || !session.user?.subscription) return null;
  const subscriptionTier = session.user.subscription.subscriptionTier

  return (
    <>
      <ReverseNavigationPrompt linkTo={Routes.subscriptionPath()}>
        Lost Atlas {subscriptionTier.name}
      </ReverseNavigationPrompt>
      <MultiVariationPage product={product} />
    </>
  )
}

export default Page
