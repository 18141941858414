import React from 'react';
import { Button } from '@mui/material'
import BookmarkToggle, { BookmarkedProps, NotBookmarkedProps } from 'components/BookmarkToggle'
import { BookmarkBorder, Bookmark } from '@mui/icons-material';

const BookmarkTogglePresentation = {
  LoadPending: () => (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<BookmarkBorder color="disabled" />}
      sx={{ marginRight: '10px', marginBottom: '7px' }}
    >
        Bookmark
    </Button>
  ),

  Bookmarked: ({ onClick }: BookmarkedProps) => (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<Bookmark />}
      onClick={onClick}
      sx={{ marginRight: '10px', marginBottom: '7px' }}
    >
        Unbookmark
    </Button>
  ),

  NotBookmarked: ({ onClick }: NotBookmarkedProps) => (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<BookmarkBorder />}
      onClick={onClick}
      sx={{ marginRight: '10px', marginBottom: '7px' }}
    >
        Bookmark
    </Button>
  ),

  LoginNeeded: () => (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<BookmarkBorder />}
      sx={{ marginRight: '10px', marginBottom: '7px' }}
    >
        Bookmark
    </Button>
  )
}

type Props = {
  mapId: string
}

function BookmarkToggleTextButton({ mapId }: Props) {
  return (
      <BookmarkToggle
        mapId={mapId}
        presentation={BookmarkTogglePresentation}
      />
  )
}

export default BookmarkToggleTextButton
