import React, { useContext, useEffect } from 'react';
import { Event, Feature, TrackerContext } from 'Tracker';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material'
import { navigateToAuth0 } from 'auth0'
import Cookies from 'js-cookie'
import { CookieName } from 'storage'

type Props = {
  feature?: Feature
  postSignInAction?: string
  open: boolean
  onClose: () => void
}

const SignInWithAuth0Dialog = (props: Props) => {
  const { feature, postSignInAction, open, onClose } = props
  const { mixpanelProperties } = useContext(TrackerContext)

  useEffect(() => {
    if (open) {
      const mp = { ...mixpanelProperties }
      if (feature) mp.feature = feature
      const opts = { mixpanelProperties: mp }
      window.tracker.track(Event.SignInStarted, opts)
      window.tracker.track(Event.SignInModalShown, opts);
    }
  }, [open])

  const signInClicked = () => {
    if (postSignInAction) {
      Cookies.set(CookieName.PostSignInAction, postSignInAction)
    }
    navigateToAuth0()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          That feature requires you to sign in.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          No thanks
        </Button>
        <Button onClick={signInClicked}>
          Sign in
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SignInWithAuth0Dialog
