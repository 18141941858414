import { useContext, useState, useEffect } from 'react'
import { Subscription, deserializeSubscription } from 'models'
import ApiClient from 'ApiClient'
import { AppLoaderContext } from 'AppLoader'

type UseSubscriptionValue = {
  subscription: Subscription | null
  loading: boolean
}

const useSubscription = (): UseSubscriptionValue => {
  const { session } = useContext(AppLoaderContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [subscription, setSubscription] = useState<Subscription | null>(null)

  useEffect(() => {
    if (session) {
      if (session.user && !subscription) {
        ApiClient
          .getCamelcased(`/users/subscription`)
          .then(resp => {
            const subscription = deserializeSubscription(resp.data)
            setSubscription(subscription)
          })
          .catch(() => {})
          .finally(() => setLoading(false))
      } else if (!session.user) {
        setLoading(false)
      }
    }
  }, [session, subscription])

  return { subscription, loading }
}

export default useSubscription
