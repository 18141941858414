import React, { useContext } from 'react';
import { Box, Icon } from '@mui/material'
import { Paths } from 'routing'
import { discordLink } from 'discord'
import { AppLoaderContext } from 'AppLoader'

const discordIcon = require('./discord-mark-black.svg').default as string;

export type MiscMenuItem = {
  name: string;
  icon: React.ReactElement;
  href: string;
}

export const useMiscMenuItems = (): MiscMenuItem[] => {
  const { session } = useContext(AppLoaderContext)

  const result = [
    { name: 'Search', icon: <Icon>search</Icon>, href: Paths.searchPath() },
    {
      name: 'Discord',
      icon: (
        <Box
          component="img"
          sx={{ width: '24px', opacity: 0.54 }}
          src={discordIcon}
        />
      ),
      href: discordLink,
    }
  ]
  if (!session?.user?.subscription) {
    result.push({
      name: 'Subscribe',
      icon: <Icon>map</Icon>,
      href: Paths.subscriptionLandingPagePath()
    })
  }
  result.push(
    { name: 'FAQ', icon: <Icon>help</Icon>, href: Paths.faqPagePath() },
    { name: 'Contact Us', icon: <Icon>email</Icon>, href: Paths.contactUsPagePath() },
    { name: 'Terms of Service', icon: <Icon>article</Icon>, href: Paths.termsOfServicePath() },
    { name: 'Privacy Policy', icon: <Icon>article</Icon>, href: Paths.privacyPolicyPath() },
  )

  return result;
}
