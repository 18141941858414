import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { FlashMessageContext } from 'providers/FlashMessageProvider';
import { setErrorMessageAction } from 'reducers/useFlashMessageReducer';

import ApiClient from 'ApiClient'

function CreatorOnboardRedirect() {
  const { token } = useParams()

  const navigate = useNavigate();

  const { flashMessageDispatch } = React.useContext(FlashMessageContext)

  React.useEffect(() => {
    if (token) {
      ApiClient
        .postRaw('/stripe/account_links', {t: token})
        .then((resp) => {
          const accountLink = resp.data
          window.location.replace(accountLink.url)
        })
        .catch((resp) => {
          const { message } = resp.response.data
          if (message) {
            flashMessageDispatch(setErrorMessageAction({ message }))
          }
          navigate("/")
        })
    }
  }, [token, flashMessageDispatch, navigate]);

  return null
}

export default CreatorOnboardRedirect;
