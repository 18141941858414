import React from 'react';
import AppBar from './AppBar';
import LeftNavDrawer from './LeftNavDrawer'
import { DarkContentContainer, LightContentContainer, BackgroundColorValue } from '../backgroundColor'
import Padding, { PaddingValue } from '../Padding'
import { Container } from 'layouts/LayoutBase'
import { LayoutContext } from './context'

export { default as AppBar } from './AppBar'
export { LayoutContext } from './context'
export { default as  LeftNavDrawer } from './LeftNavDrawer'

type Props = {
  children: React.ReactNode,
  backgroundColor?: BackgroundColorValue
  padding?: PaddingValue
  appBarRef?: React.RefCallback<HTMLElement>
}

function Layout(props: Props) {
  const {
    appBarRef,
    children,
    backgroundColor = 'light',
    padding = 'none'
  } = props
  const [navOpen, setNavOpen] = React.useState<boolean>(false)

  const content = (padding: PaddingValue) => {
    let c = children
    c = <Padding padding={padding}>{c}</Padding>
    if (backgroundColor === 'dark') {
      c = <DarkContentContainer>{c}</DarkContentContainer>
    } else if (backgroundColor === 'light') {
      c = <LightContentContainer>{c}</LightContentContainer>
    }
    return c
  }

  const contextValue = { navOpen, setNavOpen }

  return (
    <LayoutContext.Provider value={contextValue}>
      <Container>
        <AppBar position='static' ref={appBarRef} />
        <LeftNavDrawer />
        {content(padding)}
      </Container>
    </LayoutContext.Provider>
  )
}

export default Layout;
