import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Dimensions } from 'models'
import { useIsMounted } from './useIsMounted'

export { useEventListener } from './useEventListener'
export { useIsMounted }

export function useElementClientDimensions<T extends HTMLElement = HTMLDivElement>(): [
  React.RefCallback<T>, Dimensions | null, () => void, React.MutableRefObject<T | undefined>
] {
  const elementRef = useRef<T>()
  const [dimensions, setDimensions] = useState<Dimensions | null>(null)
  const isMounted = useIsMounted()

  const refreshDimensions = () => {
    // Check dimensions with a timeout, since you want all these dimension
    // queries to happen after the DOM is fully updated
    setTimeout(() => {
      if (elementRef.current && isMounted()) {
        setDimensions({
          width: elementRef.current.clientWidth,
          height: elementRef.current.clientHeight
        })
      }
    }, 0)
  }

  useEffect(refreshDimensions, [isMounted])

  // callbackRef is called every time it is attached or re-attached to a DOM node
  const callbackRef = useCallback((node: T) => {
    if (elementRef.current) {
      // The callback ref is being re-attached to a new node, do cleanup here
      window.removeEventListener('resize', refreshDimensions)
    }

    if (node) {
      // Manually set elementRef.current, you'll need it inside your resize
      // detection
      elementRef.current = node
      refreshDimensions()
      window.addEventListener('resize', refreshDimensions)
    }
  }, [isMounted])

  return [callbackRef, dimensions, refreshDimensions, elementRef]
}
