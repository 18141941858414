import React, { useContext, useEffect } from 'react';
import { Event } from 'Tracker'
import { useLocation } from "react-router-dom";
import { AppLoaderContext, AppLoaderContextValue } from 'AppLoader'
import { DocumentContext, DocumentValue } from 'providers/DocumentProvider'
import { useRedirectToSignIn } from 'redirects'

type WithPageWrapperOpts = {
  setCanonicalUrl?: boolean
  signInRequired?: boolean
}

export const withPageWrapper = (Component: React.ComponentType, pageLoadEvent: Event, opts: WithPageWrapperOpts = {}) => {
  const { setCanonicalUrl = false, signInRequired = false } = opts

  const PageWrapper = () => {
    const { session } = useContext(AppLoaderContext) as AppLoaderContextValue
    const { setCanonicalAbsolutePath } = useContext(DocumentContext) as DocumentValue
    const location = useLocation()
    const redirectToSignIn = useRedirectToSignIn()

    useEffect(() => {
      window.tracker.track(pageLoadEvent)
    }, [])

    useEffect(() => {
      if (session) {
        if (signInRequired && !session.user) { redirectToSignIn() }
      }
    }, [session])

    useEffect(() => {
      if (setCanonicalUrl) {
        setCanonicalAbsolutePath(location.pathname)
        return () => setCanonicalAbsolutePath(null)
      }
    }, [location])

    if (signInRequired && (!session || !session.user)) return null;

    return <Component />
  }
  PageWrapper.displayName = `WithPage(${Component.displayName || Component.name || 'Component'})`;
  return PageWrapper
}
