import React from 'react';
import { Box, SxProps } from '@mui/material'
import { Search as MuiSearch } from '@mui/icons-material'
import { Routes } from 'routing/deprecated'
import { Link } from 'react-router-dom'

type Props = {
  fontSize?: 'medium' | 'large'
  sx?: SxProps
}

const Search = ({ fontSize = 'medium', sx = {} }: Props) => (
  <Box sx={sx}>
    <Link className='mobile-search' to={Routes.searchPath()}>
      <MuiSearch fontSize={fontSize} />
    </Link>
  </Box>
)

export default Search;
