import { FacetFilterIdentifier } from "search";
import Event, { PageLoadedEvents, ProductActionEvents } from './event'
import { AccessType, MapVariation} from 'models'

export type MixpanelSourceComponent = 'Map card' | 'Map zoom'
export type MixpanelSourcePage = 'Map page' | 'Subscription Landing Page'
export type MixpanelSourcePageSection = 'Similar maps'
export type Feature =
  'Bookmark map' |
  'Filter maps by bookmarked' |
  'Follow creator' |
  'Report map' |
  'Subscribe' |
  'Download hosted map' |
  'Set VTT download target'

export interface MixpanelProperties {
  access_type?: AccessType

  direct_download?: boolean;

  facet_entry_id?: string;
  facet_identifier?: FacetFilterIdentifier;
  feature?: Feature;
  filter?: FacetFilterIdentifier;
  follower?: boolean;
  followed_creator_ids?: number[]

  guest?: boolean;

  maps_count?: number;
  map_id?: string;
  map_variation_id?: MapVariation['id'];

  page_loaded?: boolean;
  premium?: boolean;
  product_action?: boolean;
  products_percent?: number;

  query?: string;

  refined?: boolean;

  search_query?: string;
  sort_by?: 'trending' | 'newest' | 'most_popular';
  source_component?: MixpanelSourceComponent
  source_page?: MixpanelSourcePage
  source_page_section?: MixpanelSourcePageSection

  // this type refers to chip filters on the explore page
  type?: 'trending' | 'facet' | 'new' | 'premium';

  variation_count?: number;
  value?: string;
}

export const defaultMixpanelProperties = (event: Event): MixpanelProperties => {
  const result: MixpanelProperties = {}
  if (PageLoadedEvents.includes(event)) result.page_loaded = true
  if (ProductActionEvents.includes(event)) result.product_action = true
  return result
}
