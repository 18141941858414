import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

import UnfollowModal from './UnfollowModal';
import FollowshipDropdown from './FollowshipDropdown';
import { Followship } from 'models';
import FollowToggleButton from './FollowToggleButton';

const FollowingMenuContainer = styled('div')({
  display: 'flex'
})

const DropdownButton = styled(Button)({
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  minWidth: 0,
  width: '10px'
})

interface Props {
  followship?: Followship,
  creatorName: string,
  creatorId: number,
  handleUnfollow: () => void
}

const FollowshipMenu = ({ followship, creatorName, creatorId, handleUnfollow }: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [unfollowDialogOpen, setUnfollowDialogOpen] = useState(false)
  const anchorRef = React.useRef<HTMLButtonElement | null>(null);
  const toggleDropdown = React.useCallback(() => { setDropdownOpen(!dropdownOpen) }, [dropdownOpen])
  const closeDropdown = React.useCallback(() => { setDropdownOpen(false) }, [])

  return (
    <FollowingMenuContainer onClick={(e) => e.stopPropagation()}>
      <FollowToggleButton
        following={!!followship}
        openUnfollowDialog={() => { setUnfollowDialogOpen(true) }}
        creatorId={creatorId}
      />
      { followship &&
        <React.Fragment>
          <DropdownButton
            ref={anchorRef}
            variant="contained"
            color="gray"
            aria-haspopup="true"
            aria-expanded={dropdownOpen ? 'true' : undefined}
            onClick={toggleDropdown}
            data-testid="followship-menu-toggle"
          >
            <MoreVert />
          </DropdownButton> 
          <FollowshipDropdown
            ref={anchorRef}
            closeDropdown={closeDropdown}
            creatorName={creatorName}
            followship={followship}
            open={dropdownOpen}
          />
        </React.Fragment>
      }
      <UnfollowModal
        open={unfollowDialogOpen}
        closeDialog={() => setUnfollowDialogOpen(false)}
        creatorName={creatorName}
        handleUnfollow={handleUnfollow}
      />
    </FollowingMenuContainer>
  )
}

export default FollowshipMenu;
