export enum DeprecatedCookieName {
  BrowserId = 'bid',
  MapCardV3Preview = 'mc3p',
  MapPackFake = 'mpf',
  MapPackFakeEnvironment = 'mpfe',
  MapPackFakeClicked = 'mpfc',
  NewUserAlertStatus = 'nuas',
  RedirectPath = 'redirect_href',
  HostedMapsPaywall = 'hmp',
  NpsSurveyShown = 'nps',
  TrackWhenSignedIn = 'twsi',
  UnrefinedPremiumBoost = 'upb',
}

export enum CookieName {
  AnonymousHostedDownloads = 'ahd',
  CsrfToken = 'CSRF-TOKEN',
  DirectDownloadSubscribeClicked = 'ddsc',
  DiscordServerBottomAlert = 'dsba',
  NotLoggedInSubscribeDialog = 'snlisd1',
  OwlbearRodeoDevelopment = 'obrd',
  PostSignInAction = 'psia',
  PrivateAuth0Test = 'pat',
  SplitTests = 'st',
  TrailingKeyActions = 'tka',
  VttDownloadTarget = 'vdt'
}
