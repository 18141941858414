import React from 'react';
import { styled } from '@mui/material/styles';
import { Search } from '@mui/icons-material'
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

const NoFollowersWarningContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  color: 'white',
  fontSize: '1.5em',
  margin: '2vh 2vw',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2em',
    display: 'block'
  }
}))

const SearchButton = styled('div')(({ theme }) => ({
  marginLeft: '20px',
  [theme.breakpoints.down('sm')]: {
    margin: '1vh 0vw',
    display: 'block'
  }
}))

const NoFollowersWarning = () => {
  const navigate = useNavigate()

  return (
    <NoFollowersWarningContainer>
      <div>
        Looks like you aren’t following any creators yet.
      </div>
      <SearchButton>
        <Button
          variant="contained"
          startIcon={<Search />}
          onClick={() => navigate('/')}
        >
          Search maps
        </Button>
      </SearchButton>
    </NoFollowersWarningContainer>
  )
}

export default NoFollowersWarning;
