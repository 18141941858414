export const isElementInViewport = (id: string): boolean => {
  const elt = document.getElementById(id);
  if (!elt) return false;

  const rect = elt.getBoundingClientRect();

  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export const openUrlInNewWindow = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  // This is a security thing -- see https://stackoverflow.com/questions/40593632/use-window-open-but-block-use-of-window-opener
  if (newWindow) newWindow.opener = null
}
