import React from 'react';

import { TextField as MuiTextField, SxProps } from '@mui/material';
import { styled } from '@mui/material/styles'

const StyledTextField = styled(MuiTextField)({
  width: '100%',
  '& .MuiInputBase-input': { boxSizing: 'border-box', padding: '22px 14px' }
})

type Props = {
  id: string,
  formik: { getFieldProps: (fieldId: string) => {} },
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  type?: string,
  sx?: SxProps
}

const TextField = (props: Props) => {
  const { formik, onBlur, ...muiTextFieldProps } = props;

  return (
    <StyledTextField
      variant="outlined"
      {...muiTextFieldProps}
      {...formik.getFieldProps(props.id)}
      onBlur={onBlur}
    />
  )
}

export default TextField
