import React from 'react';
import { FlashMessageContext } from 'providers/FlashMessageProvider';
import { setInfoMessageAction } from 'reducers/useFlashMessageReducer';
import { useSearchParams, useNavigate } from "react-router-dom";

const FlashMessageRedirect = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { flashMessageDispatch } = React.useContext(FlashMessageContext) as { flashMessageDispatch }

  React.useEffect(() => {
    const message = searchParams.get('m')
    flashMessageDispatch(setInfoMessageAction({ message }))
    navigate('/')
  }, [])

  return null
}

export default FlashMessageRedirect
