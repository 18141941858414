import React from 'react'
import { Event, MixpanelProperties } from 'Tracker'
import { VttDownloadTarget } from 'models/User'

export type CreateDownloadUrlResponse = {
  url: string,
  follower: boolean,
  vttDownloadTarget?: VttDownloadTarget
}
type CreateDownloadUrlFn = () => Promise<CreateDownloadUrlResponse>

type DownloadEvent =
  Event.DirectDownloadMapDownloaded |
  Event.PatreonRewardDownloaded |
  Event.PremiumMapDownloaded |
  Event.SubscriptionMapDownloaded

export type DownloadPrivateAssetFn = (
  createDownloadUrl: CreateDownloadUrlFn,
  downloadEvent: DownloadEvent,
  mapId: string,
  properties?: MixpanelProperties
) => Promise<string>

type PrivateAssetDownloadContextValue = {
  downloadPrivateAsset: DownloadPrivateAssetFn
}

// Is there a non-annoying way for TypeScript to allow a context to have an
// initial value, that we know will never be used, without requiring undefined
// checks everywhere? I don't know, but in the meantime this function, which
// returns a blank string, satisfies TypeScript but will never be used by any
// application code.
const PrivateAssetDownloadContext = React.createContext<PrivateAssetDownloadContextValue>({
  downloadPrivateAsset: (
    downloadUrl: CreateDownloadUrlFn,
    downloadEvent: Event,
    mapId: string
  ) => (Promise.resolve(""))
})

export default PrivateAssetDownloadContext
