import React from 'react';
import { SplitTestParticipations } from 'splitTests'
import { DeviceType } from 'responsive'

export interface AppContextValue {
  deviceType: DeviceType | null
  firstVisit: boolean
  initialLocationHref: string
  splitTestParticipations: SplitTestParticipations
}

type InitialProps = {
  deviceType: DeviceType | null
  firstVisit: boolean
  splitTestParticipations: SplitTestParticipations
}

export const initialAppContextValue = (initialProps: InitialProps): AppContextValue => ({
  ...initialProps,
  initialLocationHref: window.location.href,
})

const unusedDefaultValue = {
  deviceType: null,
  firstVisit: true,
  initialLocationHref: 'http://example.com/',
  splitTestParticipations: new SplitTestParticipations()
}

// For global state that doesn't need any server-loading to be provided to the
// rest of the app. (Server-dependent state is handled in AppLoader.)
const AppContext = React.createContext<AppContextValue>(unusedDefaultValue)

export default AppContext
