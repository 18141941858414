import React from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles';
import { BrowseableBattleMap } from 'models'
import { ZoomInFn } from 'components/MapCardCollectionBase'

export * from './hover'
export { default as Image } from './Image'
export { default as RotatingVariationImage } from './RotatingVariationImage'

export const ImageContainer = styled('div')({
  position: 'relative'
})

type CaptionProps = {
  children: React.ReactNode
  color?: string
}

export const Caption = ({ children, color = 'white' }: CaptionProps) => {
  return (
    <Box
      sx={{
        color,
        padding: '5px 10px 0 10px',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '3',
        overflow: 'hidden',
      }}
    >
      {children}
    </Box>
  )
}

export type Props = {
  battleMap: BrowseableBattleMap,
  zoomIn: ZoomInFn
}
