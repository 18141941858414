import React from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'

type Props = {
  to: string | ((params: any) => string)
}

const Redirect = ({ to }: Props) => {
  const params = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  React.useEffect(() => {
    let destination: string
    if (typeof to === 'string') {
      destination = to
    } else {
      destination = to(params)
    }
    if (searchParams.toString() !== "") {
      destination = `${destination}?${searchParams.toString()}`
    }
    navigate(destination)
  }, [])

  return null
}

export default Redirect
