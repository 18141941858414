import React from 'react';
import { Catalog, Session } from 'models'
import { ShoppingCartAction } from 'reducers/useShoppingCartReducer';

export type Bookmarks = any;

export interface AppLoaderContextValue {
  bookmarks: Bookmarks
  bookmarksPending: boolean
  catalog?: Catalog
  setBookmarks: (bookmarks: Bookmarks) => void
  purchasedMapIds: string[]
  session?: Session,
  refreshSession: () => Promise<Session>,
  shoppingCartDispatch: React.Dispatch<ShoppingCartAction>;
}

export const AppLoaderContext = React.createContext<AppLoaderContextValue>({
  bookmarks: {},
  bookmarksPending: true,
  setBookmarks: (bm: Bookmarks) => {},
  purchasedMapIds: [],
  refreshSession: () => Promise.resolve({}),
  shoppingCartDispatch: () => {}
})
