import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, InputLabel } from '@mui/material'
import ErrorMessage from './ErrorMessage'
import InputNote from './InputNote'

const Main = styled(Box)({
  position: 'relative',
  marginBottom: '30px',
  paddingBottom: '15px',
})

type Props = {
  children: React.ReactNode,
  label: string,
  labelExplanation?: string,
  labelRequired?: boolean,
  name: string,
  note?: React.ReactNode
}

// Wrap an input in all the adorning text we might use: A label, a label
// explanation, an input note, and an error message.
function InputWrapper(props: Props) {
  const {
    children,
    label,
    labelExplanation,
    labelRequired,
    name,
    note
  } = props;

  return (
    <Main>
      {label && (
        <InputLabel
          sx={{ display: 'inline-block', marginBottom: '8px' }}
          htmlFor={name}
          required={labelRequired}
        >
          {label}
        </InputLabel>
      )}
      {labelExplanation && (
        <Box sx={{
          display: 'inline-block',
          fontSize: '1rem',
          fontWeight: 400,
          color: 'rgba(0, 0, 0, 0.54)',
          position: 'absolute'
        }}>
          &mdash; {labelExplanation}
        </Box>
      )}
      {children}
      {note && <InputNote>{note}</InputNote>}
      <ErrorMessage name={name} />
    </Main>
  )
}

export default InputWrapper
