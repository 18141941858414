export const defaultPageSize = 50;

export enum FacetFilterIdentifier {
  AccessType = 'access_types',
  Animated = 'animated',
  Bookmarked = 'bookmarked',
  New = 'new',
  Creator = 'creators',
  Environment = 'environments',
  Size = 'sizes',
  Vtt = 'vtt'
}

export type SingleSelectFacetFilterIdentifier =
  FacetFilterIdentifier.AccessType |
  FacetFilterIdentifier.Animated |
  FacetFilterIdentifier.Bookmarked |
  FacetFilterIdentifier.New |
  FacetFilterIdentifier.Vtt

export type MultiSelectFacetFilterIdentifier =
  FacetFilterIdentifier.Creator |
  FacetFilterIdentifier.Environment |
  FacetFilterIdentifier.Size

export const NoneFilterValue = 'none'

export type SortByOptionValue = 'trending' | 'newest' | 'most_popular'

export const DefaultSortByOptionValue = 'trending' as SortByOptionValue

export type SortByOption = {
  value: SortByOptionValue,
  name: string
}

export const DefaultSortByOption = {
  name: 'Trending', value: DefaultSortByOptionValue
} as SortByOption

export const SortByOptions: SortByOption[] = [
  DefaultSortByOption,
  { name: 'Newest', value: 'newest' },
  { name: 'Most popular', value: 'most_popular' },
]

type AccessTypeFilterValue = 'all' | 'premium' | 'direct_download'
export type AccessTypeOption = {
  value: AccessTypeFilterValue
  name: string
}
export const DefaultAccessTypeOptionValue = 'all'
export const DefaultAccessTypeOption = {
  name: 'All maps', value: DefaultAccessTypeOptionValue
} as AccessTypeOption

export const AccessTypeOptions: AccessTypeOption[] = [
  DefaultAccessTypeOption,
  { name: 'Premium maps', value: 'premium' },
  { name: 'Hosted maps', value: 'direct_download' },
]

// This should be the same as SEARCH_PARAMS_KEYS in Rails
export const searchParamsKeys = [
  'q', 'new', 'environments', 'sizes', 'creators', 'animated', 'bookmarked',
  'sort', 'access_types', 'vtt'
]

