import React from 'react';
import { Box } from '@mui/material'
import { BrowseableBattleMap } from 'models'
import { aspectRatio } from 'models/BattleMap'
import { colors , zIndexes } from 'theme'
import { Actions } from 'components/MapCardBase'
import { borderRadius } from 'components/cards'

type Props = {
  battleMap: BrowseableBattleMap,
  cursor?: string
}

const wideLayoutAspectRatio = 2.5;

const Hover = ({ battleMap, cursor }: Props) => {
  const usingWideLayout = aspectRatio(battleMap) >= wideLayoutAspectRatio

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderRadius,
        zIndex: zIndexes.mapCardImage + 1,
        cursor
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          background: '#333',
          opacity: '66%',
          width: '100%',
          height: '100%',
          borderRadius
        }}
      />
      {usingWideLayout && (
        <>
          <Box
            sx={{
              color: colors.orange,
              fontWeight: 'bold',
              position: 'absolute',
              bottom: '10px',
              padding: '0 10px',
              width: '66%',
            }}
          >
            {battleMap.creator.name}
          </Box>
          <Actions battleMap={battleMap} />
        </>
      )}
      {!usingWideLayout && (
        <>
          <Actions battleMap={battleMap} />
          <Box
            sx={{
              color: colors.orange,
              fontWeight: 'bold',
              position: 'absolute',
              bottom: '10px',
              padding: '0 10px',
            }}
          >
            {battleMap.creator.name}
          </Box>
        </>
      )}
    </Box>
  )
}

export default Hover;
