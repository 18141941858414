import React from 'react'
import { useSearchParams, useNavigate } from "react-router-dom";
import { CircularProgress } from '@mui/material';
import * as Sentry from "@sentry/react";
import { styled } from '@mui/material/styles';
import { Layout } from 'layouts';

import ApiClient from 'ApiClient'
import { Event } from 'Tracker';
import { Order } from 'models';

const SpinnerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center'
})

// After a successful purchase, Stripe redirects here with a payment intent ID.
// OrderThanksRedirect uses that to find an order's ID to redirect to the order
// page.
function OrderThanksRedirect() {
  const [searchParams] = useSearchParams()
  const [order, setOrder] = React.useState<Order>()
  const navigate = useNavigate()

  React.useEffect(() => {
    const redirectStatus = searchParams.get('redirect_status')
    if (redirectStatus === 'succeeded') {
      window.tracker.track(Event.CheckoutSucceeded)
    } else {
      Sentry.captureMessage(
        `Rendered order-thanks page with redirect_status '${redirectStatus}'`
      )
    }

    const paymentIntentId = searchParams.get('payment_intent')

    const timer = setInterval(() => {
      ApiClient
        .getCamelcased(`/orders?stripe_payment_intent_id=${paymentIntentId}`)
        .then((resp) => {
          if (resp.data.length > 0) {
            setOrder(resp.data[0])
          }
        });
    }, 100);

    return () => clearInterval(timer)
  }, [searchParams])

  if (order) {
    navigate(`/o/${order.publicId}`, { state: { justOrdered: true } })
  }

  return (
    <Layout padding='medium'>
      <SpinnerContainer>
        <CircularProgress color="primary" />
      </SpinnerContainer>
    </Layout>
  );
}

export default OrderThanksRedirect;
