import React, { createContext } from 'react'
import { Dimensions } from 'models'

export type WindowContextValue = {
  getViewportDimensions: () => Dimensions
}

const defaultContextValue = {
  getViewportDimensions: () => ({
    width: window.innerWidth, height: window.innerHeight
  })
}

export const WindowContext = createContext<WindowContextValue>(defaultContextValue)

type Props = {
  children: React.ReactNode
}

const WindowProvider = ({ children }: Props) => {
  return (
    <WindowContext.Provider value={defaultContextValue}>
      {children}
    </WindowContext.Provider>
  )
}

export default WindowProvider
