import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import ApiClient from 'ApiClient';
import { FollowshipActionType } from 'reducers/useFollowshipsReducer';
import { useFollowships } from 'loaders/FollowshipLoader';
import { Event } from 'Tracker';
import SignInClickTrigger from 'components/SignInClickTrigger';
import { AppLoaderContext } from 'AppLoader';

const UnfollowButton = styled(Button)(({ theme }) => ({
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  color: 'black',
  width: '120px',
  '&:hover': {
    background: '#d32f2f',
    color: 'white'
  }
}))

const FollowButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  '&:hover': {
    backgroundColor: '#2f41a5',
  }
}))

interface Props {
  following: boolean,
  openUnfollowDialog: () => void,
  creatorId: number
}

const FollowToggleButton = ({ following, openUnfollowDialog, creatorId }: Props) => {
  const { session } = React.useContext(AppLoaderContext)
  const [mouseOverUnfollowButton, setMouseOverUnfollowButton] = useState(false)
  const { followshipsDispatch } = useFollowships()

  const trackFollowEvent = () => window.tracker.track(Event.FollowCreator)
  const createFollowship = () => {
    trackFollowEvent()
    if (session?.user) {
      ApiClient.postCamelcased('/users/followships', { creator_id: creatorId }).then((resp) => {
        followshipsDispatch({ type: FollowshipActionType.INSERT, followship: resp.data })
      })
    }
  }

  if (following) {
    return (
      <UnfollowButton
        variant="contained"
        color="gray"
        onMouseEnter={() => setMouseOverUnfollowButton(true)}
        onMouseLeave={() => setMouseOverUnfollowButton(false)}
        onClick={openUnfollowDialog}
        data-testid="followship-unfollow-button"
      >
        {mouseOverUnfollowButton ? 'Unfollow' : 'Following'}
      </UnfollowButton>
    )
  }

  if (session?.user) {
    return (
      <FollowButton
        variant="contained"
        onClick={createFollowship}
        data-testid="followship-follow-button"
      >
        Follow
      </FollowButton>
    )
  }

  return (
    <SignInClickTrigger feature="Follow creator">
      <FollowButton variant="contained" data-testid="followship-follow-button">
        Follow
      </FollowButton>
    </SignInClickTrigger>
  )
}

export default FollowToggleButton;
