import { useContext } from 'react'
import { useLocation } from "react-router-dom";
import { Event, TrackerContext } from 'Tracker'
import { auth0OauthUrl } from 'auth0'

export const useRedirectToSignIn = () => {
  const location = useLocation()
  const { mixpanelProperties } = useContext(TrackerContext)

  const trackStart = () => {
    window.tracker.track(
      Event.SignInStarted, { mixpanelProperties, sendImmediately: true }
    )
  }

  return (destination?: string) => {
    trackStart()
    window.location.assign(
      auth0OauthUrl({ destination: destination || location.pathname })
    )
  }
}
