import React from 'react'
import { MapVariationForProduct } from 'models'
import { MapVariationZoom as DownloadPageMapVariationZoom } from 'components/mapDownloadPage'
import { PrivateAssetDownloadContext } from 'providers/PrivateAssetDownloadProvider'
import { Event } from 'Tracker'
import { createDownloadUrl } from 'models/MapVariationForProduct'

type Props = {
  battleMapAspectRatio: number
  mapVariations: MapVariationForProduct[]
  setZoomedInMapVariation: (mv: MapVariationForProduct | null) => void
  zoomedInMapVariation: MapVariationForProduct
  zoomOut: () => void
}

const MapVariationZoom = (props: Props) => {
  const {
    battleMapAspectRatio,
    mapVariations, setZoomedInMapVariation,
    zoomedInMapVariation,
    zoomOut
  } = props
  const { downloadPrivateAsset } = React.useContext(PrivateAssetDownloadContext)

  const download = () => {
    downloadPrivateAsset(
      () => createDownloadUrl(zoomedInMapVariation),
      Event.PatreonRewardDownloaded,
      zoomedInMapVariation.battleMapId
    )
  }

  return(
    <DownloadPageMapVariationZoom
      download={download}
      battleMapAspectRatio={battleMapAspectRatio}
      mapVariations={mapVariations}
      setZoomedInMapVariation={setZoomedInMapVariation}
      zoomedInMapVariation={zoomedInMapVariation}
      zoomOut={zoomOut}
    />
  )
}

export default MapVariationZoom

